// Copyright (C) 2018-2024 Skylark Drones

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const state = {
  user: null,
  notificationPayload: {
    message: null,
    code: null,
    timeout: 3000
  },
  company: {},
  stream: null,
  filters: {
    created_by: null,
    created_between: null,
    pilots: null,
    search_query: null
  },
  usersList: {
    data: null,
    fetching: false
  },
  groupByType: null,
  viewMode: 'grid',
  isFetchingUser: false,
  userRole: null,
  currentActiveOrganizationId: null,
  activeOrganizationDetails: null,
  orgErrorNotification: null,
  supportedDronesList: [],
  utmLayerDescriptionCache: {},
  livestreamCount: 0,
  cameraModels: [],
  isUserProfileComplete: false,
  measureState: {
    mode: 'off',
    value: 0,
    drawState: 0 // 0: Not started, 1: Drawing, 2: Finished
  },
  freeFCL: null
}

export default {
  state,
  mutations,
  actions,
  getters
}
