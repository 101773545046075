<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card ref="m3eCard" class="rounded-lg pa-6 fill-height">
    <v-row
      no-gutters
      justify="center"
      class="fill-height text-center text-md-left flex-wrap-reverse flex-md-wrap"
    >
      <v-col cols="12" md="6">
        <v-row no-gutters class="fill-height">
          <v-col cols="12">
            <div
              class="text-h6"
              style="color: var(--v-primary-font-color-base)"
            >
              DJI Mavic 3 Enterprise Series
            </div>
            <div
              class="text-body-2 mt-2 mx-auto"
              style="color: var(--v-secondary-font-color-base)"
            >
              Your everyday commercial drone that redefines industry standards.
              With a mechanical shutter, 56x zoom camera, and RTK module, the
              Mavic 3E takes mapping to a new height.
            </div>
          </v-col>
          <v-col cols="12" align-self="end" class="mt-8 mt-md-0">
            <v-btn
              depressed
              color="primary"
              :block="$vuetify.breakpoint.smAndDown"
              rounded
              :href="androidM3EAppLink"
              @click="onInstallerDownload"
            >
              <v-icon left>mdi-download-outline</v-icon>
              Download M3E
              {{ $vuetify.breakpoint.xs ? 'App' : 'Installer' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex" style="height: 220px">
        <v-img
          contain
          style="transform: rotateZ(335deg)"
          :src="require('@/assets/images/M3E.png')"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'M3ECard',

  computed: {
    ...mapGetters('brand', ['androidM3EAppLink'])
  },

  methods: {
    onInstallerDownload() {
      this.$analytics.trackDMOInstallerDownload('M3E')
    }
  }
}
</script>
