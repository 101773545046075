<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-list-item v-bind="$attrs" dense>
    <v-list-item-avatar tile width="100" height="50">
      <v-img :src="siteThumbnail" class="rounded" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-row align="center" style="width: 100%">
        <v-col>
          <div
            data-hj-suppress
            class="text-truncate text-body-2 font-weight-medium mb-1 primary-font-color--text"
          >
            {{ $props.site.name }}
          </div>
          <template v-if="$props.site.goal && isProgressSyncEnabled">
            <v-progress-linear
              background-color="#D9D9D9"
              color="#4CAF50"
              height="4"
              rounded
              :value="siteProgressPercentage"
            />
            <div
              class="text-truncate text-body-2 mt-1 secondary-font-color--text"
            >
              {{ remainingFlyingTime }}
            </div>
          </template>
          <div
            v-else
            class="text-body-2 secondary-font-color--text"
            data-hj-suppress
          >
            {{ siteSubtitle }}
          </div>
        </v-col>
      </v-row>
    </v-list-item-content>
    <v-list-item-icon>
      <v-row no-gutters align="center" style="min-width: 80px">
        <sample-data-chip
          v-if="site.sample_data"
          class="mr-2"
          tooltip-text="Sample site for your reference"
        />
        <v-row
          v-if="$props.showStakeholders && isProgressSyncEnabled"
          no-gutters
          justify="center"
        >
          <template v-for="(stakeholder, index) in stakeholders">
            <v-tooltip :key="stakeholder.id" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="32"
                  :color="
                    index ? 'var(--v-primary-font-color-base)' : '#A98EF5'
                  "
                  class="white--text text-body-2"
                  :class="index ? 'ml-n3' : ''"
                  data-hj-suppress
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ computeInitials(stakeholder.name) }}
                </v-avatar>
              </template>
              <span data-hj-suppress>
                {{
                  computeInitials(stakeholder.name) !== '-'
                    ? stakeholder.name
                    : 'No Name Set'
                }}
              </span>
            </v-tooltip>
          </template>
        </v-row>
        <v-tooltip v-if="site.external" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="primary-font-color" class="mr-1">
              mdi-account-multiple
            </v-icon>
          </template>
          <span>Shared by external user</span>
        </v-tooltip>
        <v-menu bottom close-on-content-click offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon width="48" height="48" v-on="on" @click.prevent="">
              <v-icon size="24" color="var(--v-secondary-font-color-base)">
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 ma-0 rounded-lg">
            <v-list-item @click="showAddSiteToCollectionDialog = true">
              <v-list-item-icon class="ml-n1 mr-4">
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Add to Collection</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item @click="showSiteShareDialog = true">
              <v-list-item-icon class="ml-n1 mr-4">
                <v-icon>mdi-share-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Share Site</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
              @click="
                downloadSite(
                  $props.site.id,
                  $props.site.name,
                  SOURCE.SITE_CARD_MENU
                )
              "
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-file-code</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Export Site as KML</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$props.site.goal > 0"
              @click="
                downloadSiteMissions(
                  $props.site.id,
                  $props.site.name,
                  SOURCE.SITE_CARD_MENU
                )
              "
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-file-code</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Export Missions as KML</v-list-item-title>
            </v-list-item>
            <v-menu
              v-if="areGCPsPresent"
              right
              open-on-hover
              close-on-content-click
              offset-x
              min-width="140"
            >
              <template v-slot:activator="{ on }">
                <v-list-item
                  v-on="on"
                  @click.prevent=""
                  style="cursor: default"
                >
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-file-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Export GCPs as</v-list-item-title>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>

              <v-list class="pa-0 ma-0 rounded-lg">
                <v-list-item @click="downloadGCPsHelper('CSV')">
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-file-delimited</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>CSV</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadGCPsHelper('KML')">
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-file-code</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>KML</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item
              v-if="shouldShowExportElevationsOption"
              @click="downloadSiteElevations($props.site.id, $props.site.name)"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-file-delimited</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Export Elevations as CSV</v-list-item-title>
            </v-list-item>
            <v-divider v-if="canDelete($props.site.access_level)" />
            <v-list-item
              v-if="canDelete($props.site.access_level)"
              @click="showSiteDeleteConfirmationDialog = true"
            >
              <v-list-item-icon class="ml-n1 mr-4">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Delete Site</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-list-item-icon>
    <site-delete-confirmation-dialog
      v-if="showSiteDeleteConfirmationDialog"
      :site="$props.site"
      :source-page="PAGE.SitesOverviewPage"
      @close-dialog="showSiteDeleteConfirmationDialog = false"
      @site-deleted="$emit('site-deleted', $props.site.id)"
    />
    <add-site-to-collection-dialog
      v-if="showAddSiteToCollectionDialog"
      :site-name="$props.site.name"
      :site-id="$props.site.id"
      @close-dialog="showAddSiteToCollectionDialog = false"
      @collection-created="onCollectionCreated"
      @site-added-to-collection="onSiteAddedToCollection"
    />
    <site-share-dialog
      :site-id="$props.site.id"
      :site-name="$props.site.name"
      :action-source="SOURCE.SITE_CARD_MENU"
      v-if="showSiteShareDialog"
      @close-dialog="showSiteShareDialog = false"
      @collaborators-update="updateCollaboratorsCount"
    />
  </v-list-item>
</template>

<script>
import SiteMixin from '../SiteMixin'
import PermissionMixin from '@/components/siteshare/PermissionMixin'
import SampleDataChip from '@/components/common/SampleDataChip.vue'

export default {
  name: 'SiteColumnListItem',

  mixins: [SiteMixin, PermissionMixin],

  props: {
    showStakeholders: { type: Boolean, required: true }
  },

  components: {
    SampleDataChip
  }
}
</script>
