import store from '../../store'

export default {
  install(Vue) {
    Vue.prototype.$cRbac = collaborators => {
      if (typeof collaborators === 'undefined' || collaborators === null) {
        collaborators = []
      }
      if (
        collaborators.length > 0 &&
        collaborators.includes(store.state.generic.user.email)
      )
        return false
      else if (
        collaborators.length > 0 &&
        !collaborators.includes(store.state.generic.user.email)
      )
        return true

      return true
    }
  }
}
