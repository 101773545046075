// Copyright (C) 2024 Skylark Drones

import speedConverter from '@/utils/unitconverter/SpeedUnitConverter.js'
import distanceConverter from '@/utils/unitconverter/DistanceUnitConverter.js'
import heightConverter from '@/utils/unitconverter/HeightUnitConverter.js'
import areaConverter from '@/utils/unitconverter/AreaUnitConverter.js'

const ConverterPlugin = {
  install(Vue) {
    Vue.prototype.$speedConverter = speedConverter
    Vue.prototype.$distanceConverter = distanceConverter
    Vue.prototype.$heightConverter = heightConverter
    Vue.prototype.$areaConverter = areaConverter
  }
}

export default ConverterPlugin
