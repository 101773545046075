export const SVG_ICONS = {
  HOME_ICON: '/static/008-house.svg',
  STATS_ICON: '/static/006-pencil.svg',
  LOCATION_ICON: '/static/007-map.svg',
  DRONE_ICON: '/static/006-camera.svg',
  USERS_ICON: '/static/005-people.svg',
  CAMERA_ICON: '/static/012-photo-camera.svg',
  BATTERY_ICON: '/static/004-battery.svg',
  METRICS_ICON: '/static/metrics.svg',
  SETTINGS_ICON: '/static/settings.svg',
  NEWS_ICON: '/static/news.svg',
  ORG_ICON: '/static/building.svg',
  STREAM_ICON: '/static/access-point.svg',
  TIPS_ICON: '/static/tips.svg',
  MONEY_ICON: '/static/money.svg'
}

export const PROJECT_TYPE_LOGOS = {
  AGRICULTURE: '/static/agriculture.png',
  INSPECTION: '/static/infrastructure.png',
  MINING: '/static/mining.png',
  RAILWAYS: '/static/railways.png',
  ROADWAYS: '/static/roadways.png',
  SOLAR: '/static/solar.png',
  UTILITY: '/static/powerline.png'
}

export const MISSION_PARAMETER_ICONS = {
  ALTITUDE: '/static/mission_params_icons/Altitude.svg',
  CURVE_SIZE: '/static/mission_params_icons/CurveSize.svg',
  GIMBAL_PITCH: '/static/mission_params_icons/GimbalPitch.svg',
  MIN_DRONE_ALTITUDE: '/static/mission_params_icons/MinDroneAltitude.svg',
  OVERLAP: '/static/mission_params_icons/Overlap.svg',
  FPS: '/static/mission_params_icons/FPS.svg',
  DRONE_SPEED: '/static/mission_params_icons/DroneSpeed.svg',
  RESOLUTION: '/static/mission_params_icons/Resolution.svg'
}

export const MIMETYPE_ICONS = {
  'application/pdf': 'mdi-file-pdf',
  'text/csv': 'mdi-file-delimited',
  'image/jpeg': 'mdi-file-image',
  'image/png': 'mdi-file-image',
  'image/vnd.dwg': 'mdi-file-cad',
  'application/msword': 'mdi-file-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'mdi-file-word',
  'application/vnd.ms-excel': 'mdi-file-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'mdi-file-excel',
  'application/vnd.oasis.opendocument.spreadsheet': 'mdi-file-chart',
  'application/zip': 'mdi-folder-zip',
  'text/plain': 'mdi-file-document',
  'video/mp4': 'mdi-file-video',
  'video/mpeg': 'mdi-file-video',
  'application/vnd.google-earth.kml+xml': 'mdi-file-code',
  'application/vnd.google-earth.kmz': 'mdi-file-code'
}
