<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <h5
    class="text-subtitle-1 font-weight-medium text-uppercase primary-font-color--text"
  >
    <slot />
  </h5>
</template>

<script>
export default {
  name: 'GroupHeader'
}
</script>
