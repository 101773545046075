// Copyright (C) 2024 Skylark Drones

import { UnitConverter } from './UnitConverter'
import store from '@/store'

class HeightUnitConverter extends UnitConverter {
  storeState = store.state.generic

  static Units = {
    CM: 'cm',
    INCHES: 'in',
    M: 'm',
    FEET: 'ft',
    KM: 'km',
    MILES: 'mi'
  }

  static UnitTypes = {
    imperial: 'imperial',
    metric: 'metric'
  }

  UnitEnums = {
    CM: 'cm',
    INCHES: 'in',
    M: 'm',
    FEET: 'ft',
    KM: 'km',
    MILES: 'mi'
  }

  getRequiredUnit = {
    imperial: unit => {
      switch (unit) {
        case 'cm':
          return 'in'
        case 'm':
          return 'ft'
        case 'km':
          return 'mi'
      }
    },
    metric: unit => unit
  }

  longForm = {
    m: 'meters',
    ft: 'feet',
    cm: 'centimeters',
    in: 'inches',
    km: 'kilometers',
    mi: 'miles'
  }

  constructor() {
    super()

    this.toBaseUnit = {
      [HeightUnitConverter.Units.KM]: value => value * 1000,
      [HeightUnitConverter.Units.M]: value => value * 1,
      [HeightUnitConverter.Units.CM]: value => value / 100,
      [HeightUnitConverter.Units.FEET]: value => value / 3.2808399,
      [HeightUnitConverter.Units.MILES]: value => value * 1609.344,
      [HeightUnitConverter.Units.INCHES]: value => value / 39.3700787
    }
    this.toPrefferedUnit = {
      [HeightUnitConverter.Units.KM]: value => value / 1000,
      [HeightUnitConverter.Units.M]: value => value * 1,
      [HeightUnitConverter.Units.CM]: value => value * 100,
      [HeightUnitConverter.Units.FEET]: value => value * 3.2808399,
      [HeightUnitConverter.Units.MILES]: value => value / 1609.344,
      [HeightUnitConverter.Units.INCHES]: value => value * 39.3700787
    }
  }

  /**
   * Converts a value from default unit of height to user's preffered unit of height
   * @param {number} value The numerical value to be converted .
   * @param {String} inputUnit The unit from ehich change is required .
   * @returns {Object} The object posess 3 keys, 'value': value in preferred unit, 'shortUnit': preffered unit in short form, 'longUnit': preffered unit in long form .
   */
  convert(
    value,
    inputUnit,
    outputUnitType = this.storeState.user?.unit_preferences?.distance
  ) {
    const baseUnitType = HeightUnitConverter.UnitTypes.metric
    const preferredHeightUnit = outputUnitType ?? baseUnitType
    const outputUnit = this.getRequiredUnit[preferredHeightUnit](inputUnit)
    const inputValueInBaseUnit = this.toBaseUnit[inputUnit](value)
    return {
      value: this.toPrefferedUnit[outputUnit](inputValueInBaseUnit),
      shortUnit: outputUnit,
      longUnit: this.longForm[outputUnit]
    }
  }

  /**
   * Converts a value from preferred unit of height to default unit of height
   * @param {number} value The numerical value to be converted .
   * @param {String} inputUnit The unit from ehich change is required .
   * @returns {number} The converted value in default unit .
   */
  convertToBaseUnit(value, inputUnit) {
    const preferredHeightUnit =
      this.storeState.user?.unit_preferences?.height ?? 'metric'
    const outputUnit = this.getRequiredUnit[preferredHeightUnit](inputUnit)
    const exponentValue = 10 ** UnitConverter.PRECISION
    return (
      Math.round(
        value *
          (1 /
            (this.toBaseUnit[inputUnit](1) *
              this.toPrefferedUnit[outputUnit](1))) *
          exponentValue
      ) / exponentValue
    )
  }
}

export default new HeightUnitConverter()
