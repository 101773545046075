// Copyright (C) 2020 Skylark Drones

import axios from 'axios'

export default {
  data() {
    return {
      request: null,
      requests: {}
    }
  },

  computed: {
    trackRequestConfig() {
      return { cancelToken: this.request.cancelToken }
    }
  },

  methods: {
    isErrorDueToCancellation(error) {
      return axios.isCancel(error)
    },
    trackRequest() {
      const axiosSource = axios.CancelToken.source()
      this.request = {
        cancel: axiosSource.cancel,
        cancelToken: axiosSource.token
      }
    },
    cancelPendingRequests() {
      if (this.request) {
        this.request.cancel()
        this.clearRequest()
      }
    },
    clearRequest() {
      this.request = null
    },
    trackRequestByKey(key) {
      const axiosSource = axios.CancelToken.source()
      this.requests[key] = {
        cancel: axiosSource.cancel,
        cancelToken: axiosSource.token
      }
    },
    cancelPendingRequestByKey(key) {
      if (this.requests[key]) {
        this.requests[key].cancel()
        this.clearRequestByKey(key)
      }
    },
    clearRequestByKey(key) {
      delete this.requests[key]
    },
    trackRequestConfigByKey(key) {
      return { cancelToken: this.requests[key].cancelToken }
    }
  }
}
