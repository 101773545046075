<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-menu
    offset-y
    bottom
    rounded="lg"
    :close-on-content-click="false"
    min-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <filter-btn
        v-bind="attrs"
        v-on="on"
        label="Created Between"
        icon="mdi-calendar-range"
      >
        <template v-slot:value>
          <div v-if="displayValue" class="subtitle-2 primary--text">
            {{ displayValue }}
          </div>
        </template>
      </filter-btn>
    </template>
    <v-card>
      <v-list dense>
        <v-list-item
          :input-value="presetRangeValue === presetRange.value"
          v-for="presetRange in presetRangeList"
          v-bind:key="presetRange.value"
          @click="selectPresetRange(presetRange.value)"
          color="primary"
          dense
        >
          {{ presetRange.title }}
        </v-list-item>
      </v-list>
      <v-divider />
      <v-date-picker
        v-model="calenderRangeValue"
        @change="onRangeChange"
        color="primary"
        class="mt-1"
        range
        no-title
        :max="maxAllowedDate"
      />
      <template v-if="filters.created_between">
        <v-divider />
        <v-list-item @click="clearFilter" class="mt-2 primary-font-color--text">
          Clear Filter
        </v-list-item>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import dayjs from 'dayjs'

import FilterButton from '@/components/sites/filters/FilterButton'

export default {
  name: 'CreatedBetweenFilter',

  components: {
    'filter-btn': FilterButton
  },

  data() {
    return {
      calenderRangeValue: null,
      presetRangeValue: null,
      maxAllowedDate: dayjs().format('YYYY-MM-DD'),
      presetRangeList: [
        { title: 'Last 24 Hours', value: '-1d' },
        { title: 'Last 7 Days', value: '-7d' },
        { title: 'Last 30 Days', value: '-30d' },
        { title: 'Last 90 Days', value: '-90d' }
      ]
    }
  },

  computed: {
    ...mapGetters(['filters']),
    displayValue() {
      const value = this.filters.created_between

      if (!value) {
        return null
      }

      if (typeof value === 'string') {
        return this.presetRangeList.find(preset => preset.value === value).title
      }

      const rangeStart = dayjs(value[0]).format('D MMMM, YYYY')
      const rangeEnd = this.isDateToday(value[1])
        ? 'Today'
        : dayjs(value[1]).format('D MMMM, YYYY')

      return `${rangeStart} - ${rangeEnd}`
    }
  },

  watch: {
    'filters.created_between': function (value) {
      this.calenderRangeValue = null
      this.presetRangeValue = null

      if (value) {
        this.setDateRange()
      }
    }
  },

  mounted() {
    if (this.filters.created_between) {
      this.setDateRange()
    }
  },

  methods: {
    ...mapMutations(['updateFilters']),
    selectPresetRange(presetRange) {
      this.presetRangeValue = presetRange
      this.calenderRangeValue = null

      this.updateFilters({ created_between: this.presetRangeValue })
    },
    isDateToday(date) {
      return dayjs(date).isSame(Date.now(), 'day')
    },
    clearFilter() {
      this.calenderRangeValue = null
      this.presetRangeValue = null

      this.updateFilters({ created_between: null })
    },
    onRangeChange(range) {
      let rangeStart, rangeEnd

      // vuetify datepicker set the first item of the range
      // to the date user clicked first irrespective of the position
      // of the next clicked date is on left or right on the timeline
      // therefore correcting the order of the dates in the range
      if (dayjs(range[0]).isBefore(dayjs(range[1]))) {
        rangeStart = dayjs(range[0]).toISOString()
        rangeEnd = dayjs(range[1]).toISOString()
      } else {
        rangeStart = dayjs(range[1]).toISOString()
        rangeEnd = dayjs(range[0]).toISOString()
      }

      if (this.isDateToday(rangeEnd)) {
        rangeEnd = dayjs(Date.now()).toISOString()
      } else {
        rangeEnd = dayjs(rangeEnd).endOf('day').toISOString()
      }

      this.presetRangeValue = null

      this.updateFilters({ created_between: [rangeStart, rangeEnd] })
    },
    setDateRange() {
      if (typeof this.filters.created_between === 'string') {
        this.presetRangeValue = this.filters.created_between
      } else {
        this.calenderRangeValue = this.filters.created_between
      }
    }
  }
}
</script>
