// Copyright (C) 2023-2024 Skylark Drones
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('brand', ['appNameAbbr']),
    ...mapGetters(['groupByType']),
    groupedSites: function () {
      if (this.groupByType === 'time') {
        return this.groupSitesByTime(this.$props.sites)
      } else {
        return this.groupSitesByStatus(this.$props.sites)
      }
    },
    showStakeholders: function () {
      return this.groupByType === 'status'
    }
  },

  props: {
    sites: { type: Object, required: true },
    deleteSiteFunction: { type: Function, required: true },
    siteAddedToCollectionFunction: { type: Function, required: true },
    collectionCreatedFunction: { type: Function, required: true },
    loadMoreSites: { type: Function, required: true },
    limit: { type: Number, required: true }
  },

  methods: {
    groupSitesByTime(sites) {
      return [
        {
          heading: 'This Week',
          groupKey: 'this_week',
          sites: sites.this_week,
          emptyMessage:
            'No sites created this week. Is it the bad weather? If not, you should totally create a site!'
        },
        {
          heading: 'Earlier This Month',
          groupKey: 'earlier_this_month',
          sites: sites.earlier_this_month,
          emptyMessage:
            "No sites created earlier this month. Isn't it fun to fly?"
        },
        {
          heading: 'Last 3 Months',
          groupKey: 'last_3_months',
          sites: sites.last_3_months,
          emptyMessage:
            'No sites created in the last 3 months. That is unusual. Any stoppers?'
        },
        {
          heading: 'Older',
          groupKey: 'before_4_months',
          sites: sites.before_4_months,
          emptyMessage: "No old sites found. You're all about the new factor?"
        }
      ]
    },
    groupSitesByStatus(sites) {
      return [
        {
          heading: 'New',
          groupKey: 'New',
          sites: sites.New,
          emptyMessage:
            'No new sites found in the pipeline! Start by creating a new site ' +
            `and fly it using ${this.appNameAbbr}.`
        },
        {
          heading: 'In Progress',
          groupKey: 'In Progress',
          sites: sites['In Progress'],
          emptyMessage:
            `No sites being flown now. Not using ${this.appNameAbbr} to fly? Track site progress ` +
            `using the ${this.appNameAbbr} Android app in real time.`
        },
        {
          heading: 'Complete',
          groupKey: 'Complete',
          sites: sites.Complete,
          emptyMessage: 'No completed sites found.'
        }
      ]
    }
  }
}
