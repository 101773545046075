<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <div>
    <v-btn class="primary" rounded @click="selectFile">
      <v-icon left>mdi-tray-arrow-up</v-icon>
      Upload Site
    </v-btn>
    <site-file-selector-module
      v-show="false"
      ref="inputUpload"
      @file-processed="onFileProcessed"
    />

    <invalid-site-file-dialog
      v-if="showInvalidSiteFileDialog"
      :title="errorTitle"
      :message="errorMsg"
      @close-dialog="showInvalidSiteFileDialog = false"
      @select-another-file="onInvalidFileSelected"
    />
  </div>
</template>

<script>
import UploadSiteBtnMixin from './UploadSiteBtnMixin'
export default {
  name: 'UploadSiteBtn',
  mixins: [UploadSiteBtnMixin]
}
</script>
