<!-- Copyright (C) 2022-2024 Skylark Drones -->
<template>
  <v-row class="overflow-x-auto" style="flex-wrap: nowrap">
    <template v-for="index in [1, 2, 3]">
      <v-col cols="6" md="3" lg="3" xl="2" :key="index">
        <div class="elevation-3 rounded-lg white pb-2">
          <v-skeleton-loader class="rounded-t-lg" type="list-item-two-line" />
        </div>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'HorizontalCollectionListSkeletonLoader'
}
</script>
