// Copyright (C) 2022-2024 Skylark Drones

import apiInstance from '@/config'

export function getNews(
  pageNo = 1,
  limit = 10,
  excludeDraft = false,
  sortBy,
  sortOrder
) {
  const params = { limit, page: pageNo, exclude_draft: excludeDraft }
  if (sortBy) {
    params.sort_by = sortBy
    params.sort_order = sortOrder
  }
  return apiInstance.http.get('/news', { params })
}

export function createNews(data) {
  return apiInstance.http.post(`/news/publish`, data)
}

export function editNews(id, payload) {
  return apiInstance.http.patch(`/news/${id}`, payload)
}

export function updateNewsViews(id) {
  return apiInstance.http.patch(`/news/views/${id}`, {})
}

export function deleteNews(id) {
  return apiInstance.http.delete(`/news/${id}`)
}
