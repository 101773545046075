<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <h5 class="mt-8 mb-3 text-h5 font-weight-medium primary-font-color--text">
    {{ title }}
  </h5>
</template>
<script>
export default {
  name: 'HomePageSectionHeader',
  props: {
    title: {
      type: String
    }
  }
}
</script>
