<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-carousel
    v-model="model"
    height="100%"
    hide-delimiter-background
    :show-arrows="false"
    continuous
    interval="10000"
    cycle
  >
    <v-carousel-item v-for="slide in slides" :key="slide.title">
      <v-row align="center" justify="center" style="height: 100%">
        <v-col class="text-center">
          <div class="text-h6">{{ slide.title }}</div>
          <div class="text-body-2 mt-2 mx-5">
            {{ slide.text }}
          </div>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'StreamFeatureCarousel',

  data() {
    return {
      model: 0,
      slides: [
        {
          title: 'Interactive Communication',
          text: 'Two-way communication, allowing ground teams to guide pilots with precision, making adjustments on the fly for optimal mission outcomes.'
        },
        {
          title: 'AI Powered Analytics',
          text: 'Classify vehicles and people in the drone video feed, providing real-time data that supports various operational needs.'
        },
        {
          title: 'High Quality Streaming',
          text: 'Utilizes Zoom’s robust platform for stable and clear aerial video, ensuring that every detail is visible, as if you are right there with the drone.'
        }
      ]
    }
  }
}
</script>
