<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <div>
    <v-row no-gutters class="mb-sm-1" align="center">
      <v-col cols="auto">
        <h5 class="text-h5 font-weight-medium primary-font-color--text">
          Sites
        </h5>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="auto"
        class="d-flex align-center ml-5"
      >
        <v-btn-toggle
          v-model="showMySites"
          borderless
          class="elevation-2 mr-2"
          color="primary"
          dense
          rounded
        >
          <v-btn
            class="white px-3 text-none text-subtitle-1 font-weight-medium"
          >
            My Sites
          </v-btn>
        </v-btn-toggle>
        <div class="mr-2">
          <status-filter
            :value="statusFilter"
            @on-change="onStatusFilterChange"
          />
        </div>
        <date-filter :value="dateFilter" @on-change="onDateFilterChange" />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <upload-site-btn />
      </v-col>
      <v-col cols="auto" v-if="isMoreSites" class="ml-2">
        <v-btn
          text
          rounded
          :to="{
            name: 'SitesOverviewPage',
            params: { companyId: currentActiveOrganization.id }
          }"
        >
          View All
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div class="d-flex align-center" style="position: relative">
      <v-row
        ref="siteList"
        class="flex-nowrap mt-0"
        align="stretch"
        :class="{
          'overflow-x-hidden': $vuetify.breakpoint.mdAndUp,
          'overflow-x-auto': $vuetify.breakpoint.smAndDown
        }"
        style="min-height: 200px"
      >
        <template v-if="isSitesLoading">
          <v-col
            cols="8"
            sm="5"
            md="3"
            lg="2"
            v-for="i in new Array(5).map((v, i) => i)"
            :key="i"
          >
            <site-card-loader />
          </v-col>
        </template>

        <template v-else-if="sites.length > 0">
          <v-col
            cols="8"
            sm="5"
            md="3"
            lg="2"
            v-for="site in sites"
            :key="site.id"
          >
            <site-card
              :site="site"
              :to="{ name: 'SiteDetailsPage', params: { siteId: site.id } }"
              @site-deleted="onSiteDelete"
              @collection-created="onCollectionCreated"
              @site-added-to-collection="onSiteAddedToCollection"
            />
          </v-col>
        </template>

        <v-col v-else cols="12">
          <div
            style="color: #6e6e6d"
            class="text-body-2 grey lighten-2 rounded-lg d-flex align-center justify-center flex-column fill-height"
          >
            <template v-if="!isFilterAdded">
              You do not have any sites. Create one to get started
              <div class="mt-2">
                <v-btn rounded small class="primary" @click="drawSite">
                  <v-icon left>mdi-plus</v-icon> Create Site
                </v-btn>
              </div>
            </template>
            <template v-else>No sites found for the given filter</template>
          </div>
        </v-col>
      </v-row>

      <template v-if="$vuetify.breakpoint.mdAndUp && sites.length > 0">
        <v-btn
          v-if="canScrollPrev"
          absolute
          fab
          key="left-scroll"
          style="left: 0"
          color="white"
          class="ml-n6"
          @click="scrollTo('left')"
        >
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn
          v-if="canScrollNext"
          absolute
          fab
          key="right-scroll"
          style="right: 0"
          color="white"
          class="mr-n6"
          @click="scrollTo('right')"
        >
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { debounce, isArray, isNil } from 'lodash'
import * as Sentry from '@sentry/browser'

import {
  cachePreference,
  removeUnusedFilters
} from '@/utils/common/userPreferences.js'
import { getAllSites } from '@/services/SiteService'
import { editUser } from '@/services/UserService'
import SiteCard from '@/components/sites/SiteCard.vue'
import DateFilter from './DateFilter'
import SiteCardLoader from '@/components/sites/SiteCardLoader.vue'
import StatusFilter from './StatusFilter.vue'
import UploadSiteBtn from '@/components/homepage/UploadSiteBtn.vue'

export default {
  name: 'SiteRow',

  components: {
    SiteCard,
    DateFilter,
    SiteCardLoader,
    StatusFilter,
    UploadSiteBtn
  },

  data() {
    return {
      sites: [],
      isMoreSites: false,
      isSitesLoading: false,
      showMySites: false,
      dateFilter: null,
      statusFilter: null,
      canScrollPrev: false,
      canScrollNext: true,
      canActOnFilterUpdates: true
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'currentActiveOrganization',
      'currentActiveOrganizationId'
    ]),
    isFilterAdded() {
      return this.showMySites || this.dateFilter !== null
    }
  },

  watch: {
    currentActiveOrganization: async function () {
      if (this.isSitesLoading) {
        return
      }

      await this.setPreferences()
      this.fetchSites()
    },
    showMySites: function () {
      if (!this.canActOnFilterUpdates) return

      this.fetchSites()
      this.savePreference()
      this.$analytics.trackHomepageSitesFilter(
        this.showMySites === 0,
        undefined,
        undefined
      )
    },
    sites: async function () {
      await this.$nextTick()
      this.checkScrollState()
    }
  },

  async mounted() {
    await this.setPreferences()
    this.fetchSites()
  },

  methods: {
    ...mapMutations(['updateNotificationPayload']),
    async setPreferences() {
      this.canActOnFilterUpdates = false

      const preferences =
        this.user.web_preferences[this.currentActiveOrganizationId]
          .homepage_site_filters

      // Setting filter preferences to filter states
      this.showMySites = preferences.my_sites ? 0 : undefined
      this.dateFilter = isArray(preferences.created_between)
        ? preferences.created_between[0]
        : null
      this.statusFilter =
        preferences.status === 'none' ? null : preferences.status

      await this.$nextTick()
      this.canActOnFilterUpdates = true
    },
    savePreference: debounce(async function () {
      try {
        // Creating server payload
        const preferences = {
          homepage_site_filters: {
            status: this.statusFilter || 'none',
            created_between: isNil(this.dateFilter) ? [] : [this.dateFilter],
            my_sites: typeof this.showMySites === 'number'
          }
        }

        await editUser(this.user.id, {
          web_preferences: { [this.currentActiveOrganizationId]: preferences }
        })

        cachePreference({
          ...this.user.web_preferences,
          [this.currentActiveOrganizationId]: {
            ...this.user.web_preferences[this.currentActiveOrganizationId],
            ...preferences
          }
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 300),
    onDateFilterChange(optionKey) {
      this.dateFilter = optionKey
      this.fetchSites()
      this.savePreference()
      this.$analytics.trackHomepageSitesFilter(
        undefined,
        this.dateFilter,
        undefined
      )
    },
    onStatusFilterChange(optionKey) {
      this.statusFilter = optionKey
      this.fetchSites()
      this.savePreference()
      this.$analytics.trackHomepageSitesFilter(
        undefined,
        undefined,
        this.statusFilter
      )
    },
    async drawSite() {
      this.$analytics.trackSiteDraw()
      await this.$router.push({
        name: 'SiteDetailsPage',
        params: {
          companyId: this.currentActiveOrganization.id,
          siteId: 'new',
          workflow: 'draw',
          site: null
        }
      })
    },
    scrollTo(direction) {
      let offset =
        this.$refs.siteList.scrollLeft + this.$refs.siteList.clientWidth

      if (direction === 'left') {
        offset =
          this.$refs.siteList.scrollLeft - this.$refs.siteList.clientWidth
        this.$analytics.trackHomepageSitesScroll('prev')
      } else {
        this.$analytics.trackHomepageSitesScroll('next')
      }

      this.$refs.siteList.scrollTo({ left: offset, behavior: 'smooth' })

      // SetTimeout is required in order to determine the correct state of the scroll
      // If the scroll animation is ongoing and we check the state in between the animation
      // it will return the scroll location at that moment not the final state after the animation
      setTimeout(() => {
        this.checkScrollState()
      }, 750)
    },
    checkScrollState() {
      this.canScrollPrev = this.$refs.siteList.scrollLeft !== 0
      this.canScrollNext =
        this.$refs.siteList.scrollLeft + this.$refs.siteList.clientWidth !==
        this.$refs.siteList.scrollWidth
    },
    async fetchSites() {
      const cleanedFilters = removeUnusedFilters({
        status: this.statusFilter === null ? null : [this.statusFilter],
        created_between: this.dateFilter,
        created_by: this.showMySites === 0 ? [{ id: this.user.id }] : null
      })

      this.isSitesLoading = true

      try {
        const resp = await getAllSites({
          limit: 10,
          value: new Date().toISOString(),
          direction: 'lt',
          filters: cleanedFilters
        })
        if (this.showMySites === 0) {
          this.sites = resp.data.sites.filter(
            site => site.sample_data === false
          )
        } else {
          this.sites = resp.data.sites
        }
        this.isMoreSites = resp.data.next_count > 0
      } catch (err) {
        this.updateNotificationPayload({
          message: 'Unable to fetch sites!',
          code: 400,
          timeout: 5000
        })
      } finally {
        this.isSitesLoading = false
      }
    },
    onSiteDelete() {
      this.updateNotificationPayload({
        message: 'Site Deleted Successfully',
        code: 201,
        timeout: 5000
      })
      this.fetchSites()
    },
    onSiteAddedToCollection() {
      this.updateNotificationPayload({
        message: 'Site Added to collection',
        code: 201,
        timeout: 5000
      })
    },
    onCollectionCreated() {
      this.updateNotificationPayload({
        message: 'Collection created successfully',
        code: 201,
        timeout: 5000
      })
    }
  }
}
</script>
