<!-- Copyright (C) 2022-2024 Skylark Drones -->
<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" rounded class="primary">
        Create
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="pa-0 ma-0 rounded-lg">
      <v-list-item @click="selectFile">
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-tray-arrow-up</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Upload Site </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="drawSite">
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-draw</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Draw Site </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="$emit('create-collection')">
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-folder-plus-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Create Collection </v-list-item-title>
      </v-list-item>
    </v-list>
    <site-file-selector-module
      v-show="false"
      ref="inputUpload"
      @file-processed="onFileProcessed"
    />
    <invalid-site-file-dialog
      v-if="showInvalidSiteFileDialog"
      :title="errorTitle"
      :message="errorMsg"
      @close-dialog="showInvalidSiteFileDialog = false"
      @select-another-file="onInvalidFileSelected"
    />
  </v-menu>
</template>

<script>
import UploadSiteBtnMixin from '../homepage/UploadSiteBtnMixin'
export default {
  name: 'CreateSiteBtn',

  mixins: [UploadSiteBtnMixin],

  methods: {
    async drawSite() {
      this.$analytics.trackSiteDraw()
      await this.$router.push({
        name: 'SiteDetailsPage',
        params: {
          companyId: this.currentActiveOrganizationId,
          siteId: 'new',
          workflow: 'draw',
          site: null
        }
      })
    }
  }
}
</script>
