<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-row
    v-bind="$attrs"
    no-gutters
    align="center"
    class="pa-4 pa-sm-2 mx-auto"
    style="max-width: 500px"
  >
    <v-col cols="12" sm="auto">
      <v-img :src="EmptyStreamListImg" :width="155" class="ml-sm-4 mx-auto" />
    </v-col>
    <v-col class="ml-6" :class="{ 'text-center': $vuetify.breakpoint.xs }">
      <div class="text-subtitle-1 font-weight-medium">
        No Live Drone Streams
      </div>
      <div
        class="text-body-2 mt-2"
        :style="{ maxWidth: $vuetify.breakpoint.smAndUp ? '300px' : null }"
      >
        Live stream drone feed using the {{ appNameAbbr }} Android app (v2023.11
        or higher)
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

import EmptyStreamListImg from '@/assets/images/empty-stream-list.svg'

export default {
  name: 'EmptyStreamCard',

  computed: {
    ...mapGetters('brand', ['appNameAbbr'])
  },

  data() {
    return {
      EmptyStreamListImg
    }
  }
}
</script>
