<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card class="white rounded-lg" height="100%">
    <v-row style="height: 50%" no-gutters>
      <v-col cols="6" class="pa-2 pb-1 pr-1">
        <news-square
          v-if="$props.newsList[0]"
          :news="$props.newsList[0]"
          @click="onNewsClick($props.newsList[0])"
        />
      </v-col>
      <v-col cols="6" class="pa-2 pb-1 pl-1">
        <news-square
          v-if="$props.newsList[1]"
          :news="$props.newsList[1]"
          @click="onNewsClick($props.newsList[1])"
        />
      </v-col>
    </v-row>

    <v-row style="height: 50%" no-gutters>
      <v-col cols="6" class="pa-2 pt-1 pr-1">
        <news-square
          v-if="$props.newsList[2]"
          :news="$props.newsList[2]"
          @click="onNewsClick($props.newsList[2])"
        />
      </v-col>
      <v-col cols="6" class="pa-2 pt-1 pl-1">
        <v-btn
          rounded
          depressed
          width="100%"
          height="100%"
          class="rounded-lg"
          color="primary"
          :to="{ name: 'NewsOverview' }"
        >
          <div no-gutters align="center">
            <div>
              <v-img
                :src="require('@/assets/images/view-all-news.svg')"
                width="40"
              />
            </div>
            <div class="mt-2 text-capitalize text-body-1">View All</div>
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { page } from '@/utils/analytics/abstraction/eventEnums'
import { updateNewsViews } from '@/services/NewsService'
import NewsSquare from './NewsSquare.vue'

export default {
  components: {
    NewsSquare
  },

  props: {
    newsList: { type: Array, required: true }
  },

  methods: {
    onNewsClick(news) {
      this.$analytics.trackNewsView(
        {
          id: news.id,
          title: news.title,
          url: news.url,
          isFeatured: news.featured
        },
        page.HomePage
      )
      this.$router.push({
        name: 'NewsOverview',
        query: { newsId: news.id }
      })
      updateNewsViews(news.id)
    }
  }
}
</script>
