// Copyright (C) 2018-2024 Skylark Drones

export default {
  setUserResponse({ commit }, payload) {
    commit('updateUser', payload)
  },

  setPreferences({ commit }, preferences) {
    commit('updateFilters', preferences.filters)
    commit('setGroupByType', preferences.group)
    commit('setViewMode', preferences.view)
  },
  updateFCL({ commit, getters }, { featureKey, payload }) {
    const companyIndex = getters.currentActiveOrganization._index
    const featureIndex = getters.activeFCL[featureKey]._index
    commit('updateFCL', { featureIndex, companyIndex, payload })
  }
}
