// Copyright (C) 2018-2024 Skylark Drones

import store from '@/store'

/**
 * If a logged in user tries to open the Homepage, redirect him to
 * the default projects list page.
 * @param {Route} dest Target page being navigated to
 * @param {Route} src Current page being navigated away from
 * @param {function} next Function to resolve the hook
 */
export function redirectLoggedInUser(dest, src, next) {
  const tokenAuthorized = JSON.parse(localStorage.getItem('tokenAuthorized'))
  const user = JSON.parse(localStorage.getItem('user'))
  const isLoggedIn = Boolean(tokenAuthorized) && user !== null

  if (isLoggedIn && dest.name === 'HomePage') {
    if (user && user.designation.includes('Super Admin')) {
      next({ name: 'ProductUsageOverview' })
    } else {
      const userOrgSelectionMapString = localStorage.getItem(
        'usersSelectedOrganizationMap'
      )

      let activeCompanyId
      if (userOrgSelectionMapString) {
        activeCompanyId = JSON.parse(userOrgSelectionMapString)[user.id]

        // Check if the user is still member of org
        // if not fallback to personal org
        if (!user.companies.find(company => company.id === activeCompanyId)) {
          activeCompanyId = store.getters.userPersonalSpace.id
        }
      }

      store.commit('setCurrentActiveOrganizationId', activeCompanyId)
      next({
        name: 'LoggedInHomePage',
        params: { companyId: activeCompanyId }
      })
    }
  } else {
    next()
  }
}

/**
 * Checks the user authentication by looking at some basic attributes
 * like the token authorized and appropriately routing the user to the
 * target page on successful authorization or redirecting to the home
 * page on failure.
 * @param {Route} dest Target page being navigated to
 * @param {Route} src Current page being navigated away from
 * @param {function} next Function to resolve the hook
 */
export function checkAuth(dest, src, next) {
  const tokenAuthorized = JSON.parse(localStorage.getItem('tokenAuthorized'))
  const isLoggedIn = Boolean(tokenAuthorized)
  const user = JSON.parse(localStorage.getItem('user'))

  if (isLoggedIn && dest.name === 'PlansDetailsPage') {
    return next({
      name: 'UsersDisplay',
      params: { userId: user.id, tabKey: 'plans' }
    })
  }

  if (!dest.meta.requiresAuth) {
    next()
    return
  }

  if (isLoggedIn && user !== null) {
    next()
  } else {
    next({ name: 'HomePage' })
  }
}

/**
 * Redirect user landed on the PrivateLivestream to PublicLivestream and vice-versa
 * based on their auth state.
 * @param {Route} dest Target page being navigated to
 * @param {Route} src Current page being navigated away from
 * @param {function} next Function to resolve the hook
 */
export function redirectUsersToCorrectLivestreamPage(dest, src, next) {
  const tokenAuthorized = JSON.parse(localStorage.getItem('tokenAuthorized'))
  const user = JSON.parse(localStorage.getItem('user'))
  const isLoggedIn = Boolean(tokenAuthorized) && user !== null

  if (isLoggedIn && dest.name === 'StreamPage') {
    next({ name: 'StreamPrivatePage', replace: true, params: dest.params })
    return
  }

  if (!isLoggedIn && dest.name === 'StreamPrivatePage') {
    next({ name: 'StreamPage', replace: true, params: dest.params })
    return
  }

  // NOTE: make sure all the condition has return at the end
  // next should be called only once
  next()
}
