<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <div v-bind="$attrs" class="elevation-3 rounded-lg white pb-2">
    <v-skeleton-loader
      class="rounded-t-lg rounded-b-0 grey lighten-2"
      height="135"
      type="image"
    />
    <v-skeleton-loader class="rounded-0" type="list-item" />
    <v-skeleton-loader class="rounded-b-lg mt-n2" type="list-item-avatar" />
  </div>
</template>

<script>
export default {
  name: 'SiteCardLoader'
}
</script>
