<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-row dense>
    <v-col cols="auto"><search-query-filter /></v-col>
    <v-col cols="auto"><created-by-filter /></v-col>
    <v-col cols="auto">
      <v-badge content="NEW" color="red" offset-x="30" offset-y="8">
        <filter-menu />
      </v-badge>
    </v-col>
    <v-col v-if="areFiltersApplied" cols="auto">
      <v-btn
        text
        rounded
        class="subtitle-1 text-capitalize font-weight-medium"
        @click="clearFilters"
      >
        Clear Filters
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import CreatedByFilter from './CreatedByFilter'
import SearchQueryFilter from './SearchQueryFilter.vue'
import FilterMenu from './FilterMenu.vue'

export default {
  name: 'CommonFilters',

  components: {
    'created-by-filter': CreatedByFilter,
    'search-query-filter': SearchQueryFilter,
    'filter-menu': FilterMenu
  },

  computed: {
    ...mapGetters(['filters']),
    areFiltersApplied() {
      return Object.values(this.filters).reduce(
        (isPreviousExist, currentFilterValue) =>
          isPreviousExist || !!currentFilterValue,
        false
      )
    }
  },

  methods: {
    ...mapMutations(['updateFilters']),
    clearFilters() {
      this.updateFilters({
        created_by: null,
        created_between: null,
        pilots: null,
        search_query: null
      })
    }
  }
}
</script>
