<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <v-menu offset-y bottom rounded="lg" min-width="155">
    <template v-slot:activator="{ on, attrs }">
      <pro-badge
        :value="!$featureAccessControl.isEnabled('web_progress_sync')"
        offset-x="30"
        offset-y="8"
      >
        <filter-button
          v-bind="attrs"
          v-on="{ ...on, click: onClick(on.click) }"
          label="Status"
          icon="mdi-clipboard-text-clock-outline"
        >
          <template v-slot:value>
            <div v-if="displayValue" class="text-subtitle-2 primary--text">
              {{ displayValue }}
            </div>
          </template>
        </filter-button>
      </pro-badge>
      <pro-dialog
        v-if="showProDialog"
        @click:close-btn="showProDialog = false"
      />
    </template>
    <v-card>
      <v-list dense>
        <v-list-item
          v-for="option in menuOptions"
          :key="option.key"
          color="primary"
          :input-value="$props.value === option.key"
          @click="
            $emit('on-change', $props.value === option.key ? null : option.key)
          "
        >
          {{ option.label }}
        </v-list-item>
      </v-list>
      <template v-if="$props.value">
        <v-divider />
        <v-list-item
          @click="$emit('on-change', null)"
          class="mt-2 primary-font-color--text"
        >
          Clear Filter
        </v-list-item>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
import FilterButton from '@/components/sites/filters/FilterButton.vue'
import ProBadge from '@/common/ProBadge.vue'
const ProDialog = () => import('@/common/ProDialog.vue')

export default {
  name: 'SiteFilter',

  components: {
    FilterButton,
    ProBadge,
    ProDialog
  },

  props: {
    value: { type: String }
  },

  data() {
    return {
      menuOptions: [
        { label: 'New', key: 'New' },
        { label: 'In Progress', key: 'In Progress' },
        { label: 'Completed', key: 'Complete' }
      ],
      showProDialog: false
    }
  },

  computed: {
    displayValue() {
      return (
        this.$props.value &&
        this.menuOptions.find(opt => opt.key === this.$props.value).label
      )
    }
  },

  methods: {
    onClick(click) {
      return e => {
        if (!this.$featureAccessControl.isEnabled('web_progress_sync')) {
          this.showProDialog = true
          return
        }

        click(e)
      }
    }
  }
}
</script>
