<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <user-list-filter
    label="Created By"
    icon="mdi-account-outline"
    :filter="filters.created_by"
    :filter-function="_updateFilters"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import UsersListFilter from './UsersListFilter.vue'

export default {
  name: 'CreatedByFilter',

  components: {
    'user-list-filter': UsersListFilter
  },

  computed: {
    ...mapGetters(['filters'])
  },

  methods: {
    ...mapMutations(['updateFilters']),
    _updateFilters(users) {
      this.updateFilters({ created_by: users })
    }
  }
}
</script>
