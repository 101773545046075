// Copyright (C) 2018-2024 Skylark Drones

import mutations from './mutations'
import getters from './getters'
import actions from './actions'

const state = {
  id_token: null,
  tokenAuthorized: false,
  first_issued_at: null,
  expires_at: null,
  userObject: null,
  tokenIssuer: null
}
export default {
  state,
  mutations,
  getters,
  actions
}
