// Copyright (C) 2023-2024 Skylark Drones

import apiInstance from '@/config'
import { isUserLoggedIn } from '@/utils/common'

/**
 * Generate Zoom JWT token to join session
 * @param {string} companyId - Id of the company
 * @param {Object} data Data required to generate token
 * @param {string} data.session_name - The name of the session to join to.
 * @param {'host'|'participant'} data.role - Role of the user either host or participant.
 * @param {string} data.expiration_time - Seconds after which this token will expire.
 * @param {boolean} usePublicRoute - Boolean to use public route or not
 * @returns {import('axios').AxiosResponse} axios response
 */
export function postGenerateZoomJWTToken(
  companyId,
  data,
  usePublicRoute = false
) {
  if (!usePublicRoute && isUserLoggedIn()) {
    return apiInstance.http.post(
      `/company/${companyId}/streams/generatetoken`,
      data
    )
  }

  return apiInstance.publicHttp.post(
    `/company/${companyId}/streams/generatetoken`,
    data
  )
}

/**
 * Get All Stream
 * @param {string} companyId - Id of the company
 * @param {Object} options parameter to filter streams
 * @param {string} [options.limit=20] options.limit - who many streams to return in single page
 * @param {string} [options.page=1] options.page - page no
 * @param {'live'|'ended'} [options.stream_status='live'] options.stream_status - status of stream
 * @param {string} options.search_query - query to filter streams by
 * @returns axios response with streams list
 */
export function getStreams(companyId, options) {
  return apiInstance.http.get(`/company/${companyId}/streams`, {
    params: options
  })
}

/**
 * Get Stream
 * @param {string} companyId - Id of the company
 * @param {string} streamId - Id of the stream
 * @param {boolean} usePublicRoute - Boolean to use public route or not
 * @returns axios response with stream data
 */
export function getStream(companyId, streamId, usePublicRoute = false) {
  if (!usePublicRoute && isUserLoggedIn()) {
    return apiInstance.http.get(`/company/${companyId}/streams/${streamId}`)
  }

  return apiInstance.publicHttp.get(`/company/${companyId}/streams/${streamId}`)
}

/**
 * Get Live Stream joining data
 * @param {string} companyId - Id of the company
 * @param {string} streamId - Id of the stream
 * @param {boolean} usePublicRoute - Boolean to use public route or not
 * @returns axios response with stream data
 */
export function getStreamJoinData(companyId, streamId, usePublicRoute = false) {
  if (!usePublicRoute && isUserLoggedIn()) {
    return apiInstance.http.get(
      `/company/${companyId}/streams/${streamId}/join`
    )
  }

  return apiInstance.publicHttp.get(
    `/company/${companyId}/streams/${streamId}/join`
  )
}

/**
 * Update Stream
 * @param {string} companyId - Id of the company
 * @param {string} streamId - Id of the stream
 * @param {string} etag - etag for the stream
 * @param {Object} data - Data to update
 * @param {'live'|'ended'} data.status - session status
 * @param {string[]} data.participants - List of registered participants
 * @param {string[]} data.public_participants - List of public participants
 * @param {boolean} data.public - Session is Public or Private
 * @param {number} data.session_time - Total live session time
 * @param {string} data.end_time - Time stream end in UTC eg. 2023-02-01T12:27:25.973+00:00
 * @param {boolean} usePublicRoute - Boolean to use public route or not
 * @returns axios response
 */
export function patchStream(
  companyId,
  streamId,
  etag,
  data,
  usePublicRoute = false
) {
  if (!usePublicRoute && isUserLoggedIn()) {
    return apiInstance.http.patch(
      `/company/${companyId}/streams/${streamId}`,
      data,
      {
        headers: { 'If-Match': etag }
      }
    )
  }

  return apiInstance.publicHttp.patch(
    `/company/${companyId}/streams/${streamId}`,
    data,
    {
      headers: { 'If-Match': etag }
    }
  )
}
