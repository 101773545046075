<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-sheet elevation="2" rounded="lg" class="pb-3" min-height="150">
    <group-header class="py-5 px-3 text-center">
      {{ $props.heading }}
    </group-header>

    <template v-if="$props.sites.length">
      <v-list class="pt-0">
        <template v-for="(site, index) in $props.sites">
          <site-column-list-item
            :key="site.id"
            :site="site"
            :show-stakeholders="$props.showStakeholders"
            :to="{ name: 'SiteDetailsPage', params: { siteId: site.id } }"
            @site-deleted="removeSite"
            @collection-created="$props.collectionCreatedFunction"
            @site-added-to-collection="$props.siteAddedToCollectionFunction"
          />
          <v-divider
            :key="index"
            class="mx-4"
            v-if="index < $props.sites.length - 1"
          />
        </template>
      </v-list>
      <v-btn
        v-if="isMoreSites"
        class="rounded-lg text-body-2 font-weight-medium mx-auto d-block"
        text
        min-width="auto"
        @click="loadMore"
        :loading="isFetchingMoreSites"
      >
        Load More
      </v-btn>
    </template>
    <div
      v-else
      class="text-body-2 pa-2 px-5 text-center"
      style="color: #6e6e6d"
    >
      <slot v-if="$slots['no-items']" name="no-items"></slot>
      <template v-else> No Sites </template>
    </div>
  </v-sheet>
</template>

<script>
import { last } from 'lodash'

import GroupHeader from './GroupHeader.vue'
import SiteColumnListItem from './SiteColumnListItem.vue'

export default {
  name: 'SiteGridViewSection',

  components: {
    'group-header': GroupHeader,
    'site-column-list-item': SiteColumnListItem
  },

  data() {
    return {
      isFetchingMoreSites: false,
      isMoreSites: !(this.sites.length < this.$props.limit)
    }
  },

  props: {
    heading: { type: String, required: true },
    sites: { type: Array, required: true },
    groupKey: { type: String, required: true },
    deleteSiteFunction: { type: Function, required: true },
    siteAddedToCollectionFunction: { type: Function, required: true },
    collectionCreatedFunction: { type: Function, required: true },
    loadMoreSites: { type: Function, required: true },
    limit: { type: Number, required: true },
    showStakeholders: { type: Boolean, required: true }
  },

  methods: {
    async loadMore() {
      this.isFetchingMoreSites = true
      this.isMoreSites = await this.$props.loadMoreSites(
        this.$props.groupKey,
        last(this.$props.sites).creation_time
      )
      this.isFetchingMoreSites = false
    },
    removeSite(siteId) {
      this.$props.deleteSiteFunction(siteId, this.$props.groupKey)
    }
  }
}
</script>
