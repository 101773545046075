// Copyright (C) 2023-2024 Skylark Drones

import apiInstance from '@/config'

/**
 * Fetch Tips List from the Server
 *
 * @param {Number} pageNo page no. to fetch data from
 * @param {Number} limit number of tips to return in one page
 * @param {Boolean} excludeDraft exclude tips which are not published
 * @param {String} sortBy sort on basis of (creation_time/up_votes/down_votes)
 * @param {String} sortOrder sorting order (asc/des)
 */
export function getTips(
  pageNo = 1,
  limit = 10,
  excludeDraft = false,
  sortBy,
  sortOrder
) {
  const params = { limit, page: pageNo, exclude_draft: excludeDraft }
  if (sortBy) {
    params.sort_by = sortBy
    params.sort_order = sortOrder
  }
  return apiInstance.http.get('/tips', { params })
}

/**
 * @param {FormData} data post request payload
 * @returns
 */
export function createTip(data) {
  return apiInstance.http.post(`/tips/publish`, data)
}

/**
 * @param {String} tipId object Id of the Tip
 * @param {FormData} data edit request payload
 * @returns
 */
export function editTip(tipId, data) {
  return apiInstance.http.patch(`/tips/${tipId}`, data)
}

/**
 * Delete tip
 *
 * @param {String} tipId Id of the tip
 * @returns
 */
export function deleteTip(tipId) {
  return apiInstance.http.delete(`/tips/${tipId}`)
}

export function getTipsOfTheDay() {
  return apiInstance.http.get(`/tips/tip_of_the_day`)
}

/**
 * Upvote tip of the day
 *
 * @param {String} tipId Id of the tip
 * @returns
 */
export function upvoteTipsOfTheDay(tipId) {
  return apiInstance.http.patch(`/tips/${tipId}/upvote`)
}

/**
 * Downvote tip of the day
 *
 * @param {String} tipId Id of the tip
 * @returns
 */
export function downTipsOfTheDay(tipId) {
  return apiInstance.http.patch(`/tips/${tipId}/downvote`)
}
