<!-- Copyright (C) 2022-2024 Skylark Drones -->
<template>
  <v-row>
    <template v-for="index in [1, 2, 3, 4, 5]">
      <v-col cols="6" md="3" lg="3" xl="2" :key="index" class="mb-2">
        <site-card-loader />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import SiteCardLoader from './SiteCardLoader.vue'

export default {
  name: 'SiteGridSkeletonLoader',

  components: {
    SiteCardLoader
  }
}
</script>
