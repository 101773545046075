var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{ref:"tipsCard",staticClass:"rounded-lg pa-6 fill-height"},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{class:{
        'pr-4': !_vm.isSingleColumnLayout,
        'pb-4': _vm.isSingleColumnLayout
      },attrs:{"cols":_vm.isSingleColumnLayout ? 12 : 5}},[(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"image","width":"100%","height":"200"}}):_c('v-img',{staticClass:"rounded-lg",attrs:{"contain":"","src":_vm.tipOfTheDay.thumbnail,"max-height":"220"}})],1),_c('v-col',{attrs:{"cols":_vm.isSingleColumnLayout ? 12 : 7}},[(_vm.isLoading)?_c('div',[_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"heading"}}),_c('v-skeleton-loader',{attrs:{"type":"paragraph"}})],1):_c('v-row',{staticClass:"fill-height",class:{
          'text-center': _vm.isSingleColumnLayout
        },attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":_vm.isSingleColumnLayout ? 'center' : null}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"yellow darken-3"}},[_vm._v(" mdi-lightbulb-on-outline ")]),_c('div',{staticClass:"text-overline text--secondary",staticStyle:{"color":"var(--v-primary-font-color-base)","line-height":"1.5"}},[_vm._v(" Tip of the Day ")])],1),_c('div',{staticClass:"text-h6",staticStyle:{"color":"var(--v-primary-font-color-base)"}},[_vm._v(" "+_vm._s(_vm.tipOfTheDay.title)+" ")]),_c('div',{staticClass:"text-body-2 mt-2 mx-auto",staticStyle:{"color":"var(--v-secondary-font-color-base)"},domProps:{"innerHTML":_vm._s(_vm.tipOfTheDay.description)}})],1),_c('v-col',{staticClass:"mt-8",class:{
            'text-center': _vm.isSingleColumnLayout
          },attrs:{"cols":"12","align-self":"end"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":_vm.isSingleColumnLayout ? 'center' : undefined}},[_c('v-col',{staticClass:"text-body-2",staticStyle:{"color":"var(--v-primary-font-color-base)"},attrs:{"cols":"auto"}},[_vm._v(" Did you find this helpful? ")]),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","small":"","color":"green"},on:{"click":_vm.upvoteTip}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-thumb-up-outline")])],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"red"},on:{"click":_vm.downvoteTip}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-thumb-down-outline")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }