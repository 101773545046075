<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    text
    rounded
    class="btnLabel text-none text-subtitle-1"
    :block="$vuetify.breakpoint.smAndDown"
  >
    {{ btnLabel }}
  </v-btn>
</template>

<script>
export default {
  name: 'HomeNavBarBtn',
  props: {
    btnLabel: {
      required: true
    }
  }
}
</script>

<style scoped>
.btnLabel {
  color: var(--v-primary-font-color);
}

.btnLabel:hover {
  color: var(--v-primary-base);
}
</style>
