// Copyright (C) 2024 Skylark Drones

import { UnitConverter } from './UnitConverter'
import store from '@/store'

class SpeedUnitConverter extends UnitConverter {
  storeState = store.state.generic

  static Units = {
    kmph: 'kmph',
    miph: 'miph',
    mps: 'mps'
  }

  mapResponse = {
    kmph: { shortUnit: 'km/h', longUnit: 'kilometers per hour' },
    miph: { shortUnit: 'mi/h', longUnit: 'miles per hour' },
    mps: { shortUnit: 'm/s', longUnit: 'meters per second' }
  }

  constructor() {
    super()

    this.toPrefferedUnit = {
      [SpeedUnitConverter.Units.kmph]: value => value * (18 / 5),
      [SpeedUnitConverter.Units.miph]: value => value * 2.23694,
      [SpeedUnitConverter.Units.mps]: value => value
    }

    this.toBaseUnit = {
      [SpeedUnitConverter.Units.kmph]: value => value * (5 / 18),
      [SpeedUnitConverter.Units.miph]: value => value / 2.23694,
      [SpeedUnitConverter.Units.mps]: value => value
    }
  }

  /**
   * Converts a value from default unit of speed(m/s) to user's preffered unit of speed
   * @param {number} value The numerical value to be converted .
   * @returns {Object} The object posess 3 keys, 'value': value in preferred unit, 'shortUnit': preffered unit in short form, 'longUnit': preffered unit in long form .
   */
  convert(
    value,
    baseUnit = SpeedUnitConverter.Units.mps,
    outputUnit = this.storeState.user?.unit_preferences?.speed
  ) {
    const preferredSpeedUnit = outputUnit ?? baseUnit
    return {
      value: this.toPrefferedUnit[preferredSpeedUnit](value),
      shortUnit: this.mapResponse[preferredSpeedUnit].shortUnit,
      longUnit: this.mapResponse[preferredSpeedUnit].longUnit
    }
  }

  convertToBaseUnit(value) {
    const preferredSpeedUnit =
      this.storeState.user?.unit_preferences?.speed ?? 'mps'
    const exponentValue = 10 ** UnitConverter.PRECISION
    return (
      Math.round(this.toBaseUnit[preferredSpeedUnit](value) * exponentValue) /
      exponentValue
    )
  }
}

export default new SpeedUnitConverter()
