var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"600","fullscreen":_vm.$vuetify.breakpoint.xs,"content-class":_vm.$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-lg'}},[_c('v-card',[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"d-flex align-center justify-center text-center pt-0 pb-4 text-h4 font-weight-medium text-truncate",staticStyle:{"color":"var(--v-primary-font-color-base)"}},[_vm._v(" "+_vm._s(_vm.$props.drone.model_name)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","href":_vm.$props.drone.website,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1)],1),_c('v-btn',{attrs:{"absolute":"","right":"","top":"","icon":""},on:{"click":function($event){return _vm.$emit('on-close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-row',{staticClass:"mb-7",attrs:{"no-gutters":"","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-col',{staticClass:"image-container",attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto image",style:({
                transition: 'transform 0.3s ease-in-out',
                transform: hover ? 'translateX(-144px)' : 'none'
              }),attrs:{"width":"100%","height":"250","contain":"","src":_vm.$props.drone.picture_url}}),_c('v-row',{staticClass:"overlay-content",style:({ right: hover ? '5vw' : _vm.none }),attrs:{"no-gutters":"","align":"center"}},[_c('div',{class:{ 'mx-auto': _vm.$vuetify.breakpoint.xs },style:({
                  maxWidth: _vm.$vuetify.breakpoint.xs ? '350px' : null
                })},_vm._l((_vm.specifications),function(spec){return _c('v-row',{key:spec.label,staticClass:"flex-nowrap",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"small":"","color":"#d9d9d9"}},[_vm._v(_vm._s(spec.icon))])],1),_c('v-col',{staticClass:"pa-2"},[_c('div',{staticClass:"text-subtitle-2 font-weight-regular",staticStyle:{"color":"#d9d9d9"}},[_vm._v(" "+_vm._s(spec.label)+" ")]),_c('div',{staticClass:"text-truncate text-subtitle-1 font-weight-medium",staticStyle:{"color":"#ffffff"}},[_vm._v(" "+_vm._s(spec.value)+" "+_vm._s(spec.unit)+" ")])])],1)}),1)])],1)]}}])})],1),_c('div',{staticClass:"mx-auto pl-12 pr-12"},[_c('h6',{staticClass:"text-h6 text-center mb-7 text-uppercase",staticStyle:{"color":"var(--v-primary-font-color-base)"}},[_vm._v(" SUPPORTED FEATURES BY "+_vm._s(_vm.appNameAbbr)+" ")]),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-expansion-panels',{staticStyle:{"z-index":"unset","border-radius":"8px"},attrs:{"accordion":""}},_vm._l((_vm.capabilities),function(capability){return _c('v-expansion-panel',{key:capability.label,class:capability.value ? 'green lighten-4' : 'red lighten-4'},[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('v-row',[_c('v-col',{class:capability.value
                        ? 'green--text text--darken-4'
                        : 'red--text text--darken-4',attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(capability.label)+" "),_c('v-icon',{staticStyle:{"margin-left":"auto"},attrs:{"color":capability.value ? 'green darken-4' : 'red darken-4'}},[_vm._v(" mdi-chevron-down ")])],1),_c('v-spacer'),_c('v-col',[_c('v-icon',{attrs:{"color":capability.value ? 'green darken-4' : 'red darken-4'}},[_vm._v(" "+_vm._s(capability.value ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline')+" ")])],1)],1)],1),_c('v-expansion-panel-content',{staticClass:"text-body-2",staticStyle:{"color":"var(--v-secondary-font-color-base)"}},[_vm._v(" "+_vm._s(capability.info)+" ")])],1)}),1)],1)],1),_c('div',{staticClass:"text-center mt-6"},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.downloadLink,"depressed":"","rounded":"","width":"340","href":_vm.downloadLink,"target":"_blank"}},[(_vm.downloadLink)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download-outline")]):_vm._e(),_vm._v(" "+_vm._s(_vm.downloadLink ? `Download ${_vm.appNameAbbr}` : `${_vm.appNameAbbr} Not Supported`)+" ")],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }