// Copyright (C) 2024 Skylark Drones

import { cloneDeep, isNumber } from 'lodash'

import store from '@/store'
import { isValueInRange } from '@/utils/common'
import { getFreeFcl } from '@/services/FclService'

class FeatureAccessControl {
  /**
   * Check feature exist in the fcl object or not
   *
   * @throws Error when featureKey doesn't exist in the fcl
   *
   * @param {String} featureKey Feature key
   * @param {Object} customFcl fcl object
   */
  #isValidFeatureKey(featureKey, customFcl) {
    const fcl = customFcl ?? this.fcl
    if (!(featureKey in fcl)) {
      throw new Error(`FeatureAccessControl: Unknown feature ${featureKey}`)
    }
  }

  /**
   * Get the global fcl
   */
  get fcl() {
    return store.getters.activeFCL
  }

  /**
   * Get the feature data
   *
   * @param {String} featureKey Feature key
   * @param {Object} customFcl fcl object
   * @returns {Object} Feature data
   */
  getFeatureData(featureKey, customFcl) {
    this.#isValidFeatureKey(featureKey, customFcl)
    const fcl = customFcl ?? this.fcl
    const feature = cloneDeep(fcl[featureKey])

    if (feature.type === 'range') {
      feature.range[0] =
        feature.range[0] === null ? Number.NEGATIVE_INFINITY : feature.range[0]
      feature.range[1] =
        feature.range[1] === null ? Number.POSITIVE_INFINITY : feature.range[1]
    }
    return {
      aggregated: isNumber(feature.used),
      ...feature
    }
  }

  /**
   * Get is feature is enabled or not
   *
   * NOTE: Pass custom fcl if don't want to use global fcl. Some resource like livestream
   * doesn't depend on global fcl instead depend on the fcl of org created it which comes in
   * the resource response which should be used instead of global fcl
   *
   * @param {String} featureKey Feature key
   * @param {Object} customFcl fcl object
   * @returns {Boolean}
   */
  isEnabled(featureKey, customFcl) {
    return this.getFeatureData(featureKey, customFcl).enabled
  }

  /**
   * Check if the value is in the allowed range/limit or not
   *
   * NOTE: Pass custom fcl if don't want to use global fcl. Some resource like livestream
   * doesn't depend on global fcl instead depend on the fcl of org created it which comes in
   * the resource response which should be used instead of global fcl
   *
   * @param {String} featureKey Feature key
   * @param {Number} [value] feature usage
   * @param {Object} customFcl fcl object
   * @returns {Boolean}
   */
  isAllowed(featureKey, value, customFcl) {
    const feature = this.getFeatureData(featureKey, customFcl)

    if (feature.type === 'bool') {
      return feature.enabled
    }

    if (
      feature.type === 'range' &&
      isValueInRange(
        // If feature is aggregated add current usage to new usage
        feature.used * feature.aggregated + value,
        feature.range[0],
        feature.range[1]
      )
    ) {
      return true
    }

    return false
  }

  /**
   * Update the current usage of the feature. Returns updated feature data when
   * [customFcl] is passed. Otherwise directly updates the global fcl
   *
   * NOTE: Pass custom fcl if don't want to use global fcl. Some resource like livestream
   * doesn't depend on global fcl instead depend on the fcl of org created it which comes in
   * the resource response which should be used instead of global fcl
   *
   *
   * @param {String} featureKey Feature key
   * @param {Number} currentUsage feature usage
   * @param {Object} customFcl fcl object
   * @returns {Object} Return updated feature data only when customFcl is passed
   */
  updateUsage(featureKey, currentUsage, customFcl) {
    const feature = this.getFeatureData(featureKey, customFcl)

    // do nothing for non aggregated features
    if (!feature.aggregated) return

    const updatedData = {
      used: feature.used + currentUsage
    }

    // toggle off the feature if usage reached the range
    if (feature.type === 'range') {
      const isUsageWithinRange = isValueInRange(
        updatedData.used,
        feature.range[0],
        feature.range[1],
        { inclusiveMax: false, inclusiveMin: false }
      )

      updatedData['enabled'] = isUsageWithinRange
    }

    // Return updated feature data when customFcl is passed
    if (customFcl) {
      return updatedData
    }

    store.dispatch('updateFCL', {
      featureKey,
      payload: updatedData
    })
  }
}

export function processRawFCL(fclList) {
  return fclList.reduce((fclObject, feature, index) => {
    fclObject[feature.key] = { _index: index, ...feature }
    return fclObject
  }, {})
}

export async function loadFreeFCL() {
  if (store.getters.user) return

  try {
    const resp = await getFreeFcl()
    store.commit('setFreeFCL', resp.data.fcl)
  } catch (error) {
    store.commit('updateNotificationPayload', {
      message: 'Unable to fetch free fcl',
      code: 400,
      timeout: 5000
    })
  }
}

export default new FeatureAccessControl()
