// Copyright (C) 2018-2024 Skylark Drones

export default {
  userObject: state => {
    return state.userObject
  },
  tokenAuthorized: state => {
    return state.tokenAuthorized
  },
  tokenIssuer: state => {
    return state.tokenIssuer
  },
  idToken: state => {
    return state.id_token
  },
  isLoggedIn: (state, getters) => !!state.id_token && !!getters.user
}
