<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-carousel
    v-model="model"
    height="100%"
    hide-delimiters
    :show-arrows="false"
    continuous
    interval="15000"
    cycle
    light
  >
    <v-carousel-item v-for="slide in slides" :key="slide.title">
      <v-row align-content="center" justify="center" style="height: 100%">
        <v-col cols="auto">
          <v-icon :color="slide.iconColor" size="48">{{ slide.icon }}</v-icon>
        </v-col>
        <v-col cols="12" class="text-center">
          <div class="text-body-1 font-weight-medium primary-font-color--text">
            {{ slide.title }}
          </div>
          <div
            class="text-body-2 text--secondary mt-2 mx-6"
            v-html="slide.text"
          ></div>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'DataVerificationFeatureCarousel',

  data() {
    return {
      model: 0,
      slides: [
        {
          icon: 'mdi-clock-fast',
          iconColor: 'blue',
          title: '3 Step Verification',
          text: 'Verify your drone data in 3 simple steps. It is faaast! It is easy!'
        },
        {
          icon: 'mdi-robot-happy-outline',
          iconColor: 'yellow darken-2',
          title: 'Stress Free',
          text:
            'Worried if data was captured correctly? Fret not.<br />' +
            '<b>Data Check</b> will identify all failures and inform you.'
        },
        {
          icon: 'mdi-bank',
          iconColor: 'green',
          title: 'Save Time & Money',
          text:
            '<b>Data Check</b> allows pilots to catch data issues on site and act' +
            '<br />immediately, thereby saving time and costs.'
        }
      ]
    }
  }
}
</script>
