// Copyright (C) 2020-2024 Skylark Drones

import { logoutUser } from '@/services/AuthenticateService'

export default {
  setAuthSession({ commit }, payload) {
    commit('setIdToken', payload.idToken)
    commit('setTokenAuthorized', payload.tokenAuthorized)
    commit('setFirstIssuedAt', payload.firstIssuedAt)
    commit('setExpiresAt', payload.expiresAt)
    commit('userObject', payload.userObject)
  },
  signOut({ commit, getters }) {
    // telling BE user is logged out
    logoutUser(getters.idToken)

    commit('setIdToken', null)
    commit('setTokenIssuer', null)
    commit('setTokenAuthorized', null)
    commit('setFirstIssuedAt', null)
    commit('setExpiresAt', null)
    commit('userObject', null)
    commit('updateUser', null)
  }
}
