<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-row no-gutters class="pa-4 fill-height">
    <v-col cols="12">
      <v-list color="transparent" dense class="py-0">
        <v-hover
          v-for="(stream, i) in $props.streams"
          :key="stream.id"
          v-slot="{ hover }"
        >
          <v-list-item
            class="rounded-lg"
            :class="{ 'mt-2': i !== 0 }"
            :style="{
              'background-color': hover ? '#ffb2ae' : '#ff9994'
            }"
            @click="onStreamClick(stream)"
          >
            <v-list-item-content>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
                class="flex-nowrap"
              >
                <v-col>
                  <div
                    class="text-truncate text-body-1 font-weight-medium"
                    style="width: 100%"
                  >
                    <template v-if="stream.status === 'live'">
                      Started
                    </template>
                    {{ formatDateTime(stream.start_time) }}
                  </div>
                  <div class="text-truncate text-body-2 d-flex align-center">
                    <v-icon small class="mr-2" color="black">
                      mdi-account-group-outline
                    </v-icon>
                    <span>
                      {{ stream.participant_count }}
                    </span>
                    <template v-if="stream.location">
                      <span class="mx-2">·</span>
                      <span class="text-truncate" data-hj-suppress>
                        {{ stream.location }}
                      </span>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-action>
              <v-row no-gutters align="center">
                <v-col cols="auto" class="mr-0 mr-sm-4">
                  <v-chip
                    v-if="stream.status === 'live'"
                    label
                    small
                    class="text-caption font-weight-medium text-uppercase pr-2"
                  >
                    <v-icon left color="red" small>mdi-access-point</v-icon>
                    Live
                  </v-chip>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp" cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <profile-image-avatar
                        v-bind="attrs"
                        v-on="on"
                        :size="40"
                        :name="stream.host.name"
                        :image-src="stream.host.profile_picture"
                        color="white"
                      />
                    </template>
                    <span data-hj-suppress>
                      {{
                        computeNameInitials(stream.host.name) !== '-'
                          ? stream.host.name
                          : 'No Name Set'
                      }}
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-hover>
      </v-list>
    </v-col>
    <v-col cols="12" align-self="end" class="mt-2">
      <v-btn
        color="#ff9994"
        block
        depressed
        class="rounded-lg"
        height="64"
        :to="{
          name: 'StreamOverviewPage',
          params: { companyId: currentActiveOrganization.id }
        }"
      >
        {{
          $vuetify.breakpoint.xs ? 'View All' : 'View All streams & Recordings'
        }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

import { page } from '@/utils/analytics/abstraction/eventEnums'
import { formatDateTime, computeNameInitials } from '@/utils/common/display'
import ProfileImageAvatar from '@/components/common/ProfileImageAvatar.vue'

export default {
  name: 'StreamListHomepage',

  components: {
    ProfileImageAvatar
  },

  props: {
    streams: { type: Array, required: true }
  },

  data() {
    return {
      computeNameInitials,
      formatDateTime
    }
  },

  computed: {
    ...mapGetters(['currentActiveOrganization'])
  },

  methods: {
    onStreamClick(stream) {
      if (stream.status === 'live') {
        this.$analytics.trackPageStreamView(stream.public, page.HomePage)

        this.$router.push({
          name: 'StreamPrivatePage',
          params: {
            companyId: this.currentActiveOrganization.id,
            streamId: stream.id
          }
        })
        return
      }

      this.$analytics.trackPageRecordingView(page.HomePage)
      this.$router.push({
        name: 'RecordingViewPage',
        params: {
          companyId: this.currentActiveOrganization.id,
          streamId: stream.id
        }
      })
    }
  }
}
</script>
