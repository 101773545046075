// Copyright (C) 2023-2024 Skylark Drones

import store from '@/store'
import apiInstance from '@/config'

export default {
  install(Vue) {
    Vue.prototype.$metaRbac = (
      method,
      route,
      baseApiPath = apiInstance.baseApiPath
    ) => {
      try {
        const metaRbacRules = store.state.generic.user.meta_rbac_rules
        const userMetaRoles = store.state.generic.user.meta_roles

        route = `/${baseApiPath}/${route}`

        if (!userMetaRoles) return false

        // Check if route and method are supported by the server
        if (!(route in metaRbacRules && method in metaRbacRules[route]))
          return false

        return (
          userMetaRoles.findIndex(metaRole => {
            return metaRole in metaRbacRules[route][method]
          }) !== -1
        )
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }
}
