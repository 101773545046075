// Copyright (C) 2023-2024 Skylark Drones

import MixpanelAnalytics from './mixpanelAnalytics.js'

export const analytics = new MixpanelAnalytics()

export default {
  install(Vue) {
    Vue.prototype.$analytics = analytics
  }
}
