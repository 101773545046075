// Copyright (C) 2021-2024 Skylark Drones

import apiInstance from '@/config'
import store from '@/store'

export function deleteMissions(data) {
  const companyId = store.getters.currentActiveOrganizationId
  return apiInstance.http.delete(`/company/${companyId}/missions/bulk`, {
    data
  })
}

// v3 Mission Preview Generation
export function postMissionsPreview(data, config) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.post(
    `/company/${companyId}/missions/generatepreview`,
    data,
    config
  )
}

// v3 Mission Generation
export function postGenerateMappingMissionPlans(data) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.post(
    `/company/${companyId}/missions/mapping/generate`,
    data
  )
}

export function postGenerateCorridorVideoMissionPlans(data) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.post(
    `/company/${companyId}/missions/video/generate`,
    data
  )
}

/**
 * Generate single mission preview
 * @param {Object} data Data required to generate the preview
 * @param {string} data.site Site ID of the preview to be generated
 * @param {string} data.mission_type Mission type of the preview to be generated
 * @param {string} data.camera_model Camera model of the preview to be generated
 * @param {Object} data.mission_params Mission parameters of the preview to be generated
 * @param {Object} data.geometry Geometry of the preview to be generated
 * @param {import('axios').AxiosRequestConfig} config Custom request configuration for handling cases such as cancelling previous requests
 * @returns {import('axios').AxiosResponse} axios response with mission plan that contains waypoints and characteristics
 */
export function generateMissionPreview(data, config) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.post(
    `/company/${companyId}/missions/preview`,
    data,
    config
  )
}

// Edit Mission
export function patchMission(missionId, data, etag) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.patch(
    `/company/${companyId}/missions/${missionId}`,
    data,
    {
      headers: { 'If-Match': etag }
    }
  )
}

/**
 * Creates a single mission
 * @param {Object} data Data required to create the mission
 * @param {string} data.site Site ID of the mission to be created
 * @param {string} data.mission_type Mission type of the mission to be created
 * @param {string} data.camera_model Camera model of the mission to be created
 * @param {Object} data.mission_params Parameters of the mission to be created
 * @param {Object} data.geometry Geometry of the mission to be created
 * @param {string} data.name Name of the mission to be created
 * @param {Object} data.waypoints Waypoints of the mission to be created
 * @param {Object} data.characteristics Characteristics of the mission to be created
 * @param {string} data.status Status of the mission to be created
 * @param {Array} data.failures Failures of the mission to be created
 * @returns {import('axios').AxiosResponse} axios response with created mission ID
 */
export function postMission(data) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.post(`/company/${companyId}/missions`, data)
}

/**
 * Deletes a single mission
 * @param {string} missionId - Id of the mission to be deleted
 */
export function deleteMission(missionId) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.delete(`/company/${companyId}/missions/${missionId}`)
}

/**
 * Fetches elevations for a given set of points
 * @param {Object} data Data required to fetch the elevation
 * @param {Array} data.coordinates coordinates for which the elevations have to be fetched
 * @param {string} [data.site_id=null] Site ID of the mission to be created / updated
 * @returns {import('axios').AxiosResponse} axios response containing list of elevations
 */
export function getElevations(data) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.post(
    `/company/${companyId}/missions/elevations`,
    data
  )
}

/**
 * Download mission in required format
 * @param {string} missionId - Id of the mission for which the features have to be downloaded
 * @param {Object} data parameters to specify output format
 * @param {string} [data.file_format] data.file_format - extension of the file (CSV, KML etc)
 * @param {string} [data.destination] data.destination - 3rd party app for which the file is being downloaded
 * @returns {import('axios').AxiosResponse} axios response containing output file content
 */
export function downloadMissionFeatures(missionId, data) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.get(
    `/company/${companyId}/missions/${missionId}/features`,
    {
      params: data
    }
  )
}

/**
 * Get a mission
 * @param {string} missionId - Id of the selected mission
 */
export function getMission(missionId) {
  const companyId = store.getters.currentActiveOrganizationId
  return apiInstance.http.get(`/company/${companyId}/missions/${missionId}`)
}
