// Copyright (C) 2018-2024 Skylark Drones

import Vue from 'vue'
import { isArray } from 'lodash'

import { preferencesListToMap } from '@/utils/common/userPreferences'

export default {
  updateUser(state, payload) {
    // If web preferences exist and is a list covert it into a map
    if (
      payload &&
      payload.web_preferences &&
      isArray(payload.web_preferences)
    ) {
      payload.web_preferences = preferencesListToMap(payload.web_preferences)
    }

    localStorage.setItem('user', JSON.stringify(payload))
    state.user = payload
  },
  updateUserAttribute(state, payload) {
    // If web preferences exist and is a list covert it into a map
    if (
      payload &&
      payload.web_preferences &&
      isArray(payload.web_preferences)
    ) {
      payload.web_preferences = preferencesListToMap(payload.web_preferences)
    }

    Object.entries(payload).forEach(([attribute, value]) => {
      Vue.set(state.user, attribute, value)
    })
    localStorage.setItem('user', JSON.stringify(state.user))
  },
  updateCompany(state, payload) {
    const { companyIndex, data } = payload

    Object.values(data).forEach(([attr, value]) => {
      Vue.set(state.user.companies[companyIndex], attr, value)
    })
  },

  updateNotificationPayload(state, payload) {
    state.notificationPayload = payload
  },

  setStream(state, stream) {
    state.stream = stream
  },
  updateFilters(state, filtersToUpdate) {
    Object.entries(filtersToUpdate).forEach(([key, value]) => {
      Vue.set(state.filters, key, value)
    })
  },
  setUsersList(state, usersList) {
    state.usersList.data = usersList
  },
  setFetchingUsersList(state, isFetching) {
    state.usersList.fetching = isFetching
  },
  updateUsersListItem(state, { userId, data }) {
    if (!state.usersList.data) return

    const index = state.usersList.data.findIndex(user => user.id === userId)
    Vue.set(state.usersList.data, index, {
      ...state.usersList.data[index],
      ...data
    })
  },
  setGroupByType(state, groupByType) {
    state.groupByType = groupByType
  },
  setViewMode(state, viewType) {
    state.viewMode = viewType
  },
  setIsFetchingUser(state, isFetching) {
    state.isFetchingUser = isFetching
  },

  setUserRole(state, role) {
    state.userRole = role
  },
  setCurrentActiveOrganizationId(state, organizationId) {
    state.currentActiveOrganizationId = organizationId
  },
  updateOrgErrorNotification(state, payload) {
    state.orgErrorNotification = payload
  },
  setActiveOrganizationDetails(state, payload) {
    state.activeOrganizationDetails = payload
  },
  updateActiveOrganizationDetails(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      Vue.set(state.activeOrganizationDetails, key, value)
    })
  },
  setSupportedDronesList(state, dronesList) {
    state.supportedDronesList = dronesList
  },
  setUtmLayerDescriptionCache(state, { layerId, descriptionData }) {
    Vue.set(state.utmLayerDescriptionCache, layerId, descriptionData)
  },
  setLivestreamCount(state, count) {
    state.livestreamCount = count
  },
  updateCameraModels(state, payload) {
    state.cameraModels = payload
  },
  updateMeasureMode(state, mode) {
    Vue.set(state.measureState, 'mode', mode)
  },
  updateMeasureValue(state, value) {
    Vue.set(state.measureState, 'value', value)
  },
  updateMeasureDrawState(state, drawState) {
    Vue.set(state.measureState, 'drawState', drawState)
  },
  resetMeasureState(state) {
    state.measureState = {
      mode: 'off',
      value: 0,
      drawState: 0
    }
  },
  updateFCL(state, { companyIndex, featureIndex, payload }) {
    Object.entries(payload).forEach(([attr, value]) => {
      Vue.set(state.user.companies[companyIndex].fcl[featureIndex], attr, value)
    })
  },
  setFreeFCL(state, payload) {
    state.freeFCL = payload
  }
}
