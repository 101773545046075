<!-- Copyright (C) 2022-2024 Skylark Drones -->
<template>
  <v-row
    v-bind="$attrs"
    class="overflow-x-auto"
    :dense="$vuetify.breakpoint.xsOnly"
    style="flex-wrap: nowrap"
  >
    <template v-for="collection in collections">
      <v-col :key="collection.id" cols="6" md="3" lg="3" xl="2">
        <collection-card
          :collection="collection"
          @collection-deleted="onCollectionDeleted"
          @collection-updated="onCollectionUpdated"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import CollectionCard from '@/components/collections/CollectionCard'

export default {
  name: 'CollectionsHorizontalListView',

  components: {
    'collection-card': CollectionCard
  },

  props: {
    collections: {
      type: Array,
      required: true
    }
  },

  methods: {
    onCollectionDeleted(collectionId) {
      this.$emit('collection-deleted', collectionId)
    },
    onCollectionUpdated(collectionId) {
      this.$emit('collection-updated', collectionId)
    }
  }
}
</script>
