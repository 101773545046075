// Copyright (C) 2018-2024 Skylark Drones

import apiInstance from '@/config'

export function getAllSensorModels() {
  return apiInstance.http.get('/assets/sensor/')
}

export function getAllDrones() {
  return apiInstance.http.get('/assets/drone/')
}

export function getAllBatteriesModels() {
  return apiInstance.http.get('/assets/battery/')
}
