<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-container fluid class="pa-4 px-8" :class="isLoggedIn ? 'pt-4' : 'pt-5'">
    <v-row v-if="!isLoggedIn" no-gutters align="center">
      <v-col cols="12">
        <home-nav-bar class="mb-4" />
        <home-page-hero-card />
      </v-col>
    </v-row>

    <v-row v-if="isLoggedIn" no-gutters align="center">
      <v-col>
        <v-row no-gutters>
          <h5 class="text-h5 font-weight-medium mr-2 primary-font-color--text">
            {{ welcomeTitle }}, {{ user.name.split(' ')[0] }}
          </h5>
          <v-icon class="ml-2" color="primary-font-color">mdi-hand-wave</v-icon>
        </v-row>
        <v-row no-gutters v-if="isLoggedIn" class="text-body-1 text--secondary">
          {{ welcomeSubtitleOfTheDay }}
        </v-row>
      </v-col>
      <v-col cols="auto" v-if="isLoggedIn && isOrgOnFreePlan">
        <pro-button />
      </v-col>
    </v-row>

    <v-row v-if="isLoggedIn && !isUserProfileComplete">
      <v-col cols="12">
        <incomplete-profile-alert :showAction="true" />
      </v-col>
    </v-row>

    <v-row v-if="isLoggedIn" class="mt-4">
      <v-col cols="12">
        <site-row />
      </v-col>
    </v-row>

    <HomePageSectionHeader v-if="isLoggedIn" title="Live Streams" />
    <v-row v-if="isLoggedIn">
      <v-col cols="12" lg="6">
        <stream-row />
      </v-col>
      <v-col cols="12" lg="6">
        <tips-card />
      </v-col>
    </v-row>

    <!-- Show the data verification row above supported drones when logged in -->
    <HomePageSectionHeader
      v-if="isLoggedIn"
      title="Airspace & Drone Images Check"
    />
    <v-row v-if="isLoggedIn">
      <v-col cols="12" md="6" xl="4">
        <airspace-advisory-card />
      </v-col>
      <v-col cols="12" md="6" xl="8">
        <data-verification-card />
      </v-col>
    </v-row>

    <HomePageSectionHeader :title="`Download ${appNameAbbr} For Your Drone`" />
    <v-row>
      <v-col cols="12" md="4" lg="6" xl="7">
        <v-lazy>
          <supported-drones-card />
        </v-lazy>
      </v-col>
      <v-col cols="12" md="8" lg="6" xl="5">
        <m3-e-card />
      </v-col>
    </v-row>

    <template v-if="!disabledFeatures.news && isLoggedIn">
      <HomePageSectionHeader title="Industry News & Updates" />
      <v-row>
        <v-col cols="12">
          <v-lazy>
            <news-row />
          </v-lazy>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

import { randomNumberInRange } from '@/utils/common'
import NewsRow from './recent-news/NewsRow.vue'
import StreamRow from './stream-row/StreamRow.vue'
import SiteRow from './site-row/SiteRow.vue'
import M3ECard from './supported-drones/M3ECard.vue'
import SupportedDronesCard from './supported-drones/SupportedDronesCard.vue'
import AirspaceAdvisoryCard from './airspace-advisory/AirspaceAdvisoryCard.vue'
import TipsCard from './tips-card/TipsCard.vue'
import HomePageHeroCard from './HomePageHeroCard.vue'
import DataVerificationCard from './data-verification-card/DataVerificationCard.vue'
import HomePageSectionHeader from './HomePageSectionHeader.vue'
import HomeNavBar from '@/components/homepage/HomeNavBar.vue'

const IncompleteProfileAlert = () =>
  import('@/components/common/IncompleteProfileAlert.vue')
const ProButton = () => import('@/common/ProButton.vue')

export default {
  name: 'HomePage',

  components: {
    NewsRow,
    StreamRow,
    SiteRow,
    M3ECard,
    SupportedDronesCard,
    AirspaceAdvisoryCard,
    TipsCard,
    HomePageHeroCard,
    DataVerificationCard,
    HomePageSectionHeader,
    IncompleteProfileAlert,
    HomeNavBar,
    ProButton
  },

  computed: {
    ...mapGetters('brand', ['appNameAbbr', 'disabledFeatures']),
    ...mapGetters([
      'user',
      'isUserProfileComplete',
      'isLoggedIn',
      'currentActiveOrganization'
    ]),
    welcomeSubtitleOfTheDay() {
      const welcomeSubtitles = [
        "What's your plan for today?",
        `Have you tried ${this.appNameAbbr} Live Stream?`,
        'Ready for a flight?'
      ]

      const num = randomNumberInRange(0, welcomeSubtitles.length - 1)
      return welcomeSubtitles[num]
    },
    welcomeTitle() {
      const title = ['Hello', 'time_based_greeting', 'Howdy'][
        randomNumberInRange(0, 2)
      ]

      if (title === 'time_based_greeting') {
        const hour = new Date().getHours()

        if (hour >= 0 && hour <= 10) {
          return 'Good Morning'
        }

        if (hour >= 11 && hour <= 17) {
          return 'Good Afternoon'
        }

        if (hour >= 17 && hour <= 23) {
          return 'Good Evening'
        }
      }

      return title
    },
    isOrgOnFreePlan() {
      return isEmpty(this.currentActiveOrganization.plan)
    }
  },

  mounted() {
    this.$analytics.trackHomeView()
  }
}
</script>
