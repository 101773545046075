<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <user-list-filter
    label="Flown By"
    icon="mdi-account-multiple-outline"
    :filter="filters.pilots"
    :filter-function="_updateFilters"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import UsersListFilter from './UsersListFilter.vue'

export default {
  name: 'FlownByFilter',

  components: {
    'user-list-filter': UsersListFilter
  },

  computed: {
    ...mapGetters(['filters'])
  },

  methods: {
    ...mapMutations(['updateFilters']),
    _updateFilters(users) {
      this.updateFilters({ pilots: users })
    }
  }
}
</script>
