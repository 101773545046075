// Copyright (C) 2020-2024 Skylark Drones

import apiInstance from '@/config'

export function getCompanies(q = null, limit = 20, pageNo = 1) {
  const config = { params: { limit, page: pageNo } }
  if (q) config.params.q = q
  return apiInstance.http.get('/company', config)
}

export function getCompany(id) {
  return apiInstance.http.get(`/company/${id}`)
}

export function updateCompany(id, etag, payload) {
  return apiInstance.http.patch(`/company/${id}`, payload, {
    headers: { 'If-Match': etag }
  })
}

export function deleteCompany(id) {
  return apiInstance.http.delete(`/company/${id}`)
}

export function getCompanyMembers(id, options) {
  return apiInstance.http.get(`/company/${id}/members`, { params: options })
}

/**
 *
 * @param {String} id Company id
 * @param {Object} options Options to pass as request query params
 * @param {keyof InviteStatus} options.status Option to filter invites by status
 * @returns
 */
export function getCompanyInvites(id, options) {
  if (!id) {
    throw Error(`Invalid company id (${id}) passed to getCompanyInvites`)
  }

  return apiInstance.http.get(`/company/${id}/invites`, { params: options })
}

/**
 *
 * @param {String} id Id of the company
 * @param {Object} payload Data to to post invite
 * @param {String} payload.email Email of the user to send invite to
 * @param {'member', 'admin', 'owner'} payload.role Role to assign the the invited user
 * @returns
 */
export function postCompanyInvite(id, payload) {
  if (!id) {
    throw Error(`Invalid company id (${id}) passed to postCompanyInvite`)
  }

  return apiInstance.http.post(`/company/${id}/invites`, payload)
}

export function createCompany(payload) {
  return apiInstance.http.post('/company', payload)
}
