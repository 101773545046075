<!-- Copyright (C) 2021-2024 Skylark -->
<template>
  <v-btn
    v-bind="$attrs"
    :block="$vuetify.breakpoint.xsOnly"
    class="white--text text-none text-body-1 font-weight-medium pa-0"
    color="#4285F4"
    depressed
    width="280"
    height="40"
    :loading="isContinuingWithGoogle"
    @click="onContinueWithGoogleButtonClicked"
  >
    <v-row no-gutters align="center" justify="start">
      <v-col cols="auto">
        <v-img
          class="mr-n2"
          contain
          height="38"
          src="/static/google_signin_buttons/1x/google-logo.png"
        />
      </v-col>
      <v-col class="mr-1">
        <div class="text-center text-body-1 font-weight-medium">
          Login / Sign up with Google
        </div>
      </v-col>
    </v-row>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as Sentry from '@sentry/browser'
import { isEmpty } from 'lodash'

import { initializeGIS } from '@/auth/Google'
import { authenticateUser } from '@/services/AuthenticateService'
import { errorType, page } from '@/utils/analytics/abstraction/eventEnums.js'
import { companySelection } from '@/utils/common'

export default {
  name: 'ContinueWithGoogleButton',

  data() {
    return {
      isContinuingWithGoogle: !isEmpty(this.$router.currentRoute.query),
      googleUser: null
    }
  },

  computed: {
    ...mapGetters(['user'])
  },

  async mounted() {
    if ('id_token' in this.$router.currentRoute.query) {
      await this.afterAuthorization()
      this.onContinueWithGoogleButtonClicked = false
      return
    }

    if ('error' in this.$router.currentRoute.query) {
      this.$analytics.trackError(
        page.HomePage,
        errorType.GOOGLE_AUTH,
        this.$router.currentRoute.query.error
      )
      await this.errorHandler(this.$router.currentRoute.query.error)
      this.onContinueWithGoogleButtonClicked = false
    }
  },

  methods: {
    ...mapMutations([
      'updateNotificationPayload',
      'updateUser',
      'setCurrentActiveOrganizationId'
    ]),
    ...mapActions(['setAuthSession']),
    async onContinueWithGoogleButtonClicked() {
      this.isContinuingWithGoogle = true
      const client = await initializeGIS()
      client.requestCode()
      this.isContinuingWithGoogle = false
    },
    async afterAuthorization() {
      await this.setAuthSession({
        idToken: this.$router.currentRoute.query.id_token,
        tokenAuthorized: true,
        firstIssuedAt: this.$router.currentRoute.query.first_issued_at,
        expiresAt: this.$router.currentRoute.query.expires_at,
        userObject: {
          name: this.$router.currentRoute.query.user_name,
          email: this.$router.currentRoute.query.user_email,
          picture: this.$router.currentRoute.query.user_picture
        }
      })

      try {
        const response = await authenticateUser(
          this.$router.currentRoute.query.id_token
        )
        this.updateUser(response.data.data)

        const activeCompanyId = companySelection.previousCompanyId
        this.setCurrentActiveOrganizationId(activeCompanyId)
        companySelection.persist()

        // Enriching sentry events with user id
        Sentry.setUser({ id: this.user.id })

        this.$analytics.trackLogin(this.user, page.HomePage)

        if (!this.user.designation.includes('Super Admin')) {
          await this.$router.push({
            name: 'LoggedInHomePage',
            params: { companyId: activeCompanyId }
          })
        } else {
          await this.$router.push({ name: 'ProductUsageOverview' })
        }
      } catch (error) {
        console.error(error)
        if ('response' in error) {
          await this.errorHandler(error.response.data.error_type)
        } else {
          await this.errorHandler(error.message)
        }
      }

      this.isContinuingWithGoogle = false
    },
    async errorHandler(e) {
      Sentry.setTags({
        source: 'auth',
        operation: 'after_authorization'
      })
      switch (e) {
        case 'popup_blocked_by_browser':
          Sentry.addBreadcrumb({
            message: `${e}: Popup blocked`,
            level: 'log'
          })
          this.updateNotificationPayload({
            code: 400,
            message:
              'Your browser may have blocked popups that are required to continue with Google. ' +
              'Please unblock them and refresh the page.',
            timeout: -1
          })
          break
        case 'cookie_disabled_by_browser':
          Sentry.addBreadcrumb({
            message: `${e}: Cookies disabled`,
            level: 'info'
          })
          this.updateNotificationPayload({
            code: 400,
            message:
              'Your browser may have disabled cookies that are required to sign in with Google. ' +
              'Please enable them and refresh the page.',
            timeout: -1
          })
          break
        case 'google_auth_obj_initialize_error':
          Sentry.addBreadcrumb({
            message: `${e}: Google Sign-in service error`,
            level: 'info'
          })
          this.updateNotificationPayload({
            code: 400,
            message:
              'Error in the Google Sign-in service! Please try again later.',
            timeout: -1
          })
          break
        case 'popup_closed_by_user':
          Sentry.addBreadcrumb({
            message: 'Popup closed by user',
            level: 'info'
          })
          // https://developers.google.com/identity/sign-in/web/reference#error_codes_2
          // Do nothing in this case as the user himself closed the login prompt dialog
          break
        case 'TransportError':
          Sentry.captureException(`${e}: Fail to validate credentials`, {
            tags: { source: 'auth', operation: 'after-authorization' }
          })
          this.updateNotificationPayload({
            code: 400,
            message:
              'Something went wrong when validating your credentials! Please try again later',
            timeout: -1
          })
          break
        case 'retired_user_sign_in':
          this.updateNotificationPayload({
            code: 400,
            message: 'Your access has been revoked by our company admin.',
            timeout: 5000
          })
          await this.$router.push({ name: 'Forbidden' })
          break
        case 'new_user_sign_in':
          this.$emit('new-user-sign-up')
          break
        default:
          // handle random errors here
          this.updateNotificationPayload({
            code: 400,
            message: 'Error signing in. Please try again later.',
            timeout: 5000
          })
          Sentry.captureException(e, {
            tags: { source: 'auth', operation: 'after-authorization' }
          })
          await this.$router.push({ name: 'Forbidden' })
          break
      }
    }
  }
}
</script>
