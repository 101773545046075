// Copyright (C) 2022-2024 Skylark Drones

import apiInstance from '@/config'
import store from '@/store'
import { isUserLoggedIn } from '@/utils/common'

export function postSite(data) {
  const companyId = store.getters.currentActiveOrganizationId
  return apiInstance.http.post(`/company/${companyId}/sites`, data)
}

export function patchSite(siteId, data, etag) {
  const companyId = store.getters.currentActiveOrganizationId
  return apiInstance.http.patch(`/company/${companyId}/sites/${siteId}`, data, {
    headers: { 'If-Match': etag }
  })
}

/**
 * Get sites list
 * @param {Object} options filter options for sites list
 * @param {Number} options.limit number of items to return
 * @param {'lt'|'gt'} options.direction direction to fetch data from the cursor value
 * @param {String} options.value cursor value point to fetch data from
 * @param {String} [options.key='creation_time'] Site data attribute key name to use as a cursor. Default to 'creation_time'
 * @param {String} options.search_query query sites over names
 * @param {JSON} options.filters JSON object of valid filters
 * @param {'time'|'status'} options.group_by Attribute by which to group sites
 * @param {import('axios').AxiosRequestConfig} config Custom request configuration for handling cases such as cancelling previous requests
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export function getAllSites(options, config) {
  const companyId = store.getters.currentActiveOrganizationId

  if ('filters' in options) {
    options.filters = JSON.stringify(options.filters)
  }

  return apiInstance.http.get(`/company/${companyId}/sites`, {
    params: options,
    ...config
  })
}

export function getSite(siteId) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.get(`/company/${companyId}/sites/${siteId}`)
}

export function getSiteMissions(siteId) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.get(
    `/company/${companyId}/sites/${siteId}/missions`,
    {
      params: { use_new_model: true }
    }
  )
}

export function deleteSite(siteId) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.delete(`/company/${companyId}/sites/${siteId}`)
}

export function downloadFeatures(siteId, data) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.get(
    `/company/${companyId}/sites/${siteId}/features`,
    {
      params: data
    }
  )
}

export function getElevationsForHeatmap(siteId) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.get(
    `/company/${companyId}/sites/${siteId}/heatmapelevations`
  )
}

export function validateElevations(data) {
  const params = {}

  if (isUserLoggedIn()) {
    params['company_id'] = store.getters.currentActiveOrganizationId
  }

  return apiInstance.http.post(`/sites/verifyelevationgrid`, data, { params })
}

/**
 * Generate GCPs for a site
 *
 * @param {String} siteId Id of the site to generate GCPs for
 * @param {Number} spacing Spacing between GCPs
 * @param {Number} [corridorWidth] Width of the corridor site
 * @returns {import('axios').AxiosResponse}
 */
export function getAutoGeneratedGcp(siteId, spacing, corridorWidth) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.get(
    `/company/${companyId}/sites/${siteId}/generategcps`,
    { params: { spacing, corridor_width: corridorWidth } }
  )
}

/**
 * Generate verification task for a site
 *
 * @param {object} payload Body to be passed with in the api request
 * @param {number} payload.gsd GSD value
 * @param {number} payload.gimbal_pitch Gimbal pitch value
 * @param {0|1} payload.custom_sensor Is custom sensor used
 * @param {Object} payload.verification_flags
 * @param {0|1} payload.verification_flags.check_metadata is metadata check enabled
 * @param {0|1} payload.verification_flags.check_iso is iso check enabled
 * @param {0|1} payload.verification_flags.check_shutter_speed is shutter speed check enabled
 * @param {0|1} payload.verification_flags.check_gimbal_pitch is gimbal pitch check enabled
 * @param {0|1} payload.verification_flags.check_duplicate is duplicate check enabled
 * @param {0|1} payload.verification_flags.check_overlap is overlap check enabled
 * @param {0|1} payload.verification_flags.check_image_count is image count check enabled
 * @param {0|1} payload.verification_flags.check_image_size is image size check enabled
 * @param {0|1} payload.verification_flags.check_image_connectivity is image connectivity check enabled
 * @param {0|1} payload.verification_flags.check_gsd_stitchability is image stitchability check enabled
 * @param {0|1} payload.verification_flags.check_gsd is gsd check enabled
 * @returns {import('axios').AxiosPromise}
 */
export function postSiteVerification(siteId, payload) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.post(
    `/company/${companyId}/sites/${siteId}/verification`,
    payload
  )
}
