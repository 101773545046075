<!-- Copyright (C) 2022-2024 Skylark Drones -->
<template>
  <input
    ref="fileSelector"
    accept=".kml,.kmz"
    type="file"
    @input="onFileSelected"
  />
</template>

<script>
import { cloneDeep } from 'lodash'
import kinks from '@turf/kinks'

import { readKML, readKMZ } from '@/utils/common/KMLHelpers'

export default {
  name: 'UploadSiteMenuAction',

  data() {
    return {
      file: null
    }
  },

  methods: {
    selectFile() {
      this.$refs.fileSelector.click()
    },
    onFileSelected(uploadEvent) {
      this.file = uploadEvent.target.files[0]

      // The @input and @change signals are only emitted when the file selected
      // by the user is different from the previous file. So, if the user selects
      // an invalid file twice, it will not be processed the next time which can
      // lead to unexpected behavior e.g. error dialog is not shown again
      this.$refs.fileSelector.value = null

      const fileExtension = this.file.name.split('.').pop().toLowerCase()

      if (fileExtension === 'kml') {
        readKML(this.file, this.processKMLFeatures)
      } else {
        readKMZ(this.file, this.processKMLFeatures)
      }
    },
    processKMLFeatures(features) {
      const filteredFeatures = []

      features.forEach(feature => {
        // Filter out invalid polygon features that are self-intersecting
        if (
          feature.geometry.type === 'Polygon' &&
          !this.validatePolygonGeometry(feature.geometry)
        )
          return

        // Iterate through GeometryCollection and extract valid features
        if (feature.geometry.type === 'GeometryCollection') {
          const properties = cloneDeep(feature.properties)
          const multiGeometryName = feature.properties.name
          feature.geometry.geometries.forEach((partGeom, partGeomIndex) => {
            if (
              partGeom.type === 'Polygon' &&
              !this.validatePolygonGeometry(partGeom)
            )
              return

            const childFeature = {
              type: 'Feature',
              geometry: partGeom,
              properties: {
                ...properties,
                ...{ name: `${multiGeometryName} ${partGeomIndex + 1}` }
              }
            }

            childFeature.geometry = this.convert3DTo2D(childFeature.geometry)
            filteredFeatures.push(childFeature)
          })
        } else {
          feature.geometry = this.convert3DTo2D(feature.geometry)
          filteredFeatures.push(feature)
        }
      })

      this.$emit('file-processed', {
        fileName: this.file.name,
        features: filteredFeatures
      })
    },
    convert3DTo2D(geometry) {
      const coordinates =
        geometry.type === 'Polygon'
          ? geometry.coordinates[0]
          : geometry.type === 'LineString'
          ? geometry.coordinates
          : [geometry.coordinates]
      coordinates.forEach(point => {
        if (point.length > 2) point.pop()
      })
      return geometry
    },
    validatePolygonGeometry(geometry) {
      return kinks(geometry).features.length === 0
    }
  }
}
</script>
