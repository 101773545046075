// Copyright (C) 2024 Skylark Drones

import { UnitConverter } from './UnitConverter'
import store from '@/store'

class AreaUnitConverter extends UnitConverter {
  storeState = store.state.generic

  static Units = {
    hectares: 'hectares',
    acres: 'acres',
    sqkm: 'sqkm',
    sqm: 'sqm'
  }

  mapResponse = {
    hectares: { shortUnit: 'ha', longUnit: 'hectares' },
    acres: { shortUnit: 'acres', longUnit: 'acres' },
    sqkm: { shortUnit: 'sq. km', longUnit: 'sq. km' },
    sqm: { shortUnit: 'sq. m', longUnit: 'sq. m' }
  }

  constructor() {
    super()

    this.toBaseUnit = {
      [AreaUnitConverter.Units.hectares]: value => value * 2.47105,
      [AreaUnitConverter.Units.sqkm]: value => value * 247.105,
      [AreaUnitConverter.Units.sqm]: value => value * 0.000247105,
      [AreaUnitConverter.Units.acres]: value => value * 1
    }
    this.toPrefferedUnit = {
      [AreaUnitConverter.Units.hectares]: value => value * 0.404686,
      [AreaUnitConverter.Units.sqkm]: value => value * 0.00404686,
      [AreaUnitConverter.Units.sqm]: value => value * 4046.86,
      [AreaUnitConverter.Units.acres]: value => value * 1
    }
  }

  /**
   * Converts a value from default unit of area to user's preffered unit of area
   * @param {number} value The numerical value to be converted .
   * @returns {Object} The object posess 3 keys, 'value': value in preferred unit, 'shortUnit': preffered unit in short form, 'longUnit': preffered unit in long form .
   */
  convert(
    value,
    baseUnit = AreaUnitConverter.Units.acres,
    outputUnit = this.storeState.user?.unit_preferences?.area
  ) {
    const preferredAreaUnit = outputUnit ?? baseUnit
    return {
      value: this.toPrefferedUnit[preferredAreaUnit](value),
      shortUnit: this.mapResponse[preferredAreaUnit].shortUnit,
      longUnit: this.mapResponse[preferredAreaUnit].longUnit
    }
  }

  /**
   * Converts a value from preffered unit of area to default unit of area
   * @param {number} value The numerical value to be converted .
   * @returns {number} The converted value in default unit .
   */
  convertToBaseUnit(value) {
    const preferredAreaUnit =
      this.storeState.user?.unit_preferences?.area ?? 'acres'
    const exponentValue = 10 ** UnitConverter.PRECISION
    return (
      Math.round(this.toBaseUnit[preferredAreaUnit](value) * exponentValue) /
      exponentValue
    )
  }
}

export default new AreaUnitConverter()
