// Copyright (C) 2024 Skylark Drones

import colors from 'vuetify/lib/util/colors'

// whitelabel brands
export const dmoBrand = process.env.VUE_APP_BRAND_DMO_KEY
export const latitudeBrand = 'latitude'

export const domainBrandMap = {
  [process.env.VUE_APP_BRAND_DMO_DOMAIN]: dmoBrand,
  [process.env.VUE_APP_BRAND_LATITUDE_DOMAIN]: latitudeBrand
}

// brand domain-config mapping
export const whiteLabelBrandConfig = {
  [dmoBrand]: {
    appName: 'Drone Mission Ops',
    appNameAbbr: 'DMO',
    billingDetails: {
      name: 'Skylark Drones Pvt. Ltd.',
      address: 'UrbanVault, 1090C HSR Layout, Bengaluru Karnataka 560102'
    },
    supportEmail: 'dmo.support@skylarkdrones.com',
    locationCoordinate: [22, 78],
    defaultZoom: 5,
    favicon: '/static/skylark-icon.png',
    appLogo: '/static/skylark-icon.png',
    appLogoWhite: '/static/skylark-icon.svg',
    termsOfServiceLink: 'https://www.skylarkdrones.com/terms-of-service',
    privacyPolicyLink: 'https://www.skylarkdrones.com/privacy-policy',
    androidPilotAppLink:
      'https://play.google.com/store/apps/details?id=com.skylarkdrones.dronemissionops',
    androidM3EAppLink:
      'https://dmo-public-assets.s3.ap-south-1.amazonaws.com/dji/v5/stable/latest.apk',
    disabledFeatures: {},
    theme: {
      primary: '#F05B28',
      secondary: colors.orange.lighten4,
      accent: colors.orange.accent3,
      'primary-font-color': '#4a4a48',
      'secondary-font-color': '#666666'
    }
  },
  [latitudeBrand]: {
    appName: 'Latitude DMO',
    appNameAbbr: 'Latitude DMO',
    billingDetails: {
      name: 'Latitude Pvt Ltd',
      address: 'Latitude Guyana'
    },
    supportEmail: 'latitude.support@latitudedmo.com',
    locationCoordinate: [4, -58],
    defaultZoom: 7,
    favicon: '/static/latitude-favicon.webp',
    appLogo: '/static/latitude-logo.png',
    appLogoWhite: '/static/latitude-white-logo.png',
    termsOfServiceLink: 'https://latitudegeospatial.com/terms-of-service',
    privacyPolicyLink: 'https://latitudegeospatial.com/privacy-policy',
    androidPilotAppLink:
      'https://dmo-public-assets.s3.ap-south-1.amazonaws.com/dji/v4/stable/latest-latitude.apk',
    androidM3EAppLink:
      'https://dmo-public-assets.s3.ap-south-1.amazonaws.com/dji/v5/stable/latest-latitude.apk',
    disabledFeatures: {
      news: true
    },
    theme: {
      primary: '#1989FB',
      secondary: '#475467',
      accent: colors.blue.accent3,
      'primary-font-color': '#4a4a48',
      'secondary-font-color': '#666666'
    }
  }
}
