<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-menu offset-y bottom rounded="lg" min-width="200">
    <template v-slot:activator="{ on, attrs }">
      <filter-button
        v-bind="attrs"
        v-on="on"
        label="Created Between"
        icon="mdi-calendar-range"
      >
        <template v-slot:value>
          <div v-if="displayValue" class="subtitle-2 primary--text">
            {{ displayValue }}
          </div>
        </template>
      </filter-button>
    </template>
    <v-card>
      <v-list dense>
        <v-list-item
          v-for="option in menuOptions"
          :key="option.key"
          color="primary"
          :input-value="$props.value === option.key"
          @click="
            $emit('on-change', $props.value === option.key ? null : option.key)
          "
        >
          {{ option.label }}
        </v-list-item>
      </v-list>
      <template v-if="$props.value">
        <v-divider />
        <v-list-item
          @click="$emit('on-change', null)"
          class="mt-2 primary-font-color--text"
        >
          Clear Filter
        </v-list-item>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
import FilterButton from '@/components/sites/filters/FilterButton.vue'

export default {
  name: 'DateFilter',

  components: {
    FilterButton
  },

  props: {
    value: { type: String }
  },

  data() {
    return {
      menuOptions: [
        { label: 'Today', key: '-1d' },
        { label: 'Past Week', key: '-7d' },
        { label: 'Past Month', key: '-30d' }
      ]
    }
  },

  computed: {
    displayValue() {
      return (
        this.$props.value &&
        this.menuOptions.find(opt => opt.key === this.$props.value).label
      )
    }
  }
}
</script>
