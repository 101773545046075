// Copyright (C) 2022-2024 Skylark Drones

import apiInstance from '@/config'
import store from '@/store'

/**
 * Get Collections list
 * @param {Object} options filter options for sites list
 * @param {Number} options.limit number of items to return
 * @param {Number} options.compact exclude sites from list or not(1/0)
 * @param {'lt'|'gt'} options.direction direction to fetch data from the cursor value
 * @param {String} options.value cursor value point to fetch data from
 * @param {String} options.page page no. to fetch data of
 * @param {String} [options.key='creation_time'] Collection data attribute key name to use as a cursor. Default to 'creation_time'
 * @param {JSON} options.filters JSON object of valid filters
 * @param {import('axios').AxiosRequestConfig} config Custom request configuration for handling cases such as cancelling previous requests
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export function getAllCollections(options, config) {
  const companyId = store.getters.currentActiveOrganizationId

  if ('filters' in options) {
    options.filters = JSON.stringify(options.filters)
  }

  return apiInstance.http.get(`/company/${companyId}/collections`, {
    params: options,
    ...config
  })
}

export function getCollection(collectionId) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.get(
    `/company/${companyId}/collections/${collectionId}`
  )
}

export function postCollection(data) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.post(`/company/${companyId}/collections`, data)
}

export function patchCollection(collectionId, data, etag) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.patch(
    `/company/${companyId}/collections/${collectionId}`,
    data,
    {
      headers: { 'If-Match': etag }
    }
  )
}

export function deleteCollection(collectionId) {
  const companyId = store.getters.currentActiveOrganizationId

  return apiInstance.http.delete(
    `/company/${companyId}/collections/${collectionId}`
  )
}
