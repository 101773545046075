<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    rounded
    color="white"
    class="subtitle-1 text-none"
    style="color: var(--v-primary-font-color-base)"
  >
    <v-icon v-if="$props.icon" size="20" class="ml-n1">
      {{ $props.icon }}
    </v-icon>
    <slot v-else name="icon"></slot>
    <span class="ml-1 font-weight-medium" :class="{ 'mr-1': $slots.value }">
      {{ $props.label }}{{ $slots.value ? ':' : '' }}
    </span>
    <slot name="value"></slot>
    <v-icon size="20" class="ml-1 mr-n2">mdi-chevron-down</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'FilterButton',

  props: {
    label: { type: String, required: true },
    icon: { type: String }
  }
}
</script>
