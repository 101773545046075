// Copyright (C) 2018-2024 Skylark Drones

import Vuex from 'vuex'
import Vue from 'vue'
import generic from './modules/generic'
import auth from './modules/auth'
import brand from './modules/brand'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    generic,
    auth,
    brand,
    components: { namespaced: true }
  }
})
