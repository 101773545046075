<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <div>
    <home-nav-bar-btn
      class="mb-2 mb-md-0"
      btn-label="What's New"
      :to="{ name: 'Changelog' }"
    />
    <home-nav-bar-btn
      class="mb-2 mb-md-0"
      btn-label="Industry News"
      :to="{ name: 'NewsOverview' }"
    />
    <home-nav-bar-btn
      btn-label="Data Check"
      class="mr-5"
      @click="navigateToVerificationPage"
    />
    <v-divider v-if="$vuetify.breakpoint.smAndDown" class="my-4" />
    <v-btn
      rounded
      color="primary"
      depressed
      height="39"
      :href="androidM3EAppLink"
      :block="$vuetify.breakpoint.smAndDown"
    >
      Get DMO
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { source } from '@/utils/analytics/abstraction/eventEnums'
import HomeNavBarBtn from '@/components/homepage/HomeNavBarBtn.vue'

export default {
  name: 'HomeNavBarLinks',

  components: { HomeNavBarBtn },

  computed: {
    ...mapGetters('brand', ['androidM3EAppLink'])
  },

  methods: {
    navigateToVerificationPage() {
      this.$analytics.trackDataVerificationView(source.DATA_CHECK_CARD)
      this.$router.push({ name: 'DroneDataVerification' })
    }
  }
}
</script>
