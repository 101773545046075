// Copyright (C) 2023-2024 Skylark Drones

import BaseApi from './baseHttpApi'

export default class v4Api extends BaseApi {
  whitelistURLs = [
    'get:/assets/sensor',
    'get:/assets/drone',
    'get:/assets/battery',
    'get:/news',
    'patch:/news/views',
    'post:/data_verification',
    'get:/data_verification',
    'patch:/data_verification/trial',
    'patch:/data_verification/result',
    'get:/plans/general',
    'get:/plans/free_fcl'
  ]

  constructor() {
    super('api/v4')
  }
}
