// Copyright (C) 2023-2024 Skylark Drones

export default {
  data() {
    return {
      permissionOptionsList: {
        view: {
          value: 'view',
          label: 'Can View',
          sentenceLabel: 'View Access',
          level: 1
        },
        edit: {
          value: 'edit',
          label: 'Can Edit',
          sentenceLabel: 'Edit Access',
          level: 2
        },
        full_access: {
          value: 'full_access',
          label: 'Full Access',
          sentenceLabel: 'Full Access',
          level: 3
        },
        reset_access: {
          value: 'none',
          label: 'Reset Access',
          sentenceLabel: 'Reset Access',
          level: 0
        },
        none: {
          value: 'none',
          label: 'No Access',
          sentenceLabel: 'No Access',
          level: 0
        },
        remove_access: {
          value: 'none',
          label: 'Remove Access',
          sentenceLabel: 'Remove Access',
          level: 0
        }
      }
    }
  },

  methods: {
    canEdit(accessLevel) {
      return (
        this.permissionOptionsList[accessLevel].level >
        this.permissionOptionsList.view.level
      )
    },
    canDelete(accessLevel) {
      return (
        this.permissionOptionsList[accessLevel].level ===
        this.permissionOptionsList.full_access.level
      )
    },
    getPermissionDescription(permissionKey) {
      switch (permissionKey) {
        case this.permissionOptionsList.view.value:
          return 'Can only view and export'

        case this.permissionOptionsList.edit.value:
          return 'Can edit, fly and share'

        case this.permissionOptionsList.full_access.value:
          return 'Can edit, fly, share and delete'
      }
    }
  }
}
