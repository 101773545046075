<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card
    class="d-flex flex-column rounded-lg pt-4 fill-height overflow-hidden"
  >
    <!-- header -->
    <v-row no-gutters justify="center" class="flex-grow-0">
      <v-col cols="auto" class="text-center">
        <h6 class="text-h6" style="color: var(--v-primary-font-color-base)">
          70+ Supported Drones
        </h6>
        <v-text-field
          dense
          hide-details
          filled
          clearable
          rounded
          :value="searchQuery"
          placeholder="Search your drone..."
          class="mt-2 mb-1"
          style="width: 230px"
          prepend-inner-icon="mdi-magnify"
          @input="onSearchDrones"
        />
      </v-col>
    </v-row>

    <!-- drones loading skeleton -->
    <div v-if="isFetchingDronesList" class="d-flex flex-nowrap">
      <div v-for="i in 10" :key="i + 'loading'" class="d-inline-block mr-8">
        <v-skeleton-loader width="130" height="134" type="image" />
        <v-skeleton-loader width="80" type="text" class="mt-3 mx-auto" />
      </div>
    </div>
    <!-- drones list -->
    <div
      v-else
      class="flex-grow-1 pb-4 text-center"
      :class="{
        'overflow-auto': isSearching,
        'infinite-scroll-animation': !isSearching
      }"
      :style="{
        'animation-play-state': pauseInfiniteScroll ? 'paused' : null,
        'white-space': isSearching ? 'nowrap' : null
      }"
      @click="onScrollContainerClick"
    >
      <template v-for="i in isSearching ? 1 : 2">
        <div
          v-for="(drone, ii) in dronesList"
          :key="drone.id + i"
          class="drone-item"
          :class="{
            'ml-8': ii !== 0 || (i === 2 && ii === 0)
          }"
          @click="onDroneCardClick(drone)"
        >
          <v-img
            width="130"
            height="130"
            contain
            eager
            class="drone-item__image"
            :src="drone.picture_url"
          />
          <div class="text-caption text-center mt-2">
            {{ drone.model_name }}
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="dronesList.length === 0"
      class="d-flex justify-center align-center text-center ma-2"
      style="height: 150px"
    >
      No drone Found with this name.
    </div>
    <drone-details-dialog
      v-if="activeDrone"
      :drone="activeDrone"
      @on-close="activeDrone = null"
    />
  </v-card>
</template>

<script>
import { debounce } from 'lodash'

import { page } from '@/utils/analytics/abstraction/eventEnums'
import SupportedDroneMixin from './SupportedDroneMixin'
import DroneDetailsDialog from './DroneDetailsDialog.vue'

export default {
  name: 'SupportedDronesCard',

  components: {
    DroneDetailsDialog
  },

  data() {
    return {
      pauseInfiniteScroll: false,
      activeDrone: null,
      searchQuery: ''
    }
  },

  mixins: [SupportedDroneMixin],

  mounted() {
    if (this.supportedDronesList.length === 0) {
      this.fetchDrones()
    }
  },

  computed: {
    isSearching() {
      return !!this.searchQuery
    },
    dronesList() {
      const dronesListArray = Object.values(this.supportedDronesList)

      if (!this.isSearching) {
        return dronesListArray
      }

      const filteredList = dronesListArray.filter(val => {
        return val.model_name
          .trim()
          .toLowerCase()
          .includes(this.searchQuery.trim().toLowerCase())
      })

      return filteredList
    }
  },

  methods: {
    onScrollContainerClick() {
      this.pauseInfiniteScroll = true
      setTimeout(() => {
        this.pauseInfiniteScroll = false
      }, 3000)
    },
    onDroneCardClick(drone) {
      this.activeDrone = drone
      this.$analytics.trackSupportedDroneDetailView(
        drone.model_name,
        page.HomePage
      )
    },
    onSearchDrones: debounce(function (event) {
      this.searchQuery = event
    }, 300)
  }
}
</script>

<style scoped>
.infinite-scroll-animation {
  width: max-content;
  animation: infinite-scroll 240s linear infinite;
}

.infinite-scroll-animation:hover {
  animation-play-state: paused;
}

.drone-item {
  cursor: pointer;
  display: inline-block;
}

.drone-item .drone-item__image {
  transition: all ease-in-out 100ms;
  transform-origin: center;
  transform: scale(1);
}

.drone-item:hover .drone-item__image {
  transform: scale(1.1);
}

@keyframes infinite-scroll {
  to {
    transform: translate(-50%);
  }
}
</style>
