//  Copyright (C) 2023-2024 Skylark Drones

import { mapGetters, mapMutations } from 'vuex'

import PermissionMixin from '@/components/siteshare/PermissionMixin.js'
import { getCompany } from '@/services/CompanyService'

export default {
  mixins: [PermissionMixin],

  data() {
    return {
      MEMBER_ROLES: {
        member: {
          id: 1,
          key: 'member',
          label: 'Member'
        },
        admin: {
          id: 2,
          key: 'admin',
          label: 'Admin'
        },
        owner: {
          id: 3,
          key: 'owner',
          label: 'Owner'
        }
      }
    }
  },

  computed: {
    ...mapGetters(['currentActiveOrganizationId', 'activeOrganizationDetails']),
    rolesOptionsList() {
      return Object.values(this.MEMBER_ROLES)
    }
  },

  methods: {
    ...mapMutations(['setActiveOrganizationDetails']),
    async fetchLatestCompany() {
      try {
        const resp = await getCompany(this.currentActiveOrganizationId)
        this.setActiveOrganizationDetails(resp.data)
      } catch (err) {
        console.error(err)
        return err
      }
    },
    getRoleDescription(roleKey) {
      const defaultOrgMemberAccessLevel =
        this.permissionOptionsList[
          this.activeOrganizationDetails.default_member_access_level
        ].sentenceLabel

      switch (roleKey) {
        case this.MEMBER_ROLES.member.key:
          return (
            'Can create, edit and fly sites. Has ' +
            `<b>${defaultOrgMemberAccessLevel}</b> ` +
            'to sites created by other members.'
          )

        case this.MEMBER_ROLES.admin.key:
          return (
            'Manages <strong>day-to-day</strong> tasks such as members ' +
            'and sites. Unrestricted access to all members and sites.'
          )

        case this.MEMBER_ROLES.owner.key:
        default:
          return (
            'Full control of the organization including its branding, ' +
            'members, and sites.'
          )
      }
    }
  }
}
