<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-row class="flex-nowrap overflow-x-auto">
    <v-col
      :cols="groupByType === 'status' ? 4 : 3"
      v-for="group in groupedSites"
      style="min-width: 450px"
      v-bind:key="group.heading"
    >
      <site-column-section
        :heading="group.heading"
        :sites="group.sites"
        :group-key="group.groupKey"
        :show-stakeholders="showStakeholders"
        :collection-created-function="$props.collectionCreatedFunction"
        :delete-site-function="$props.deleteSiteFunction"
        :site-added-to-collection-function="
          $props.siteAddedToCollectionFunction
        "
        :is-more-sites="group.moreItems"
        :load-more-sites="$props.loadMoreSites"
        :limit="$props.limit"
      >
        <template v-slot:no-items>{{ group.emptyMessage }}</template>
      </site-column-section>
    </v-col>
  </v-row>
</template>

<script>
import GroupByMixin from './GroupByMixin'
import SiteColumnSection from './SiteColumnViewSection.vue'

export default {
  name: 'SiteGroupByColumnView',

  components: {
    'site-column-section': SiteColumnSection
  },

  mixins: [GroupByMixin]
}
</script>
