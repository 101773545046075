// Copyright (C) 2023-2024 Skylark Drones

// Google
export const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID
export const GEOCODING_API_KEY = process.env.VUE_APP_GEOCODING_API_KEY

// Mapbox
export const MAPBOX_API_KEY = process.env.VUE_APP_MAPBOX_API_KEY

// mixpanel
export const MIXPANEL_PROJECT_TOKEN = process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN
export const MIXPANEL_DEBUG = process.env.VUE_APP_MIXPANEL_DEBUG === 'true'

// hotjar
export const HOTJAR_APP_ID = process.env.VUE_APP_HOTJAR_APP_ID
export const HOTJAR_ENABLED = process.env.VUE_APP_HOTJAR_ENABLED === 'true'

// zoom
export const ZOOM_JWT_TOKEN_EXPIRATION_SECONDS = parseInt(
  process.env.VUE_APP_ZOOM_JWT_TOKEN_EXPIRATION_SECONDS
)
