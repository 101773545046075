// Copyright (C) 2024 Skylark Drones

export default {
  appName: state => state.appName,
  appNameAbbr: state => state.appNameAbbr,
  appLogo: state => state.appLogo,
  appLogoWhite: state => state.appLogoWhite,
  androidPilotAppLink: state => state.androidPilotAppLink,
  androidM3EAppLink: state => state.androidM3EAppLink,
  disabledFeatures: state => state.disabledFeatures,
  termsOfServiceLink: state => state.termsOfServiceLink,
  privacyPolicyLink: state => state.privacyPolicyLink,
  locationCoordinate: state => state.locationCoordinate,
  defaultZoom: state => state.defaultZoom,
  favicon: state => state.favicon,
  billingDetails: state => state.billingDetails,
  supportEmail: state => state.supportEmail
}
