// Copyright (C) 2023-2024 Skylark Drones

export const apiUrl = {
  Sites: 'company/<company_id>/sites',
  Drones: 'company/<company_id>/drones',
  Drone: 'company/<company_id>/drones/<id>',
  ExportDronesAsCsv: 'company/<company_id>/drones/exportcsv',
  Batteries: 'company/<company_id>/batteries',
  ExportBatteriesAsCsv: 'company/<company_id>/batteries/exportcsv',
  PublishNews: 'news/publish',
  News: 'news/<id>',
  Tips: 'tips',
  CompaniesUsage: 'usage/companies',
  GuestRevoke: 'company/<company_id>/guests/<guest_id>/revoke',
  Organization: 'company/<company_id>',
  AddMember: 'company/<company_id>/invites',
  Streams: 'company/<company_id>/streams'
}

export const apiMethod = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}
