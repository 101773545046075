<!-- Copyright (C) 2022-2024 Skylark Drones -->
<template>
  <v-card v-bind="$attrs" class="rounded-lg" hover>
    <v-img :src="siteThumbnail" style="width: 100%" aspect-ratio="2" />
    <sample-data-chip
      v-if="site.sample_data"
      class="ma-2"
      style="position: absolute; right: 0; top: 0; z-index: 2"
      tooltip-text="Sample site for your reference"
    />
    <v-tooltip v-if="site.external" top>
      <template v-slot:activator="{ on }">
        <v-icon
          size="32"
          v-on="on"
          color="primary-font-color"
          class="ma-2"
          style="position: absolute; right: 0; top: 0"
        >
          mdi-account-multiple
        </v-icon>
      </template>
      <span>Shared by external user</span>
    </v-tooltip>
    <v-row align="center" class="py-2 pl-2 py-sm-4 pl-sm-4" no-gutters>
      <!--
      Unfortunately the cols needs to be hardcoded to avoid the text bleeding
      out of the site card. text-truncate requires a fixed bound. This won't
      be an issue as a site card always occupies 2 cols itself which ensures
      that the site card width won't be very different in other form factors.
      -->
      <v-col cols="10">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              data-hj-suppress
              class="text-truncate text-body-1 font-weight-medium primary-font-color--text"
              style="width: 100%"
              v-bind="attrs"
              v-on="on"
            >
              {{ $props.site.name }}
            </div>
          </template>
          <span>{{ $props.site.name }}</span>
        </v-tooltip>
        <v-row
          align="center"
          justify="start"
          no-gutters
          class="mt-1 mt-sm-2"
          :class="
            !$props.site.goal && !stakeholders.length
              ? 'mt-2 mt-sm-3 mb-1 mb-sm-1'
              : !stakeholders.length
              ? 'mt-2 mt-sm-3'
              : ''
          "
        >
          <v-col
            v-if="stakeholders.length && isProgressSyncEnabled"
            cols="auto"
            class="mr-4 mr-sm-4"
          >
            <v-row no-gutters>
              <template v-for="(stakeholder, index) in stakeholders">
                <v-tooltip :key="stakeholder.id" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      size="32"
                      :color="
                        index ? 'var(--v-primary-font-color-base)' : '#A98EF5'
                      "
                      class="white--text text-body-2"
                      :class="index ? 'ml-n3' : ''"
                      data-hj-suppress
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ computeInitials(stakeholder.name) }}
                    </v-avatar>
                  </template>
                  <span data-hj-suppress>
                    {{
                      computeInitials(stakeholder.name) !== '-'
                        ? stakeholder.name
                        : 'No Name Set'
                    }}
                  </span>
                </v-tooltip>
              </template>
            </v-row>
          </v-col>
          <v-col v-if="$props.site.goal && isProgressSyncEnabled">
            <v-progress-linear
              background-color="#D9D9D9"
              color="#4CAF50"
              height="4"
              rounded
              :value="siteProgressPercentage"
            />
            <div
              class="text-truncate text-body-2 mt-1 secondary-font-color--text"
            >
              {{ remainingFlyingTime }}
            </div>
          </v-col>
          <v-col v-else>
            <div
              class="text-body-1 secondary-font-color--text"
              data-hj-suppress
            >
              {{ siteSubtitle }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="showMenu" bottom close-on-content-click offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              width="100%"
              height="48"
              block
              text
              v-on="on"
              @click.prevent=""
            >
              <v-icon size="32" color="var(--v-secondary-font-color-base)">
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 ma-0 rounded-lg">
            <v-list-item
              v-if="showRemoveFromCollectionBtn"
              @click="$emit('remove-site-from-collection')"
            >
              <v-list-item-icon class="ml-n1 mr-4">
                <v-icon>mdi-file-remove</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Remove from Collection</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!showRemoveFromCollectionBtn"
              @click="showAddSiteToCollectionDialog = true"
            >
              <v-list-item-icon class="ml-n1 mr-4">
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Add to Collection</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item @click="showSiteShareDialog = true">
              <v-list-item-icon class="ml-n1 mr-4">
                <v-icon>mdi-share-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Share Site</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
              @click="
                downloadSite(
                  $props.site.id,
                  $props.site.name,
                  SOURCE.SITE_CARD_MENU
                )
              "
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-file-code</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Export Site as KML</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$props.site.goal > 0"
              @click="
                downloadSiteMissions(
                  $props.site.id,
                  $props.site.name,
                  SOURCE.SITE_CARD_MENU
                )
              "
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-file-code</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Export Missions as KML</v-list-item-title>
            </v-list-item>
            <v-menu
              v-if="areGCPsPresent"
              right
              open-on-hover
              close-on-content-click
              offset-x
              min-width="140"
            >
              <template v-slot:activator="{ on }">
                <v-list-item
                  v-on="on"
                  @click.prevent=""
                  style="cursor: default"
                >
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-file-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Export GCPs as</v-list-item-title>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>

              <v-list class="pa-0 ma-0 rounded-lg">
                <v-list-item @click="downloadGCPsHelper('CSV')">
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-file-delimited</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>CSV</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadGCPsHelper('KML')">
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-file-code</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>KML</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item
              v-if="shouldShowExportElevationsOption"
              @click="downloadSiteElevations($props.site.id, $props.site.name)"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-file-delimited</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Export Elevations as CSV</v-list-item-title>
            </v-list-item>
            <v-divider v-if="canDelete($props.site.access_level)" />
            <v-list-item
              v-if="canDelete($props.site.access_level)"
              @click="showSiteDeleteConfirmationDialog = true"
            >
              <v-list-item-icon class="ml-n1 mr-4">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Delete Site</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <site-delete-confirmation-dialog
      v-if="showSiteDeleteConfirmationDialog"
      :site="$props.site"
      :source-page="PAGE.SitesOverviewPage"
      @close-dialog="showSiteDeleteConfirmationDialog = false"
      @site-deleted="$emit('site-deleted', $props.site.id)"
    />
    <add-site-to-collection-dialog
      v-if="showAddSiteToCollectionDialog"
      :site-name="$props.site.name"
      :site-id="$props.site.id"
      @close-dialog="showAddSiteToCollectionDialog = false"
      @collection-created="onCollectionCreated"
      @site-added-to-collection="onSiteAddedToCollection"
    />
    <site-share-dialog
      :site-id="$props.site.id"
      :site-name="$props.site.name"
      :action-source="SOURCE.SITE_CARD_MENU"
      v-if="showSiteShareDialog"
      @close-dialog="showSiteShareDialog = false"
      @collaborators-update="updateCollaboratorsCount"
    />
  </v-card>
</template>

<script>
import SiteMixin from './SiteMixin'
import PermissionMixin from '@/components/siteshare/PermissionMixin'
import SampleDataChip from '@/components/common/SampleDataChip.vue'

export default {
  name: 'SiteCard',
  components: {
    SampleDataChip
  },

  props: {
    showRemoveFromCollectionBtn: {
      type: Boolean,
      default: false
    }
  },

  mixins: [SiteMixin, PermissionMixin]
}
</script>
