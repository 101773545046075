var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"d-flex flex-column rounded-lg pt-4 fill-height overflow-hidden"},[_c('v-row',{staticClass:"flex-grow-0",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('h6',{staticClass:"text-h6",staticStyle:{"color":"var(--v-primary-font-color-base)"}},[_vm._v(" 70+ Supported Drones ")]),_c('v-text-field',{staticClass:"mt-2 mb-1",staticStyle:{"width":"230px"},attrs:{"dense":"","hide-details":"","filled":"","clearable":"","rounded":"","value":_vm.searchQuery,"placeholder":"Search your drone...","prepend-inner-icon":"mdi-magnify"},on:{"input":_vm.onSearchDrones}})],1)],1),(_vm.isFetchingDronesList)?_c('div',{staticClass:"d-flex flex-nowrap"},_vm._l((10),function(i){return _c('div',{key:i + 'loading',staticClass:"d-inline-block mr-8"},[_c('v-skeleton-loader',{attrs:{"width":"130","height":"134","type":"image"}}),_c('v-skeleton-loader',{staticClass:"mt-3 mx-auto",attrs:{"width":"80","type":"text"}})],1)}),0):_c('div',{staticClass:"flex-grow-1 pb-4 text-center",class:{
      'overflow-auto': _vm.isSearching,
      'infinite-scroll-animation': !_vm.isSearching
    },style:({
      'animation-play-state': _vm.pauseInfiniteScroll ? 'paused' : null,
      'white-space': _vm.isSearching ? 'nowrap' : null
    }),on:{"click":_vm.onScrollContainerClick}},[_vm._l((_vm.isSearching ? 1 : 2),function(i){return _vm._l((_vm.dronesList),function(drone,ii){return _c('div',{key:drone.id + i,staticClass:"drone-item",class:{
          'ml-8': ii !== 0 || (i === 2 && ii === 0)
        },on:{"click":function($event){return _vm.onDroneCardClick(drone)}}},[_c('v-img',{staticClass:"drone-item__image",attrs:{"width":"130","height":"130","contain":"","eager":"","src":drone.picture_url}}),_c('div',{staticClass:"text-caption text-center mt-2"},[_vm._v(" "+_vm._s(drone.model_name)+" ")])],1)})})],2),(_vm.dronesList.length === 0)?_c('div',{staticClass:"d-flex justify-center align-center text-center ma-2",staticStyle:{"height":"150px"}},[_vm._v(" No drone Found with this name. ")]):_vm._e(),(_vm.activeDrone)?_c('drone-details-dialog',{attrs:{"drone":_vm.activeDrone},on:{"on-close":function($event){_vm.activeDrone = null}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }