// Copyright (C) 2024 Skylark Drones
import { mapGetters, mapMutations } from 'vuex'

import SiteFileSelectorModule from '@/components/sites/SiteFileSelectorModule'
import { getSiteSize } from '@/components/sitedetails/map/SiteGeometryHelpers'

const InvalidSiteFileDialog = () =>
  import('@/components/sites/InvalidSiteFileDialog')

export default {
  components: {
    'site-file-selector-module': SiteFileSelectorModule,
    'invalid-site-file-dialog': InvalidSiteFileDialog
  },

  data() {
    return {
      showInvalidSiteFileDialog: false,
      errorTitle: null,
      errorMsg: null
    }
  },

  computed: {
    ...mapGetters(['currentActiveOrganizationId'])
  },

  methods: {
    ...mapMutations(['updateCurrentSiteByAttributes']),
    selectFile() {
      this.$analytics.trackSiteUpload()
      this.$refs.inputUpload.selectFile()
    },
    onInvalidFileSelected() {
      this.showInvalidSiteFileDialog = false
      this.selectFile()
    },
    async onFileProcessed(payload) {
      const features = payload.features

      if (!features.length) {
        this.errorTitle = 'Invalid KML Selected'
        this.errorMsg =
          'Selected KML file is invalid. Please select a KML file ' +
          'containing valid (polygon, line or point) feature.'
        this.showInvalidSiteFileDialog = true
        this.$analytics.trackSiteUploadInvalidWarningView()
        return
      }

      if (features.length > 1) {
        this.errorTitle = 'Multiple Features Detected'
        this.errorMsg =
          'Selected KML file has multiple features. Please select a KML file which ' +
          'contains only a single feature.'
        this.showInvalidSiteFileDialog = true
        this.$analytics.trackSiteUploadMultiFeatureWarningView()
        return
      }

      const geometry = features[0].geometry
      if (geometry.type !== 'Point') {
        geometry.properties = { size: getSiteSize(geometry) }
      }

      let siteName = payload.fileName.split('.')
      siteName.pop() // Remove file extension
      siteName = siteName.join('.') // Join filename to account for dots in file name

      const site = {
        name: siteName,
        geometry,
        isValid: true,
        isEditable: false
      }

      await this.$router.push({
        name: 'SiteDetailsPage',
        params: {
          companyId: this.currentActiveOrganizationId,
          siteId: 'new',
          workflow: 'upload',
          site
        }
      })
    }
  }
}
