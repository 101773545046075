// Copyright (C) 2022-2024 Skylark Drones

import { mapMutations } from 'vuex'

import { downloadMissionFeatures } from '@/services/MissionService'
import { downloadFeatures } from '@/services/SiteService'
import {
  ExportFormat,
  ExportDestination
} from '@/utils/analytics/abstraction/eventEnums'

export default {
  methods: {
    ...mapMutations(['updateNotificationPayload']),
    async downloadGCPs(siteId, siteName, format, source) {
      const fileName = `${siteName.replace(
        ' ',
        '-'
      )}-GCP.${format.toLowerCase()}`

      try {
        const resp = await downloadFeatures(siteId, {
          map_feature: 'GCP',
          output_format: format
        })
        this.downloadFile(resp.data, fileName)

        this.$analytics.trackGCPExport(format, source)
      } catch (error) {
        this.updateNotificationPayload({
          code: 400,
          message: `Unable to export GCPs as ${format}!`,
          timeout: 5000
        })
      }
    },
    async downloadSite(siteId, siteName, source) {
      const fileName = `${siteName.replace(' ', '-')}-Site.kml`
      try {
        const resp = await downloadFeatures(siteId, {
          map_feature: 'Site',
          output_format: 'KML'
        })
        this.downloadFile(resp.data, fileName)
        this.$analytics.trackSiteExport(ExportFormat.KML, source)
      } catch (error) {
        this.updateNotificationPayload({
          code: 400,
          message: `Unable to export Site as KML!`,
          timeout: 5000
        })
      }
    },
    async downloadSiteMissions(siteId, siteName, source) {
      const fileName = `${siteName.replace(' ', '-')}-Missions.kml`
      try {
        const resp = await downloadFeatures(siteId, {
          map_feature: 'Missions',
          output_format: 'KML'
        })
        this.downloadFile(resp.data, fileName)
        this.$analytics.trackMissionExport(ExportFormat.KML, source)
      } catch (error) {
        this.updateNotificationPayload({
          code: 400,
          message: `Unable to export Site Missions as KML!`,
          timeout: 5000
        })
      }
    },
    async downloadSiteElevations(siteId, siteName) {
      const fileName = `${siteName.replace(' ', '-')}-Elevations.csv`
      try {
        const resp = await downloadFeatures(siteId, {
          map_feature: 'Elevations',
          output_format: 'CSV'
        })
        this.downloadFile(resp.data, fileName)
      } catch (error) {
        this.updateNotificationPayload({
          code: 400,
          message: `Unable to export Site Elevations as CSV!`,
          timeout: 5000
        })
      }
    },
    async downloadLitchiMissionCSV(missionId, missionName, source) {
      const fileName = `${missionName.replace(' ', '-')}-Litchi.csv`
      try {
        const resp = await downloadMissionFeatures(missionId, {
          file_format: 'csv',
          destination: 'litchi'
        })
        this.downloadFile(resp.data, fileName)
        this.$analytics.trackMissionExport(
          ExportFormat.CSV,
          source,
          ExportDestination.LITCHI
        )
      } catch (error) {
        this.updateNotificationPayload({
          code: 400,
          message: 'Unable to export mission',
          timeout: 5000
        })
      }
    },
    async downloadMissionPlannerMissionWaypoints(
      missionId,
      missionName,
      source
    ) {
      const fileName = `${missionName.replace(
        ' ',
        '-'
      )}-Mission-Planner.waypoints`
      try {
        const resp = await downloadMissionFeatures(missionId, {
          file_format: 'waypoints',
          destination: 'mission_planner'
        })
        this.downloadFile(resp.data, fileName)
        this.$analytics.trackMissionExport(
          ExportFormat.WAYPOINTS,
          source,
          ExportDestination.MISSION_PLANNER
        )
      } catch (error) {
        this.updateNotificationPayload({
          code: 400,
          message: 'Unable to export mission',
          timeout: 5000
        })
      }
    },
    async downloadMissionKML(missionId, missionName, source) {
      const fileName = `${missionName.replace(' ', '-')}.kml`
      try {
        const resp = await downloadMissionFeatures(missionId, {
          file_format: 'kml',
          destination: 'google_earth'
        })
        this.downloadFile(resp.data, fileName)
        this.$analytics.trackMissionExport(
          ExportFormat.KML,
          source,
          ExportDestination.GOOGLE_EARTH
        )
      } catch (error) {
        this.updateNotificationPayload({
          code: 400,
          message: 'Unable to export mission',
          timeout: 5000
        })
      }
    },
    downloadFile(data, fileName) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    }
  }
}
