// Copyright (C) 2020-2024 Skylark Drones

import * as Sentry from '@sentry/vue'

import store from '@/store'
import { GOOGLE_CLIENT_ID } from '@/constants/service'
import { getRefreshedToken } from '@/services/AuthenticateService'
import apiInstance from '@/config'
import { getBrand } from '@/utils/common'

/**
 * Google Identity web authorization using code flow model
 * https://developers.google.com/identity/oauth2/web/guides/use-code-model
 */

/**
 * Function that initializes google OAuth2 code client
 * after gsi/client script is loaded
 * @returns {Promise<{requestCode(): void}>} reject Sets the promise as a failure
 */
export async function initializeGIS() {
  try {
    // eslint-disable-next-line no-undef
    if (google === undefined) {
      await new Promise(resolve => {
        window.onGoogleLibraryLoad = () => resolve()
      })
    }

    const redirectUri = new URL(`${apiInstance.apiUrl}/authenticate/code`)
    redirectUri.searchParams.append('brand', getBrand())

    // eslint-disable-next-line no-undef
    const client = google.accounts.oauth2.initCodeClient({
      client_id: GOOGLE_CLIENT_ID,
      scope: 'profile email',
      ux_mode: 'redirect',
      redirect_uri: redirectUri.toString()
    })

    store.commit('setTokenIssuer', 'Google')
    return client
  } catch (error) {
    // This console is present to help debug any new unknown errors
    // resulting in the Google sign-in failure.
    Sentry.captureException(error, {
      tags: { source: 'auth', operation: 'gis_initialization' }
    })
    if (error.error === 'idpiframe_initialization_failed') {
      throw new Error('cookie_disabled_by_browser')
    } else {
      throw new Error('google_auth_obj_initialize_error')
    }
  }
}

/**
 * Refresh the idToken by sending request to backend.
 * @returns {Promise<{ id_token: string, expires_at: string}>}
 */
export async function refreshIdToken() {
  const response = await getRefreshedToken(store.getters.idToken)

  store.commit('setIdToken', response.data.id_token)
  store.commit('setExpiresAt', response.data.expires_at)

  return response.data
}
