<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-dialog
    :value="true"
    persistent
    max-width="600"
    :fullscreen="$vuetify.breakpoint.xs"
    :content-class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-lg'"
  >
    <v-card>
      <div class="py-5">
        <div
          class="d-flex align-center justify-center text-center pt-0 pb-4 text-h4 font-weight-medium text-truncate"
          style="color: var(--v-primary-font-color-base)"
        >
          {{ $props.drone.model_name }}
          <v-btn icon :href="$props.drone.website" class="ml-2" target="_blank">
            <v-icon color="primary">mdi-open-in-new</v-icon>
          </v-btn>
        </div>
        <v-btn absolute right top icon @click="$emit('on-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-row no-gutters justify="center" class="mb-7">
          <v-hover v-slot="{ hover }">
            <v-col cols="12" class="image-container">
              <v-img
                width="100%"
                height="250"
                contain
                :src="$props.drone.picture_url"
                class="mx-auto image"
                :style="{
                  transition: 'transform 0.3s ease-in-out',
                  transform: hover ? 'translateX(-144px)' : 'none'
                }"
              />
              <v-row
                no-gutters
                class="overlay-content"
                :style="{ right: hover ? '5vw' : none }"
                align="center"
              >
                <div
                  :style="{
                    maxWidth: $vuetify.breakpoint.xs ? '350px' : null
                  }"
                  :class="{ 'mx-auto': $vuetify.breakpoint.xs }"
                >
                  <v-row
                    v-for="spec in specifications"
                    :key="spec.label"
                    class="flex-nowrap"
                    align="center"
                  >
                    <v-col cols="auto">
                      <v-icon small color="#d9d9d9">{{ spec.icon }}</v-icon>
                    </v-col>
                    <v-col class="pa-2">
                      <div
                        class="text-subtitle-2 font-weight-regular"
                        style="color: #d9d9d9"
                      >
                        {{ spec.label }}
                      </div>
                      <div
                        class="text-truncate text-subtitle-1 font-weight-medium"
                        style="color: #ffffff"
                      >
                        {{ spec.value }} {{ spec.unit }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
            </v-col>
          </v-hover>
        </v-row>
        <div class="mx-auto pl-12 pr-12">
          <h6
            class="text-h6 text-center mb-7 text-uppercase"
            style="color: var(--v-primary-font-color-base)"
          >
            SUPPORTED FEATURES BY {{ appNameAbbr }}
          </h6>

          <v-row no-gutters justify="center">
            <v-expansion-panels
              accordion
              style="z-index: unset; border-radius: 8px"
            >
              <v-expansion-panel
                v-for="capability in capabilities"
                :key="capability.label"
                :class="capability.value ? 'green lighten-4' : 'red lighten-4'"
              >
                <v-expansion-panel-header hide-actions>
                  <v-row>
                    <v-col
                      cols="10"
                      :class="
                        capability.value
                          ? 'green--text text--darken-4'
                          : 'red--text text--darken-4'
                      "
                    >
                      {{ capability.label }}
                      <v-icon
                        :color="
                          capability.value ? 'green darken-4' : 'red darken-4'
                        "
                        style="margin-left: auto"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </v-col>
                    <v-spacer />
                    <v-col>
                      <v-icon
                        :color="
                          capability.value ? 'green darken-4' : 'red darken-4'
                        "
                      >
                        {{
                          capability.value
                            ? 'mdi-check-circle-outline'
                            : 'mdi-close-circle-outline'
                        }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  style="color: var(--v-secondary-font-color-base)"
                  class="text-body-2"
                >
                  {{ capability.info }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </div>

        <div class="text-center mt-6">
          <v-btn
            color="primary"
            :disabled="!downloadLink"
            depressed
            rounded
            width="340"
            :href="downloadLink"
            target="_blank"
          >
            <v-icon v-if="downloadLink" left>mdi-download-outline</v-icon>
            {{
              downloadLink
                ? `Download ${appNameAbbr}`
                : `${appNameAbbr} Not Supported`
            }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

import CameraModelMixin from '@/components/sitedetails/CameraModelMixin'
import { roundToDecimal } from '@/utils/common/display.js'

export default {
  name: 'DroneDetailsDialog',

  props: {
    drone: { type: Object, required: true }
  },

  mixins: [CameraModelMixin],

  data() {
    return {
      isMissionExecutionSupported: false,
      isDataVerificationSupported: false
    }
  },

  computed: {
    ...mapGetters('brand', [
      'appNameAbbr',
      'androidPilotAppLink',
      'androidM3EAppLink'
    ]),
    ceilingHeightInfo() {
      return this.$heightConverter.convert(
        this.$props.drone.specs.ceiling_height,
        this.$heightConverter.UnitEnums.M
      )
    },
    specifications() {
      return [
        {
          icon: 'mdi-domain',
          label: 'Manufacturer',
          value: this.$props.drone.manufacturer
        },
        {
          icon: 'mdi-clock-outline',
          label: 'Max. Flight Time',
          value: Math.round(this.$props.drone.specs.endurance / 60),
          unit: 'minutes'
        },
        {
          icon: 'mdi-arrow-collapse-up',
          label: 'Ceiling Height',
          value: roundToDecimal(this.ceilingHeightInfo.value, 0),
          unit: this.ceilingHeightInfo.longUnit
        }
      ]
    },
    capabilities() {
      return [
        {
          label: 'Manual Flying',
          info: 'Connect to drone, view camera feed and fly manually',
          value: this.$props.drone.capabilities.free_flight === 2,
          supportedByHardware: this.$props.drone.capabilities.free_flight !== 0
        },
        {
          label: 'Health Management System',
          info: 'View detailed health about the drone sensors such as avionics, propulsion and more',
          value: this.$props.drone.capabilities.hms === 2,
          supportedByHardware: this.$props.drone.capabilities.hms !== 0
        },
        {
          label: 'Mission Planning',
          info: 'Plan mapping and video automated missions',
          value: true,
          supportedByHardware: true
        },
        {
          label: 'Mission Execution',
          info: 'Execute automated waypoint missions and monitor them',
          value: this.isMissionExecutionSupported,
          supportedByHardware: true
        },
        {
          label: 'Live Stream',
          info: 'Live stream the drone feed and engage in a 2-way communication with viewers',
          value: this.$props.drone.capabilities.livestream === 2,
          supportedByHardware: this.$props.drone.capabilities.livestream !== 0
        },
        {
          label: 'RTK',
          info: 'Real Time Kinematics',
          value: this.$props.drone.capabilities.ppk_rtk === 2,
          supportedByHardware: this.$props.drone.capabilities.ppk_rtk !== 0
        },
        {
          label: 'Data Verification',
          info: 'Verify drone images in 3 simple steps using the revolutionary data check feature',
          value: this.isDataVerificationSupported,
          supportedByHardware: true
        }
      ].filter(capability => capability.supportedByHardware)
    },
    downloadLink() {
      switch (this.$props.drone.download_src) {
        case 's3_dji_v5':
          return this.androidM3EAppLink
        case 'play_store':
          return this.androidPilotAppLink
        default:
          return null
      }
    }
  },

  async mounted() {
    if (this.cameraModels.length === 0) {
      await this.getSensorModels()
    }
    this.getDroneSupportedCameraModels(this.$props.drone.id)
  },

  methods: {
    getDroneSupportedCameraModels(droneId) {
      let isMissionExecutionSupported = false
      let isDataVerificationSupported = false

      this.cameraModels.forEach(model => {
        const isModelSupported = model.compatible_with.includes(droneId)

        if (isModelSupported) {
          Object.values(model.capabilities.mission_types).forEach(
            missionType => {
              isMissionExecutionSupported ||=
                missionType.mission_execution === 2
            }
          )

          if (
            model.make &&
            model.sensor_attributes &&
            !isEmpty(model.sensor_attributes)
          ) {
            isDataVerificationSupported ||= true
          }
        }
      })

      this.isMissionExecutionSupported = isMissionExecutionSupported
      this.isDataVerificationSupported = isDataVerificationSupported
    }
  }
}
</script>

<style>
.image-container {
  position: relative;
  overflow: hidden;
}
.image-container:hover {
  background: linear-gradient(
    to left,
    rgba(74, 74, 75, 1) 0%,
    rgba(0, 0, 0, 0.7) 30%,
    rgba(0, 0, 0, 0) 100%
  );
}
.overlay-content {
  position: absolute;
  margin-left: 16px;
  top: 0;
  right: -144px;
  width: 144px;
  height: 100%;
}
</style>
