<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <div
    v-scroll="onScroll"
    style="position: sticky; z-index: 10; top: 0"
    class="text-center"
  >
    <v-btn
      v-if="offsetTop > 250"
      color="primary"
      rounded
      :fab="$vuetify.breakpoint.mdAndDown"
      @click="toTop"
      class="text-overline mt-4"
    >
      <v-icon v-if="$vuetify.breakpoint.mdAndDown">mdi-chevron-up</v-icon>
      <template v-else>
        <v-icon left>mdi-format-vertical-align-top</v-icon>
        Scroll to top
      </template>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ScrollToTop',

  data() {
    return {
      offsetTop: 0
    }
  },

  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    onScroll(e) {
      this.offsetTop = e.target.documentElement.scrollTop
    }
  }
}
</script>
