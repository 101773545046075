<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-menu
    offset-y
    bottom
    rounded="lg"
    :close-on-content-click="false"
    min-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <filter-btn v-bind="attrs" v-on="on" label="Filters">
        <template v-slot:icon>
          <v-icon v-if="filterCount === 0" size="20" class="ml-n1">
            mdi-filter-outline
          </v-icon>
          <v-avatar
            v-else
            class="primary subtitle-2 ml-n2 mr-1 white--text"
            size="24"
          >
            {{ filterCount }}
          </v-avatar>
        </template>
      </filter-btn>
    </template>

    <v-row dense no-gutters class="pa-4 white">
      <v-col cols="auto" class="mr-3">
        <created-between-filter />
      </v-col>
      <v-col cols="auto">
        <flown-by-filter />
      </v-col>
    </v-row>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

import FilterButton from '@/components/sites/filters/FilterButton'
import CreatedBetweenFilter from './CreatedBetweenFilter.vue'
import FlownByFilter from './FlownByFilter.vue'

export default {
  name: 'FilterMenu',

  components: {
    'filter-btn': FilterButton,
    'created-between-filter': CreatedBetweenFilter,
    'flown-by-filter': FlownByFilter
  },

  computed: {
    ...mapGetters(['filters']),
    filterCount() {
      let count = 0

      if (this.filters.pilots !== null) {
        count++
      }

      if (this.filters.created_between !== null) {
        count++
      }

      return count
    }
  }
}
</script>
