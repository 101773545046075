// Copyright (C) 2024 Skylark Drones

export default {
  setConfig(state, payload) {
    state.appName = payload.appName
    state.appNameAbbr = payload.appNameAbbr
    state.appLogo = payload.appLogo
    state.appLogoWhite = payload.appLogoWhite
    state.androidPilotAppLink = payload.androidPilotAppLink
    state.androidM3EAppLink = payload.androidM3EAppLink
    state.disabledFeatures = payload.disabledFeatures
    state.termsOfServiceLink = payload.termsOfServiceLink
    state.privacyPolicyLink = payload.privacyPolicyLink
    state.locationCoordinate = payload.locationCoordinate
    state.defaultZoom = payload.defaultZoom
    state.favicon = payload.favicon
    state.billingDetails = payload.billingDetails
    state.supportEmail = payload.supportEmail
  }
}
