<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card ref="card" class="rounded-lg fill-height">
    <v-row no-gutters style="min-height: 228px" class="fill-height">
      <v-col>
        <v-row
          no-gutters
          align="center"
          justify="center"
          class="text-center fill-height px-6"
        >
          <v-col>
            <div class="text-h6 primary-font-color--text">Data Check</div>
            <div
              class="text-body-2 font-weight-medium mt-4 mx-8 primary-font-color--text"
            >
              Identify image issues before they cost you
            </div>
            <div class="text-caption text--secondary">
              Check your drone images for insufficient overlap, bad ISO and
              other failures.
            </div>
            <v-btn
              color="primary"
              rounded
              depressed
              class="my-4"
              @click="navigateToVerificationPage"
            >
              <v-icon left>mdi-check-outline</v-icon>
              Verify Images For Free
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="showFeatureSlides" cols="auto" class="my-10">
        <v-divider vertical />
      </v-col>
      <v-col v-if="showFeatureSlides">
        <data-verification-feature-carousel />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { source } from '@/utils/analytics/abstraction/eventEnums'
import DataVerificationFeatureCarousel from './DataVerificationFeatureCarousel.vue'

export default {
  name: 'DataVerificationCard',

  components: {
    DataVerificationFeatureCarousel
  },

  computed: {
    ...mapGetters('brand', ['appNameAbbr'])
  },

  data() {
    return {
      showFeatureSlides: true,
      resizeObserver: new ResizeObserver(this.onElemResize)
    }
  },

  mounted() {
    this.resizeObserver.observe(this.$refs.card.$el)
  },

  destroyed() {
    this.resizeObserver.disconnect()
  },

  methods: {
    onElemResize(entries) {
      requestAnimationFrame(() => {
        this.showFeatureSlides = entries[0].target.clientWidth >= 700
      })
    },
    navigateToVerificationPage() {
      this.$analytics.trackDataVerificationView(source.DATA_CHECK_CARD)
      this.$router.push({ name: 'DroneDataVerification' })
    }
  }
}
</script>
