// Copyright (C) 2024 Skylark Drones

import apiInstance from '@/config'

/**
 * Fetch free fcl
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export function getFreeFcl() {
  return apiInstance.http.get(`/plans/free_fcl`)
}
