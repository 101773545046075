<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-chip
          v-bind="$attrs"
          v-on="{ ...on, ...$listeners }"
          style="background-color: white !important"
          small
          outlined
          color="primary-font-color"
          class="text-overline"
        >
          Sample
        </v-chip>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'SampleDataChip',
  props: {
    tooltipText: {
      type: String
    }
  }
}
</script>
