// Copyright (C) 2023-2024 Skylark Drones
import contour from '@/assets/images/contour-template.png'
import {
  formatFlyingTime,
  computeNameInitials,
  formatPrecise
} from '@/utils/common/display'
import { page, source } from '@/utils/analytics/abstraction/eventEnums.js'
import ExportFeatureMixin from '../sitedetails/ExportFeatureMixin'

const SiteDeleteConfirmationDialog = () =>
  import('@/components/sitedetails/SiteDeleteConfirmationDialog')

const AddSiteToCollectionDialog = () =>
  import('@/components/collections/AddSiteToCollectionDialog')

const SiteShareDialog = () => import('@/components/siteshare/SiteShareDialog')

export default {
  components: {
    'add-site-to-collection-dialog': AddSiteToCollectionDialog,
    'site-delete-confirmation-dialog': SiteDeleteConfirmationDialog,
    'site-share-dialog': SiteShareDialog
  },

  mixins: [ExportFeatureMixin],

  props: {
    site: { type: Object, required: true }
  },

  data() {
    return {
      PAGE: page,
      SOURCE: source,
      contourTemplateImage: contour,
      showSiteDeleteConfirmationDialog: false,
      showAddSiteToCollectionDialog: false,
      showSiteShareDialog: false,
      showMenu: false
    }
  },

  computed: {
    isProgressSyncEnabled() {
      return this.$featureAccessControl.isEnabled('web_progress_sync')
    },
    stakeholders() {
      let _stakeholders = []
      if (this.site.collaborator.id) _stakeholders.push(this.site.collaborator)
      if (this.site.pilots) _stakeholders.push(...this.site.pilots)
      if (_stakeholders.length > 2) {
        const orgCount = _stakeholders.length
        _stakeholders = _stakeholders.slice(0, 1)
        _stakeholders.push({ name: `+ ${orgCount - 1}`, id: null })
      }
      return _stakeholders
    },
    siteSize() {
      let formattedSiteInfo = null
      if (this.site.site_type === 'Point') return null
      else if (this.site.site_type === 'Polygon') {
        formattedSiteInfo = this.$areaConverter.convert(this.site.size)
        return `${formatPrecise(formattedSiteInfo.value)} ${
          formattedSiteInfo.shortUnit
        }`
      } else
        formattedSiteInfo = this.$distanceConverter.convert(
          this.site.size,
          this.$distanceConverter.UnitEnums.KM
        )

      return `${formatPrecise(formattedSiteInfo.value)} ${
        formattedSiteInfo.shortUnit
      }`
    },
    remainingFlyingTime() {
      if (!this.site.goal) return null
      if (!this.site.remaining_time) return 'Complete'
      return `${formatFlyingTime(this.site.remaining_time)} left`
    },
    siteThumbnail() {
      return this.$props.site.thumbnail ? this.$props.site.thumbnail : contour
    },
    siteProgressPercentage() {
      return this.$props.site.goal
        ? (this.$props.site.progress / this.$props.site.goal) * 100
        : 0
    },
    siteSubtitle() {
      return this.$props.site.site_type === 'Point'
        ? this.$props.site.location
        : this.siteSize
    },
    shouldShowExportElevationsOption() {
      return (
        this.$props.site.elevation_source !== 'None' &&
        this.$props.site.site_type !== 'Point'
      )
    },
    areGCPsPresent() {
      return this.$props.site.gcp_count > 0
    }
  },

  methods: {
    computeInitials(name) {
      return computeNameInitials(name)
    },
    onCollectionCreated() {
      this.showAddSiteToCollectionDialog = false
      this.$emit('collection-created')
    },
    onSiteAddedToCollection(collectionEtag) {
      this.showAddSiteToCollectionDialog = false
      this.$emit('site-added-to-collection', collectionEtag)
    },
    downloadGCPsHelper(format) {
      this.showMenu = false
      this.downloadGCPs(
        this.$props.site.id,
        this.$props.site.name,
        format,
        source.SITE_CARD_MENU
      )
    },
    updateCollaboratorsCount(data) {
      this.$props.site.collaborators_count = data.collaborators.length
      this.$props.site.invites_count = data.invites.length
    }
  }
}
