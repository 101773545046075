// Copyright (C) 2018-2024 Skylark Drones

export default {
  setIdToken(state, payload) {
    localStorage.setItem('idToken', payload)
    state.id_token = payload
  },
  setFirstIssuedAt(state, payload) {
    state.first_issued_at = payload
  },
  setExpiresAt(state, payload) {
    state.expires_at = payload
  },
  userObject(state, payload) {
    state.userObject = payload
  },
  setTokenAuthorized(state, payload) {
    localStorage.setItem('tokenAuthorized', payload)
    state.tokenAuthorized = payload
  },
  setTokenIssuer(state, payload) {
    localStorage.setItem('tokenIssuer', payload)
    state.tokenIssuer = payload
  }
}
