// Copyright (C) 2023-2024 Skylark Drones
import dayjs from 'dayjs'
import { omit } from 'lodash'

import store from '@/store'

/**
 * @typedef {Object} StatePreferences preferences used as vuex state value
 * @property {Object} serverPreferences.filters Filters for sites and/or collection
 * @property {'status'|'time'} serverPreferences.group Site grouping type
 * @property {'grid'} serverPreferences.view Site view type
 *
 * @typedef {Record<String, StatePreferences>} StatePreferencesMap mapped user web preferences
 *
 * @typedef {Object} ServerPreferences web_preferences from the server
 * @property {Object} serverPreferences.filters Filters for sites and/or collection
 * @property {'status'|'time'} serverPreferences.group Site grouping type
 * @property {'grid'} serverPreferences.view Site view type
 */

/**
 * Coverts server web preferences object to vuex state object
 * @param {ServerPreferences} serverPreferences web_preferences from the server
 * @returns {StatePreferences}
 */
export function serverToStatePreferenceAdapter(serverPreferences) {
  Object.keys(serverPreferences.filters).forEach(filter => {
    const isValueAStringOrArray =
      serverPreferences.filters[filter] instanceof String ||
      serverPreferences.filters[filter] instanceof Array

    if (
      isValueAStringOrArray &&
      serverPreferences.filters[filter].length === 0
    ) {
      serverPreferences.filters[filter] = null
      return
    }

    if (
      filter === 'created_between' &&
      serverPreferences.filters[filter] &&
      serverPreferences.filters[filter].length === 1
    ) {
      serverPreferences.filters[filter] = serverPreferences.filters[filter][0]
    }
  })

  return {
    filters: serverPreferences.filters,
    group: serverPreferences.group !== 'none' ? serverPreferences.group : null,
    view: serverPreferences.view
  }
}

/**
 * Coverts server web preferences object to vuex state object
 * @param {StatePreferences} preferences
 * @returns {ServerPreferences} object accepted by server
 */
export function stateToServerPreferenceAdapter(preferences) {
  const appliedFilters = removeUnusedFilters(preferences.filters)

  if (typeof preferences.filters.created_between === 'string') {
    appliedFilters.created_between = [preferences.filters.created_between]
  }

  return {
    filters: {
      search_query: '',
      created_between: [],
      pilots: [],
      created_by: [],
      ...appliedFilters
    },
    group: preferences.group ? preferences.group : 'none',
    view: preferences.view
  }
}

/**
 * Remove filters with value null or empty array
 * @param {StatePreferences['filters']} filters Filters for sites and/or collection
 * @returns {StatePreferences['filters']} Only filters that are applied
 */
export function removeUnusedFilters(filters) {
  const cleanedFilters = {}

  Object.entries(filters).forEach(([key, val]) => {
    if (val) {
      if (['created_by', 'pilots'].includes(key)) {
        val = val.map(user => user.id)
      } else if (key === 'created_between') {
        if (typeof val === 'string') {
          let rangeStart
          const rangeEnd = dayjs().toISOString()
          switch (val) {
            case '-1d':
              rangeStart = dayjs().subtract(1, 'day').toISOString()
              break
            case '-7d':
              rangeStart = dayjs().subtract(7, 'days').toISOString()
              break
            case '-30d':
              rangeStart = dayjs().subtract(30, 'days').toISOString()
              break
            case '-90d':
              rangeStart = dayjs().subtract(90, 'days').toISOString()
              break
          }

          val = [rangeStart, rangeEnd]
        }
      }

      cleanedFilters[key] = val
    }
  })

  return cleanedFilters
}

/**
 *  Updates user web_preferences both in state and in localStorage
 * @param {StatePreferencesMap} preferences preferences formatted as from server
 */
export function cachePreference(preferences) {
  store.commit('updateUserAttribute', { web_preferences: preferences })
}

/**
 *  Covert list of company wise preferences into a map
 * @param {Array<ServerPreferences>} preferencesList List of company wise web preferences
 * @returns {StatePreferencesMap} Preferences mapped to company id
 */
export function preferencesListToMap(preferencesList) {
  return preferencesList.reduce((orgPreferenceMap, orgPreference) => {
    orgPreferenceMap[orgPreference.company] = omit(orgPreference, ['company'])
    return orgPreferenceMap
  }, {})
}

export function updatePreferenceToCurrentOrg() {
  const companyId = store.getters.currentActiveOrganizationId
  let preferences = store.getters.user.web_preferences[companyId]

  // Fallback to user's personal space preference when preference for
  // the current active company doesn't exist
  if (!preferences) {
    preferences =
      store.getters.user.web_preferences[store.getters.userPersonalSpace.id]
  }

  store.dispatch('setPreferences', serverToStatePreferenceAdapter(preferences))
}
