<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <v-sheet elevation="1" rounded="lg">
    <v-row
      no-gutters
      align="center"
      class="pa-4 px-md-6 fill-height rounded-lg"
    >
      <v-col cols="auto">
        <v-row align="center" no-gutters>
          <v-img :src="appLogo" height="24" width="24" contain class="mr-2" />
          <div class="text-h6 font-weight-bold primary-font-color--text">
            DMO
          </div>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <home-nav-bar-links v-if="!$vuetify.breakpoint.smAndDown" />
        <v-btn
          v-else
          x-large
          icon
          color="primary"
          @click="showMobileMenu = !showMobileMenu"
        >
          <v-icon>{{ showMobileMenu ? 'mdi-close' : 'mdi-menu' }}</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown && showMobileMenu">
        <home-nav-bar-links />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'

import HomeNavBarLinks from '@/components/homepage/HomeNavBarLinks.vue'

export default {
  name: 'HomeNavBar',

  components: { HomeNavBarLinks },

  data() {
    return {
      showMobileMenu: false
    }
  },

  computed: {
    ...mapGetters('brand', ['appName', 'appLogo'])
  }
}
</script>
