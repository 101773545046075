// Copyright (C) 2019-2024 Skylark Drones

import apiInstance from '@/config'

export function getUsers(requestParams = '') {
  return apiInstance.http.get(`/users?${requestParams}`)
}

export function getUser(id, data) {
  return apiInstance.http.get(`/users/${id}`, {
    params: data
  })
}

export function addUser(data) {
  return apiInstance.http.post('/users', data)
}

export function bulkAddUser(data) {
  return apiInstance.http.post('/users/importcsv', data)
}

export function getSignedUploadUrl(id, params) {
  return apiInstance.http.get(`/users/${id}/upload`, { params })
}

export function getSignedDownloadUrl(id, params) {
  return apiInstance.http.get(`/users/${id}/download`, { params })
}

export function deleteUserFile(id, params) {
  return apiInstance.http.delete(`/users/${id}/upload`, { params })
}

export function editUser(id, data) {
  return apiInstance.http.patch(`/users/${id}`, data)
}

export function editUserDetails(id, data) {
  return apiInstance.http.patch(`/users/${id}`, data)
}

export function saveUsersCSV(data) {
  return apiInstance.http.post('/users/exportcsv', data)
}

export function getPilotProjects(id, limit = 5, pageNo = 1) {
  return apiInstance.http.get(`/users/${id}/projects`, {
    params: {
      limit,
      page: pageNo
    }
  })
}

export function getUserInvites(options) {
  return apiInstance.http.get(`/users/invites`, { params: options })
}

/**
 * Delete user account
 *
 * @param {String} id Id of the user
 * @returns {import('axios').AxiosResponse} axios response
 */
export function deleteUser(id) {
  return apiInstance.http.delete(`/users/${id}`)
}
