<!-- Copyright (C) 2022-2024 Skylark Drones -->
<template>
  <v-container fluid class="pa-0">
    <scroll-to-top />
    <v-progress-linear
      indeterminate
      v-if="
        isFetchingFilteredSite ||
        isFetchingGroupedSite ||
        isFetchingFilteredCollection
      "
      absolute
    />
    <!-- Page toolbar -->
    <v-row class="mx-8 py-4" justify="space-between" no-gutters>
      <v-col cols="auto">
        <h5 class="text-h5 font-weight-medium primary-font-color--text">
          Sites & Collections
        </h5>
      </v-col>
      <v-col cols="auto">
        <sample-sites-dev-btn
          v-if="DEV_TOOLS"
          :loading="isDevButtonLoading"
          @click="setupSampleSites"
        />
        <create-site-btn
          @create-collection="showCreateCollectionDialog = true"
        />
      </v-col>
    </v-row>

    <common-filters v-if="!isFetchingUser" class="mx-8 ml-7 mt-n1" />
    <v-row v-else dense class="mx-8 ml-7">
      <v-skeleton-loader type="button" class="rounded-pill" />
      <v-skeleton-loader type="button" class="rounded-pill ml-2" />
    </v-row>

    <empty-site-and-collection-state
      class="mx-auto mt-16"
      v-if="
        collections.length === 0 &&
        areSitesEmpty &&
        !areCollectionsLoading &&
        !areSitesLoading
      "
    />

    <template v-else>
      <!-- Collections section -->
      <v-row no-gutters align="center" class="mt-6 mx-8">
        <div class="text-h6 primary-font-color--text">Collections</div>
        <v-spacer />
        <v-btn
          v-if="areMoreCollectionsPresent"
          color="primary-font-color"
          text
          rounded
          :to="{ name: 'CollectionsOverviewPage' }"
        >
          View All
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
      <collections-horizontal-list-view
        v-if="!areCollectionsLoading && collections.length"
        class="ml-5 mt-1"
        :collections="collections"
        @collection-deleted="removeCollection"
        @collection-updated="fetchAllCollections"
      />
      <horizontal-collection-list-skeleton-loader
        v-if="areCollectionsLoading"
        class="ml-5 mt-1"
      />
      <new-empty-state
        v-if="!areCollectionsLoading && !collections.length"
        class="mt-6 mb-12 ml-6"
        style="width: 450px"
        :image="emptyCollectionCard"
        title="No collections found"
        subtitle="Collection can be used to group sites. Think of collections as an
            album of photos"
        :vertical-layout="false"
      >
        <template v-slot:action>
          <v-btn
            width="196"
            class="primary mt-4"
            @click="showCreateCollectionDialog = true"
          >
            Create Collection
          </v-btn>
        </template>
      </new-empty-state>

      <!-- Sites section -->
      <v-row
        no-gutters
        align="center"
        justify="space-between"
        class="mx-8 mt-8 mb-4"
        style="height: 36px"
      >
        <v-col cols="auto">
          <div class="text-h6 primary-font-color--text">Sites</div>
        </v-col>
        <v-col cols="auto">
          <v-row v-if="!isFetchingUser" class="mr-1">
            <v-badge content="NEW" color="red" offset-x="30" offset-y="8">
              <site-group-by-menu />
            </v-badge>
            <view-toggle-option v-if="groupByType" class="ml-2" />
          </v-row>
          <v-skeleton-loader v-else type="button" class="rounded-pill" />
        </v-col>
      </v-row>

      <template
        v-if="!areSitesLoading && !areSitesEmpty && !isFetchingGroupedSite"
      >
        <template v-if="groupByType && !isSitesDateUngrouped">
          <site-group-by-grid-view
            v-if="viewMode === 'grid'"
            class="mx-2 mx-sm-8 mt-2"
            :sites="sites"
            :collection-created-function="fetchAllCollections"
            :site-added-to-collection-function="fetchAllCollections"
            :delete-site-function="removeSite"
            :limit="limit"
            :load-more-sites="
              (groupKey, cursor) =>
                fetchAllSites(cursor, 'paginating-group', groupKey)
            "
          />
          <site-group-by-column-view
            v-if="viewMode === 'column'"
            class="mx-2 mx-sm-5 mt-2 mb-4"
            :sites="sites"
            :collection-created-function="fetchAllCollections"
            :site-added-to-collection-function="fetchAllCollections"
            :delete-site-function="removeSite"
            :load-more-sites="
              (groupKey, cursor) =>
                fetchAllSites(cursor, 'paginating-group', groupKey)
            "
            :limit="limit"
          />
        </template>
        <site-ungrouped-grid-view
          v-if="isSitesDateUngrouped"
          class="mx-2 mx-sm-8 mt-2"
          :sites="sites"
          :collection-created-function="fetchAllCollections"
          :site-added-to-collection-function="fetchAllCollections"
          :delete-site-function="removeSite"
          :load-more-sites="() => fetchAllSites(latestCursor, 'paginating')"
          :is-paginating="isPaginating"
          :are-more-sites="areMoreItems"
        />
      </template>
      <site-grid-skeleton-loader v-if="areSitesLoading" class="mx-5 mt-3" />

      <new-empty-state
        v-if="!areSitesLoading && areSitesEmpty"
        class="mt-6 ml-6"
        style="width: 450px"
        :image="emptySiteCard"
        title="No sites found"
        subtitle="Create your first site to get started"
        :vertical-layout="false"
      >
        <template v-slot:action>
          <v-btn
            class="primary mt-4"
            width="150"
            @click="
              $router.push({
                name: 'SiteDetailsPage',
                params: { siteId: 'new', workflow: 'draw', site: null }
              })
            "
          >
            Draw Site
          </v-btn>
        </template>
      </new-empty-state>
    </template>

    <create-new-collection-dialog
      v-if="showCreateCollectionDialog"
      @close-dialog="showCreateCollectionDialog = false"
      @collection-created="onCollectionCreated"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { last, debounce, pick, isArray, omit } from 'lodash'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/vue'

import { DEV_TOOLS } from '@/constants/index.js'
import contour from '@/assets/images/contour-template.png'
import emptySiteCard from '@/assets/images/empty_site_card.svg'
import emptyCollectionCard from '@/assets/images/empty_collection_card.svg'
import { getAllSites } from '@/services/SiteService'
import { getAllCollections } from '@/services/CollectionService'
import { editUser } from '@/services/UserService'
import { createSampleSites } from '@/services/RegisterService'

import {
  stateToServerPreferenceAdapter,
  cachePreference,
  removeUnusedFilters,
  updatePreferenceToCurrentOrg
} from '@/utils/common/userPreferences.js'
import CreateSiteBtn from '@/components/sites/CreateSiteBtn'
import CollectionsHorizontalListView from '@/components/collections/CollectionsHorizontalListView'
import SiteGridSkeletonLoader from '@/components/sites/SiteGridSkeletonLoader'
import HorizontalCollectionListSkeletonLoader from '@/components/collections/HorizontalCollectionListSkeletonLoader'
import CommonFilters from './filters/CommonFilters.vue'
import NetworkMixin from '@/utils/common/NetworkMixin'
import GroupByMenu from './groups/GroupByMenu.vue'
import ViewToggleOption from './groups/ViewToggleOption.vue'
import SiteUnGroupedGridView from './SiteUnGroupedGridView.vue'
import SiteGroupByGridView from './groups/SiteGroupByGridView.vue'
import SiteGroupByColumnView from './groups/SiteGroupByColumnView.vue'
import ScrollToTop from '@/components/common/ScrollToTop'

const NewEmptyState = () => import('@/common/NewEmptyState')

const CreateNewCollectionDialog = () =>
  import('@/components/collections/CreateNewCollectionDialog')

const EmptySitesAndCollectionState = () =>
  import('./EmptySitesAndCollectionState.vue')

const SampleSitesDevBtn = () =>
  import('@/components/dev-tools/SampleSitesDevBtn')

export default {
  name: 'SitesOverviewPage',

  components: {
    'collections-horizontal-list-view': CollectionsHorizontalListView,
    'new-empty-state': NewEmptyState,
    'create-site-btn': CreateSiteBtn,
    'create-new-collection-dialog': CreateNewCollectionDialog,
    'site-grid-skeleton-loader': SiteGridSkeletonLoader,
    'horizontal-collection-list-skeleton-loader':
      HorizontalCollectionListSkeletonLoader,
    'common-filters': CommonFilters,
    'site-group-by-menu': GroupByMenu,
    'view-toggle-option': ViewToggleOption,
    'site-ungrouped-grid-view': SiteUnGroupedGridView,
    'site-group-by-grid-view': SiteGroupByGridView,
    'site-group-by-column-view': SiteGroupByColumnView,
    'empty-site-and-collection-state': EmptySitesAndCollectionState,
    ScrollToTop,
    SampleSitesDevBtn
  },

  mixins: [NetworkMixin],

  data() {
    return {
      emptySiteCard,
      emptyCollectionCard,
      areSitesLoading: true,
      areCollectionsLoading: true,
      contourTemplateImage: contour,
      sites: [],
      collections: [],
      limit: 20,
      areMoreItems: false,
      isPaginating: false,
      areMoreCollectionsPresent: false,
      showCreateCollectionDialog: false,
      selectedCollectionId: null,
      isFetchingFilteredSite: false,
      isFetchingGroupedSite: false,
      isFetchingFilteredCollection: false,
      canActOnFilterUpdates: true,
      DEV_TOOLS,
      isDevButtonLoading: false
    }
  },

  computed: {
    ...mapGetters([
      'filters',
      'groupByType',
      'viewMode',
      'user',
      'isFetchingUser',
      'currentActiveOrganizationId'
    ]),
    latestCursor() {
      return this.sites.length > 0
        ? last(this.sites).creation_time
        : new Date().toISOString()
    },
    areSitesEmpty: function () {
      return isArray(this.sites)
        ? this.sites.length === 0
        : // Checking each group length
          Object.values(omit(this.sites, ['etag'])).reduce(
            (previouslyComputedAreMoreSites, currentGroup) =>
              currentGroup.length === 0 && previouslyComputedAreMoreSites,
            true
          )
    },
    isSitesDateUngrouped() {
      return isArray(this.sites)
    }
  },

  watch: {
    // this was set in App.vue created hook and here it will
    // only trigger once when it changes from true to false
    // depicting preferences are fetched
    isFetchingUser: function () {
      this.fetchInitialPageData()
    },
    currentActiveOrganizationId: async function () {
      // Disabling filter watcher momentarily to prevent patching
      // user for already saved preference. Waiting for the next
      // tick to give time to the watcher to get trigger and read
      // the disable state
      this.canActOnFilterUpdates = false
      updatePreferenceToCurrentOrg()
      await this.$nextTick()
      this.canActOnFilterUpdates = true

      this.setUsersList(null)
      await this.fetchAllCollections()
      await this.fetchAllSites(new Date().toISOString())
    }
  },

  async created() {
    this.canActOnFilterUpdates = false
    updatePreferenceToCurrentOrg()
    await this.$nextTick()
    this.canActOnFilterUpdates = true
  },

  mounted() {
    this.$analytics.trackPageSiteOverView()
    this.fetchInitialPageData()
  },

  methods: {
    ...mapMutations(['updateNotificationPayload', 'setUsersList']),
    async fetchInitialPageData() {
      // API calls have to be synchronous due to technical debt. where if the
      // first request fails due to outdated access token, the subsequent requests
      // would also fail due to a limitation of the response interceptor.

      if (!this.isFetchingUser) {
        this.watchFilterGroupAndView()
        await this.fetchAllCollections()
        await this.fetchAllSites(this.latestCursor)
      }
    },
    openCollection(id) {
      this.selectedCollectionId = id
    },
    updateUserWebPreference: debounce(async function () {
      try {
        const preferences = stateToServerPreferenceAdapter({
          filters: this.filters,
          group: this.groupByType,
          view: this.viewMode
        })
        await editUser(this.user.id, {
          web_preferences: { [this.currentActiveOrganizationId]: preferences }
        })

        if (this.filters.created_by) {
          preferences.filters.created_by = this.filters.created_by
        }

        if (this.filters.pilots) {
          preferences.filters.pilots = this.filters.pilots
        }

        cachePreference({
          ...this.user.web_preferences,
          [this.currentActiveOrganizationId]: {
            ...this.user.web_preferences[this.currentActiveOrganizationId],
            ...preferences
          }
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 300),
    watchFilterGroupAndView() {
      const filterListener = isSearchFilter => async filterValue => {
        if (!this.canActOnFilterUpdates) return

        await this.fetchAllCollections()
        await this.fetchAllSites(new Date().toISOString(), 'filtering')
        this.updateUserWebPreference()

        if (isSearchFilter) {
          if (filterValue && filterValue.trim()) {
            this.$analytics.trackSitesAndCollectionsSearch(filterValue)
          }
        } else {
          this.$analytics.trackSitesAndCollectionsFilter({
            createdByFilterCount: this.filters.created_by
              ? this.filters.created_by.length
              : 0,
            flownByFilterCount: this.filters.pilots
              ? this.filters.pilots.length
              : 0,
            createdBetweenFilter: this.filters.created_between
          })
        }
      }

      this.$watch('filters.created_by', filterListener(false))
      this.$watch('filters.created_between', filterListener(false))
      this.$watch('filters.pilots', filterListener(false))
      this.$watch('filters.search_query', filterListener(true))

      this.$watch('groupByType', function (groupByType) {
        if (!this.canActOnFilterUpdates) return

        this.fetchAllSites(new Date().toISOString(), 'grouping')
        this.updateUserWebPreference()
        this.$analytics.trackSitesGroup(groupByType)
      })
      this.$watch('viewMode', function (viewMode) {
        if (!this.canActOnFilterUpdates) return

        this.updateUserWebPreference()
        this.$analytics.trackSitesViewModeUpdate(viewMode)
      })
    },
    removeSite(siteId, groupKey) {
      const sitesList = groupKey ? this.sites[groupKey] : this.sites

      const deleteIndex = sitesList.findIndex(site => site.id === siteId)
      if (deleteIndex !== -1) sitesList.splice(deleteIndex, 1)
      this.fetchAllCollections()
    },
    removeCollection(collectionId) {
      const deleteIndex = this.collections.findIndex(
        collection => collection.id === collectionId
      )
      if (deleteIndex !== -1) this.collections.splice(deleteIndex, 1)
    },
    /**
     *
     * @param {String} cursor Value/Point from where fetch sites from (ISO Date String)
     * @param {'initial-loading'|'filtering'|'paginating'|'grouping'} fetchContext Progress type to show while fetching sites results
     * @param {String} groupKey Key of the column to fetch next page of
     */
    fetchAllSites: debounce(
      async function (cursor, fetchContext = 'initial-loading', groupKey) {
        const cleanedFilters = removeUnusedFilters(this.filters)

        // As there 3 different loading indicators shown in different
        // scenarios which is determined by fetchContext and show respective
        // loading indicator based on the context given
        switch (fetchContext) {
          case 'initial-loading':
            this.areSitesLoading = true
            break

          case 'grouping':
            this.isFetchingGroupedSite = true
            break

          case 'filtering':
            this.isFetchingFilteredSite = true
            break

          case 'paginating':
            this.isPaginating = true
            break

          case 'paginating-group':
            if (this.groupByType === 'time') {
              cleanedFilters['created_between'] =
                this.computeGroupRange(groupKey)
            } else {
              cleanedFilters['status'] = [groupKey]
            }
            break
        }

        this.cancelPendingRequests()
        this.trackRequest()

        try {
          const resp = await getAllSites(
            {
              limit: this.limit,
              value: cursor,
              direction: 'lt',
              filters: cleanedFilters,
              ...(fetchContext !== 'paginating-group' && {
                group_by: this.groupByType
              })
            },
            this.trackRequestConfig
          )

          this.clearRequest()

          switch (fetchContext) {
            case 'filtering':
            case 'initial-loading':
            case 'grouping':
              if (this.groupByType) {
                this.sites = { ...resp.data }
              } else {
                this.sites = [...resp.data.sites]
                this.areMoreItems = resp.data.next_count > 0
              }
              break

            case 'paginating-group':
              this.sites[groupKey].push(...resp.data.sites)
              // returning if can fetch more items or not
              return resp.data.next_count > 0

            default:
              this.sites.push(...resp.data.sites)
              this.areMoreItems = resp.data.next_count > 0
              break
          }
        } catch (err) {
          if (this.isErrorDueToCancellation(err)) return
          this.clearRequest()

          this.updateNotificationPayload({
            message: 'Unable to fetch sites!',
            code: 400,
            timeout: 5000
          })
        }

        this.areSitesLoading = false
        this.isPaginating = false
        this.isFetchingFilteredSite = false
        this.isFetchingGroupedSite = false
      },
      300,
      { leading: true }
    ),
    async fetchAllCollections(fetchContext = 'initial-loading') {
      switch (fetchContext) {
        case 'initial-loading':
          this.areCollectionsLoading = true
          break

        case 'filtering':
          this.isFetchingFilteredCollection = true
          break
      }
      try {
        const cleanedFilters = removeUnusedFilters(
          pick(this.filters, ['created_between', 'created_by', 'search_query'])
        )
        this.cancelPendingRequestByKey('collection')
        this.trackRequestByKey('collection')

        const resp = await getAllCollections(
          {
            compact: 1,
            filters: cleanedFilters
          },
          this.trackRequestConfigByKey('collection')
        )

        this.clearRequestByKey('collection')

        this.collections = resp.data.collections
        this.areMoreCollectionsPresent = resp.data.next_page
      } catch (err) {
        if (this.isErrorDueToCancellation(err)) return
        this.clearRequestByKey('collection')

        this.updateNotificationPayload({
          message: 'Unable to fetch collections!',
          code: 400,
          timeout: 5000
        })
      }

      this.areCollectionsLoading = false
      this.isFetchingFilteredCollection = false
    },
    onCollectionCreated() {
      this.showCreateCollectionDialog = false
      this.fetchAllCollections()
    },
    computeGroupRange(groupKey) {
      const startOfThisWeek = dayjs().startOf('week').toISOString()
      const startOfThisMonth = dayjs().startOf('month').toISOString()
      const startOfLast3Months = dayjs(startOfThisMonth).subtract(3, 'months')

      let rangeStart, rangeEnd
      switch (groupKey) {
        case 'this_week':
          rangeStart = startOfThisWeek
          rangeEnd = dayjs().toISOString()
          break
        case 'earlier_this_month':
          rangeStart = startOfThisMonth
          rangeEnd = startOfThisWeek
          break
        case 'last_3_months':
          rangeStart = startOfLast3Months
          rangeEnd = startOfThisMonth
          break
        case 'before_4_months':
        default:
          rangeStart = dayjs().day(1).month(0).year(2000).toISOString()
          rangeEnd = startOfLast3Months
      }

      // In case created_between filter is applied
      // need to shift start/end group column dates
      // according to created_between start/end dates
      // such that resulted range is intersection of the two
      if (this.filters.created_between) {
        if (dayjs(this.filters.created_between[0]).isAfter(rangeStart)) {
          rangeStart = this.filters.created_between[0]
        }

        if (dayjs(this.filters.created_between[1]).isBefore(rangeEnd)) {
          rangeEnd = this.filters.created_between[1]
        }
      }

      return [rangeStart, rangeEnd]
    },
    async setupSampleSites() {
      this.isDevButtonLoading = true
      await createSampleSites(this.currentActiveOrganizationId)
      await this.fetchAllSites()
      await this.fetchAllCollections()
      this.isDevButtonLoading = false
    }
  }
}
</script>
