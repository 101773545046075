// Copyright (C) 2019-2024 Skylark Drones

import store from '@/store'
import apiInstance from '@/config'

export default {
  install(Vue) {
    /**
     * Check RBAC rules
     * @param {'GET'|'POST'|'PATCH'|'DELETE'} method HTTP Method
     * @param {String} route API route url
     * @param {String} baseApiPath API base path (e.g api/v4, api/v3)
     * @returns {Boolean} Access allowed or denied
     */
    Vue.prototype.$rbac = (
      method,
      route,
      baseApiPath = apiInstance.baseApiPath
    ) => {
      try {
        const rbacRules = store.state.generic.user.rbac_rules
        const userRole = store.getters.currentActiveOrganization.role

        route = `/${baseApiPath}/${route}`

        // Check if route and method are supported by the server
        if (!(route in rbacRules && method in rbacRules[route])) return false

        // Check access based on user role
        return userRole in rbacRules[route][method]
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }
}
