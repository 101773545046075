<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card
    v-bind="$attrs"
    v-on="$listeners"
    class="rounded-lg pa-2 pl-4"
    hover
    @click="navigateToCollectionDetailsPage"
  >
    <v-row align="center">
      <v-col class="text-truncate">
        <v-tooltip top open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-body-1 font-weight-medium text-truncate"
              data-hj-suppress
              style="color: var(--v-primary-font-color-base)"
            >
              {{ collection.name }}
            </div>
          </template>
          <span>{{ collection.name }}</span>
        </v-tooltip>
        <div
          class="text-body-2 mt-1 text-truncate"
          style="color: var(--v-secondary-font-color-base)"
        >
          {{ dialogSubtitle }}
        </div>
      </v-col>
      <v-col v-if="collection.sample_data" cols="auto">
        <sample-data-chip tooltip-text="Sample collection for your reference" />
      </v-col>
      <v-col cols="auto" class="pl-0">
        <v-menu bottom close-on-content-click offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 ma-0 rounded-lg">
            <v-list-item @click="showAddSitesToCollectionDialog = true">
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Add sites </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item @click="showCollectionDeleteConfirmationDialog = true">
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Delete Collection </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <add-sites-to-collection-dialog
      v-if="showAddSitesToCollectionDialog"
      :collection-id="collection.id"
      :collection-etag="collection.etag"
      @close-dialog="showAddSitesToCollectionDialog = false"
      @sites-added-to-collection="onCollectionUpdated"
    />
    <collection-delete-confirmation-dialog
      v-if="showCollectionDeleteConfirmationDialog"
      :collection-id="collection.id"
      :sample="collection.sample_data"
      @close-dialog="showCollectionDeleteConfirmationDialog = false"
      @collection-deleted="onCollectionDeletedFromCard"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import SampleDataChip from '@/components/common/SampleDataChip.vue'

const AddSitesToCollectionDialog = () =>
  import('@/components/collections/AddSitesToCollectionDialog')

const CollectionDeleteConfirmationDialog = () =>
  import('@/components/collections/CollectionDeleteConfirmationDialog')

export default {
  name: 'CollectionCard',

  components: {
    'add-sites-to-collection-dialog': AddSitesToCollectionDialog,
    'collection-delete-confirmation-dialog': CollectionDeleteConfirmationDialog,
    SampleDataChip
  },

  props: {
    collection: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showAddSitesToCollectionDialog: false,
      showCollectionDeleteConfirmationDialog: false
    }
  },

  computed: {
    ...mapGetters(['currentActiveOrganizationId']),
    dialogSubtitle() {
      const sitesCount = this.collection.total_sites_count
      if (!sitesCount) return 'No sites'
      const unit = sitesCount > 1 ? 'sites' : 'site'
      return `${sitesCount} ${unit}`
    }
  },

  methods: {
    navigateToCollectionDetailsPage() {
      this.$router.push({
        name: 'CollectionDetailsPage',
        params: {
          companyId: this.currentActiveOrganizationId,
          collectionId: this.collection.id
        }
      })
    },
    onCollectionUpdated(collectionId) {
      this.showAddSitesToCollectionDialog = false
      this.$emit('collection-updated', collectionId)
    },
    onCollectionDeletedFromCard() {
      this.showCollectionDeleteConfirmationDialog = false
      this.$emit('collection-deleted', this.collection.id)
    }
  }
}
</script>
