// Copyright (C) 2023-2024 Skylark Drones

export default {
  HomePage: 'Home',
  ProductUsageOverview: 'Product Usage',
  StreamPrivatePage: 'Stream Private',
  StreamPage: 'Stream',
  NewsOverview: 'News',
  DronesDisplay: 'Drones',
  SitesOverviewPage: 'Sites Overview',
  CollectionsOverviewPage: 'Collection',
  SiteDetailsPage: 'Site',
  UsersDisplay: 'User',
  BatteriesDisplay: 'Batteries',
  Changelog: 'Changelog',
  Forbidden: 'Forbidden',
  PageNotFound: 'Page Not Found',
  SettingsPage: 'Settings Page',
  ReleasePage: 'WhatsNew',
  StreamOverviewPage: 'Stream Overview',
  RecordingPage: 'Recording'
}
