<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card
    rounded="pill"
    raised
    class="subtitle-1"
    :class="{ 'pr-1': expand }"
    style="
      color: var(--v-primary-font-color-base);
      transition: all 100ms ease-in-out;
    "
    :style="{ width: this.expand ? '300px' : '52px' }"
  >
    <v-row no-gutters align="center" class="flex-nowrap">
      <v-col cols="auto">
        <v-btn
          rounded
          text
          @click="showSearchInput"
          min-width="auto"
          :class="{ 'pr-2 rounded-r-0': expand }"
        >
          <v-icon size="20"> mdi-magnify </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        v-if="expand"
        v-click-outside="{
          handler: onFocusOut,
          closeConditional: shouldShrinkPill
        }"
        class="d-flex flex-grow-1 align-center"
      >
        <input
          type="text"
          :value="searchQueryValue"
          ref="searchInput"
          autofocus
          style="outline: none"
          class="flex-grow-1 flex-shrink-1"
          @input="onSearchQuery"
        />
        <v-btn
          v-if="searchQueryValue"
          color="deep-orange"
          icon
          small
          @click="clearSearch"
        >
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'SearchQueryFilter',

  data() {
    return {
      expand: false,
      searchQueryValue: null
    }
  },

  computed: {
    ...mapGetters(['filters'])
  },

  watch: {
    'filters.search_query': function (searchQuery) {
      if (!searchQuery) {
        this.searchQueryValue = null
      }

      // Closing search bar after clearing search query
      if (!searchQuery && this.expand) {
        this.expand = false
      }

      this.showSearchQueryIfAvailable()
    }
  },

  mounted() {
    this.showSearchQueryIfAvailable()
  },

  methods: {
    ...mapMutations(['updateFilters']),
    async showSearchInput() {
      this.expand = true
      await this.$nextTick()
      this.$refs.searchInput.focus()
    },
    async clearSearch() {
      this.searchQueryValue = null
      this.$refs.searchInput.focus()
      this.debouncedSearchQuery()
    },
    onSearchQuery(e) {
      this.searchQueryValue = e.target.value
      this.debouncedSearchQuery()
    },
    debouncedSearchQuery: debounce(function () {
      this.updateFilters({ search_query: this.searchQueryValue })
    }, 300),
    shouldShrinkPill() {
      return !this.searchQueryValue
    },
    onFocusOut() {
      this.expand = false
    },
    showSearchQueryIfAvailable() {
      if (this.filters.search_query) {
        this.searchQueryValue = this.filters.search_query
        this.expand = true
      }
    }
  }
}
</script>

<style scoped></style>
