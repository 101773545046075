<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-row>
    <template v-if="isLoading">
      <v-col
        v-for="i in new Array(this.cardCount).map((v, i) => i)"
        :key="i"
        :cols="columnSize"
        class="py-2"
        :style="columnHeight"
      >
        <news-card-loader style="height: 100%" />
      </v-col>
    </template>

    <template v-else>
      <v-col :cols="columnSize" v-for="news in featureNewsList" :key="news.id">
        <news-card
          :news="news"
          :style="columnHeight"
          @click="onNewsClick(news)"
        />
      </v-col>

      <v-col :cols="columnSize">
        <view-all-news-card-grid
          :style="columnHeight"
          :news-list="remainingNewsList"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'

import { page } from '@/utils/analytics/abstraction/eventEnums'
import { getNews, updateNewsViews } from '@/services/NewsService'
import NewsCard from './NewsCard.vue'
import ViewAllNewsCardGrid from './ViewAllNewsCardGrid.vue'
import NewsCardLoader from './NewsCardLoader.vue'

export default {
  name: 'NewsRow',

  components: {
    NewsCard,
    ViewAllNewsCardGrid,
    NewsCardLoader
  },

  data() {
    return {
      newsList: [],
      isLoading: false
    }
  },

  computed: {
    columnHeight: function () {
      return { height: this.$vuetify.breakpoint.xsOnly ? '220px' : '305px' }
    },
    featureNewsList: function () {
      return this.newsList.slice(0, this.featureNewsCount)
    },
    remainingNewsList: function () {
      return this.newsList.slice(this.featureNewsCount, 10)
    },
    columnSize: function () {
      return 12 / this.cardCount
    },
    // Number of news card to display before viewAllNewsCard
    featureNewsCount: function () {
      return this.cardCount - 1 || 1
    },
    // Number of cards (newsCard & viewAllNewsCard) to display in the row based on the screen size
    cardCount: function () {
      if (this.$vuetify.breakpoint.xs) {
        return 1
      }

      if (this.$vuetify.breakpoint.sm) {
        return 2
      }

      if (this.$vuetify.breakpoint.md) {
        return 3
      }

      if (this.$vuetify.breakpoint.lg) {
        return 4
      }

      return 6
    }
  },

  mounted() {
    this.fetchNews()
  },

  methods: {
    ...mapMutations(['updateNotificationPayload']),
    async fetchNews() {
      try {
        this.isLoading = true

        const res = await getNews(1, 10, true)
        this.newsList = res.data.news
      } catch (error) {
        this.updateNotificationPayload({
          message: 'Unable to fetch news',
          code: 400,
          timeout: 5000
        })
      } finally {
        this.isLoading = false
      }
    },
    onNewsClick(news) {
      this.$analytics.trackNewsView(
        {
          id: news.id,
          title: news.title,
          url: news.url,
          isFeatured: news.featured
        },
        page.HomePage
      )
      this.$router.push({
        name: 'NewsOverview',
        query: { newsId: news.id }
      })
      updateNewsViews(news.id)
    }
  }
}
</script>
