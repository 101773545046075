// Copyright (C) 2020-2024 Skylark Drones

import Axios from 'axios'
import * as Sentry from '@sentry/browser'

import store from '@/store'
import vueApp from '@/main'
import { refreshIdToken } from '@/auth/Google'
import { BASE_API_HOST_URL } from '@/constants'

export default class BaseHttpApi {
  baseApiPath = null
  apiUrl = null
  http = null
  publicHttp = null
  responseInterceptor = null
  whitelistURLs = []

  /**
   * @param {String} baseApiPath api base path, part of path that is common in all api's eg: api/v1
   * @param {String} baseHostUrl Url for the api host with only schema and domain eg: http://dmo.com
   */
  constructor(baseApiPath, baseHostUrl = BASE_API_HOST_URL) {
    if (!baseApiPath) {
      throw Error('baseApiPath is not set')
    }

    if (!baseHostUrl) {
      throw Error('baseHostUrl is not defined')
    }

    this.baseApiPath = baseApiPath
    this.apiUrl = `${baseHostUrl}/${baseApiPath}`

    this.http = Axios.create({ baseURL: this.apiUrl })
    this.publicHttp = Axios.create({ baseURL: this.apiUrl })
  }

  setupInterceptors() {
    this.setRequestInterceptors()
    this.setResponseInterceptors()
  }

  // Auto add authorization header to all requests except for the
  // authenticate and register routes.
  setRequestInterceptors() {
    this.http.interceptors.request.use(
      config => this.handleRequestInterceptorFulfillment(config),
      error => this.handleRequestInterceptorError(error)
    )
  }

  // Auto refresh the id token if it has expired and perform the
  // failed request again if it had failed due to a 401 invalid
  // token error.
  setResponseInterceptors() {
    this.responseInterceptor = this.http.interceptors.response.use(
      response => response,
      error => this.handleResponseInterceptorError(error)
    )
  }

  handleRequestInterceptorFulfillment(config) {
    const isWhitelistedURL = this.whitelistURLs.reduce(
      (isWhitelisted, url) =>
        `${config.method}:${config.url}`.includes(url) || isWhitelisted,
      false
    )

    if (!config.url.includes('/authenticate') && !isWhitelistedURL) {
      const idToken = store.getters.idToken

      if (idToken) {
        config.headers.Authorization = idToken
      } else {
        Sentry.addBreadcrumb({
          category: 'info',
          message: 'Id token not available',
          data: { source: 'request-interceptor-fulfillment' }
        })

        return Promise.reject(new Error('Id token not available'))
      }
    }
    return config
  }

  handleRequestInterceptorError(error) {
    Sentry.captureException(error, {
      tags: { source: 'request-interceptor-error' }
    })
    return Promise.reject(error)
  }

  handleResponseInterceptorError(error) {
    return new Promise((resolve, reject) => {
      const originalReq = error.config
      /*
       * If the error is resultant from a caller function cancelling an
       * axios request *intentionally*, don't intercept it. Let it through
       * unchanged as the caller function would want to track the cancellation
       * error and react accordingly.
       */
      if (Axios.isCancel(error)) {
        return reject(error)
      }

      /*
       * Don't intercept any non 401 (JWT Token expired) errors. Just let
       * them unchanged.
       */
      if (error.response && error.response.status !== 401) {
        if (error.response.status === 400) {
          Sentry.captureException(
            new Error(
              (error.response.data && error.response.data.message) ||
                'API req. failed with status code 400'
            ),
            {
              tags: { source: 'response-interceptor' },
              contexts: {
                request: {
                  headers: error.response.config.headers,
                  url: error.response.config.url,
                  params: error.response.config.params,
                  body: error.response.config.data
                },
                response: {
                  headers: error.response.headers,
                  body: error.response.data
                }
              }
            }
          )
        }

        // If user is no more part of the company when fetching data
        // display dialog conveying this issue
        if (
          error.response.status === 403 &&
          error.response.data.error_type === 'user_not_part_of_company'
        ) {
          store.commit('updateOrgErrorNotification', true)
        }

        return reject(error)
      }

      /*
       * When response code is 401, try refreshing the id token.
       * Eject the interceptor so that it doesn't cause a loop
       * in case token refresh causes the 401 response
       */
      this.http.interceptors.response.eject(this.responseInterceptor)

      // Google id token refresh workflow
      if (store.state.auth.tokenIssuer === 'Google') {
        refreshIdToken()
          .then(refreshedToken => {
            originalReq.headers.Authorization = refreshedToken.id_token
            resolve(Axios(originalReq))
          })
          .catch(error => {
            if (error.response.data.error_type === 'refresh_error') {
              Sentry.addBreadcrumb({
                category: 'info',
                message: 'Refresh request insights',
                data: {
                  request: {
                    headers: error.response.config.headers,
                    url: error.response.config.url,
                    params: error.response.config.params,
                    body: error.response.config.data
                  },
                  response: {
                    headers: error.response.headers,
                    body: error.response.data
                  }
                }
              })
              store.dispatch('signOut')

              store.commit('updateNotificationPayload', {
                code: 400,
                message:
                  'Google account access revoked or expired. Sign in again.',
                timeout: 5000
              })

              vueApp.$router.push({ name: 'HomePage' })
            }

            Sentry.captureException(error, scope => {
              scope.setTransactionName('RefreshError')
              scope.setTags({ source: 'auth', operation: 'refresh_id_token' })
            })

            reject(error)
          })
          .finally(() => this.setResponseInterceptors())
      }

      // Other login provider's token refresh workflow would be added
      // here as required.
    })
  }
}
