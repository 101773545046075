<!-- Copyright (C) 2020 Skylark Drones -->
<template>
  <v-snackbar
    bottom
    :color="notificationPayload.code < 300 ? 'success' : 'error'"
    :timeout="notificationPayload.timeout"
    v-model="showSnackBar"
  >
    {{ notificationPayload.message }}
    <template v-slot:action="{ attrs }">
      <v-btn small outlined v-bind="attrs" @click="showSnackBar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Snackbar',
  data: function () {
    return {
      showSnackBar: false
    }
  },
  computed: {
    ...mapGetters(['notificationPayload'])
  },
  watch: {
    notificationPayload: function () {
      if (!this.notificationPayload.message) return
      this.showSnackBar = true
    }
  }
}
</script>
