// Copyright (C) 2018-2024 Skylark Drones

import { getIsNoLimitPlan } from '@/utils/common'
import { processRawFCL } from '@/utils/common/featureAccessControl'

export default {
  user: state => {
    return state.user
  },

  notificationPayload: state => {
    return state.notificationPayload
  },

  company: state => {
    return state.company
  },

  stream: state => {
    return state.stream
  },
  filters: state => state.filters,
  usersList: state => state.usersList.data,
  isFetchingUsersList: state => state.usersList.fetching,
  groupByType: state => state.groupByType,
  viewMode: state => state.viewMode,
  isFetchingUser: state => state.isFetchingUser,
  userRole: state =>
    state.currentActiveOrganization && state.currentActiveOrganization.role,
  currentActiveOrganizationId: state => state.currentActiveOrganizationId,
  currentActiveOrganization: state => {
    const _index = state.user.companies.findIndex(
      company => company.id === state.currentActiveOrganizationId
    )
    return { _index, ...state.user.companies[_index] }
  },
  userPersonalSpace: state =>
    state.user.companies.find(company => company.personal_space),
  orgErrorNotification: state => state.orgErrorNotification,
  activeOrganizationDetails: state => state.activeOrganizationDetails,
  supportedDronesList: state => state.supportedDronesList,
  utmLayerDescriptionCache: state => state.utmLayerDescriptionCache,
  livestreamCount: state => state.livestreamCount,
  cameraModels: state => {
    return state.cameraModels
  },
  isUserProfileComplete: state => {
    if (!state.user) return true
    return !(
      state.user.name === null ||
      state.user.profile_picture === null ||
      state.user.country_code === null ||
      state.user.phone_number === null ||
      state.user.geo_location === null
    )
  },
  measureState: state => state.measureState,
  freeFCL: state => state.freeFCL,
  activeFCL: (state, getters) => {
    const fcl = getters.user
      ? getters.currentActiveOrganization.fcl
      : getters.freeFCL

    return processRawFCL(fcl)
  },
  streamFCL: (state, getters) => {
    if (state.stream) {
      return processRawFCL(state.stream.fcl)
    }

    return null
  },
  isNoLimitPlan(state, getters) {
    return getIsNoLimitPlan(getters.currentActiveOrganization)
  }
}
