// Copyright (C) 2023-2024 Skylark Drones

import store from '@/store'

export default class CompanySelection {
  STORAGE_KEY = 'usersSelectedOrganizationMap'

  userOrgSelectionMap = {}

  constructor() {
    const userOrgSelectionMapString = localStorage.getItem(this.STORAGE_KEY)

    if (userOrgSelectionMapString) {
      this.userOrgSelectionMap = JSON.parse(userOrgSelectionMapString)
    }
  }

  get previousCompanyId() {
    const previousCompanyId = this.userOrgSelectionMap[store.getters.user.id]

    if (!previousCompanyId) {
      return store.getters.userPersonalSpace.id
    }

    const isUserMappingExist = store.getters.user.companies.find(
      company => company.id === previousCompanyId
    )

    if (!isUserMappingExist) {
      store.commit('updateNotificationPayload', {
        code: 403,
        message: 'You are no more part of this company'
      })
      return store.getters.userPersonalSpace.id
    }

    return previousCompanyId
  }

  persist() {
    if (!store.getters.user && !store.getters.currentActiveOrganizationId) {
      return
    }

    this.userOrgSelectionMap[store.getters.user.id] =
      store.getters.currentActiveOrganizationId

    localStorage.setItem(
      this.STORAGE_KEY,
      JSON.stringify(this.userOrgSelectionMap)
    )
  }
}
