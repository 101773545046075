<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <v-badge v-bind="$attrs" color="pro-bg" class="pro_badge">
    <template v-slot:badge>PRO</template>
    <slot />
  </v-badge>
</template>

<script>
export default {
  name: 'ProBadge'
}
</script>

<style scoped>
.pro_badge ::v-deep .v-badge__badge {
  border: 1px solid white;
  display: flex;
  align-items: center;
}
</style>
