<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-menu
    v-model="menu"
    offset-y
    bottom
    max-height="60vh"
    rounded="lg"
    :close-on-content-click="false"
    min-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <filter-btn
        v-bind="attrs"
        v-on="on"
        :label="$props.label"
        :icon="$props.icon"
      >
        <template v-slot:value>
          <template v-for="value in displayValue">
            <v-chip
              v-if="value.type === 'user'"
              active
              style="height: 28px"
              text-color="primary"
              :input-value="value.title"
              class="subtitle-2"
              v-bind:key="value.title"
            >
              {{ value.title }}
            </v-chip>
            <span
              v-bind:key="value.title"
              v-else
              class="subtitle-2 px-2 primary--text"
            >
              {{ value.title }}
            </span>
          </template>
        </template>
      </filter-btn>
    </template>

    <v-list dense>
      <v-skeleton-loader
        v-if="isFetchingUsersList"
        type="list-item-avatar"
        max-height="46"
        class="mb-2"
      />
      <v-list-item-group
        v-else
        v-model="selection"
        multiple
        :value-comparator="selectionComparator"
        color="primary"
        class="d-flex flex-column"
      >
        <template v-for="user in filteredUsersList">
          <v-list-item
            :key="user.id"
            dense
            :value="truncateUserAttributes(user)"
            :class="{ 'order-first': user.id === loggedInUserId }"
          >
            <template v-slot:default="{ active }">
              <v-list-item-avatar size="30">
                <profile-image-avatar
                  :image-src="user.profile_picture"
                  :name="user.name"
                  size="30"
                  :text-props="{ style: { fontSize: '14px' } }"
                />
              </v-list-item-avatar>
              <v-list-item-content :class="{ 'font-weight-medium': active }">
                {{ computeUserNameTitle(user.name, user.id) }}
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
    <div style="background: white; position: sticky; bottom: 0">
      <v-divider />
      <v-list-item @click="showRetiredUsers = !showRetiredUsers">
        <v-list-item-content> Show Removed Members </v-list-item-content>
        <v-list-item-action>
          <v-switch :input-value="showRetiredUsers" color="primary" />
        </v-list-item-action>
      </v-list-item>
      <v-list-item
        v-if="$props.filter"
        @click="clearFilter"
        class="primary-font-color--text"
      >
        Clear Filter
      </v-list-item>
    </div>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { isEqual, pick } from 'lodash'

import { computeNameInitials } from '@/utils/common/display'
import { getCompanyMembers } from '@/services/CompanyService'
import FilterButton from '@/components/sites/filters/FilterButton'
import ProfileImageAvatar from '@/components/common/ProfileImageAvatar.vue'

export default {
  name: 'UsersListFilter',

  components: {
    'filter-btn': FilterButton,
    'profile-image-avatar': ProfileImageAvatar
  },

  data() {
    return {
      selection: [],
      selectedUserObjects: [],
      menu: false,
      showRetiredUsers: true
    }
  },

  props: {
    label: { type: String, required: true },
    icon: { type: String, required: true },
    filter: { required: true },
    filterFunction: { type: Function, required: true }
  },

  computed: {
    ...mapGetters([
      'user',
      'usersList',
      'isFetchingUsersList',
      'currentActiveOrganizationId'
    ]),
    displayValue() {
      if (!this.selection || this.selection.length === 0) return null

      const formattedValue = [
        {
          title: this.computeUserNameTitle(
            this.selection[0].name,
            this.selection[0].id
          ),
          type: 'user'
        }
      ]

      if (this.selection.length > 1) {
        formattedValue.push({
          title: `+${this.selection.length - 1} more`,
          type: 'truncation'
        })
      }

      return formattedValue
    },
    isSelectionSameAsFilters() {
      return isEqual(this.filter, this.selection)
    },
    filteredUsersList() {
      if (this.showRetiredUsers) return this.usersList
      return this.usersList && this.usersList.filter(user => !user.leaving_date)
    },
    loggedInUserId() {
      return this.user.id
    }
  },

  watch: {
    menu: async function (isMenuVisible) {
      if (!this.usersList && isMenuVisible) {
        await this.getUsersList()
      }
    },
    selection: function () {
      // Only update the filter if the selection has changed
      if (this.isSelectionSameAsFilters) return
      this.filterFunction(this.selection.length ? this.selection : null)
    },
    filter: function () {
      // Anytime the filter changes, update the selection
      if (this.isSelectionSameAsFilters) return
      this.setSelection(this.$props.filter)
    }
  },

  mounted() {
    // On mounted, update the selection indices to match the filter value
    if (this.$props.filter) {
      this.setSelection(this.$props.filter)
    }
  },

  methods: {
    ...mapMutations([
      'setUsersList',
      'setFetchingUsersList',
      'updateNotificationPayload'
    ]),
    clearFilter() {
      this.selection = []
    },
    selectionComparator(clickedItem, currentItem) {
      return clickedItem.id === currentItem.id
    },
    computeUserNameTitle(userName, userId) {
      let title = userName.trim()

      if (!title) {
        title = 'No Name Set'
      }

      // If user item is of loggedIn user indicate
      // that to the user
      if (this.loggedInUserId === userId) {
        title += ' (You)'
      }

      return title
    },
    computeUserNameInitials(userName) {
      return computeNameInitials(userName)
    },
    async getUsersList() {
      try {
        this.setFetchingUsersList(true)
        const result = await getCompanyMembers(this.currentActiveOrganizationId)

        this.setUsersList(result.data.members)
      } catch (error) {
        this.updateNotificationPayload({
          message: 'Unable to fetch users lists!',
          code: 400,
          timeout: 5000
        })
      }

      this.setFetchingUsersList(false)
    },
    setSelection(userObjects) {
      if (!userObjects) {
        this.selection = []
        return
      }

      this.selection = [...userObjects]
    },
    truncateUserAttributes(user) {
      return pick(user, ['id', 'name'])
    }
  }
}
</script>
