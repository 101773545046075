<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card
    height="250"
    class="white--text rounded-lg"
    style="background-size: cover"
    :style="{
      backgroundImage: `url(${require('@/assets/images/airspace-advisories.png')})`
    }"
  >
    <v-row
      no-gutters
      class="fill-height"
      align="center"
      style="background-color: rgba(0, 0, 0, 0.7)"
    >
      <v-col class="text-center pa-4">
        <h6 class="text-h6">Airspace Advisories</h6>
        <div class="text-body-2 mt-2">
          Are you planning a flight? Do you know if you are legally allowed to
          fly?
        </div>
        <v-btn
          depressed
          rounded
          color="primary"
          class="mt-6"
          @click="onCheckAdvisoryClick"
          :to="{
            name: 'AirspaceAdvisory'
          }"
        >
          <v-icon left>mdi-check-outline</v-icon>
          Check Airspace Now
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { page } from '@/utils/analytics/abstraction/eventEnums'

export default {
  name: 'AirspaceAdvisoryCard',

  methods: {
    onCheckAdvisoryClick() {
      this.$analytics.trackAirspaceAdvisoryView(page.HomePage)
    }
  }
}
</script>
