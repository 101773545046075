<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <div>
    <v-menu bottom offset-y rounded="lg" min-width="180">
      <template v-slot:activator="{ on, attrs }">
        <filter-btn
          v-bind="attrs"
          v-on="on"
          label="Group By"
          icon="mdi-format-list-group"
        >
          <template v-slot:value>
            <div
              v-if="displayValue"
              class="text-subtitle-2 primary--text d-inline-flex align-center"
            >
              <v-icon size="20">{{ displayValue.icon }}</v-icon>
              <span class="ml-1">{{ displayValue.title }}</span>
            </div>
          </template>
        </filter-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(groupByOption, i) in groupByOptionsList"
          :key="groupByOption.icon + groupByOption.title"
          color="primary"
          :input-value="selectedGrouping === i"
          @click="selectOption(groupByOption, i)"
        >
          <v-list-item-icon class="mx-0 mr-3">
            <v-icon size="20">
              {{ groupByOption.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ groupByOption.title }}
          </v-list-item-content>
          <v-list-item-action>
            <pro-chip v-if="isOptionDisabled(groupByOption)" />
          </v-list-item-action>
        </v-list-item>
        <template v-if="groupByType">
          <v-divider />
          <v-list-item
            @click="removeGrouping"
            class="mt-2 primary-font-color--text"
          >
            Remove Grouping
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <pro-dialog v-if="showProDialog" @click:close-btn="showProDialog = false" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import FilterButton from '@/components/sites/filters/FilterButton'

const ProDialog = () => import('@/common/ProDialog.vue')
const ProChip = () => import('@/common/ProChip')

export default {
  name: 'GroupByMenu',

  components: {
    'filter-btn': FilterButton,
    ProChip,
    ProDialog
  },

  data() {
    return {
      selectedGrouping: null,
      groupByOptionsList: [
        {
          title: 'Created At',
          icon: 'mdi-calendar-plus-outline',
          value: 'time'
        },
        {
          title: 'Status',
          icon: 'mdi-clipboard-text-clock-outline',
          value: 'status'
        }
      ],
      showProDialog: false
    }
  },

  watch: {
    selectedGrouping: function (groupingIndex) {
      const selectedGrouping =
        groupingIndex === -1
          ? null
          : this.groupByOptionsList[groupingIndex].value

      if (selectedGrouping === this.groupByType) return

      this.setGroupByType(selectedGrouping)
    },
    groupByType: function () {
      if (this.groupByType) {
        this.selectedGrouping = this.groupByType === 'time' ? 0 : 1
        return
      }

      this.selectedGrouping = -1
    }
  },

  computed: {
    ...mapGetters(['groupByType']),
    displayValue() {
      if (this.selectedGrouping === -1) {
        return null
      }

      return this.groupByOptionsList[this.selectedGrouping]
    }
  },

  mounted() {
    if (this.groupByType) {
      this.selectedGrouping = this.groupByType === 'time' ? 0 : 1
    }
  },

  methods: {
    ...mapMutations(['setGroupByType']),
    removeGrouping() {
      this.selectedGrouping = -1
      this.setGroupByType(null)
    },
    isOptionDisabled(option) {
      return (
        option.value === 'status' &&
        !this.$featureAccessControl.isEnabled('web_progress_sync')
      )
    },
    selectOption(option, index) {
      if (this.isOptionDisabled(option)) {
        this.showProDialog = true
        return
      }

      this.selectedGrouping = index
    }
  }
}
</script>
