<!-- Copyright (C) 2018-2024 Skylark Drones -->
<template>
  <v-navigation-drawer
    app
    class="elevation-3"
    hide-overlay
    mini-variant
    :mini-variant-width="80"
    stateless
    :value="true"
  >
    <!-- Skylark Logo - Header -->
    <template v-slot:prepend>
      <org-switcher-menu />
    </template>

    <!-- Top level page items -->
    <template v-for="category in Object.keys(allowedSidebarItems)">
      <v-divider v-if="allowedSidebarItems[category].length" :key="category" />
      <v-list
        v-if="allowedSidebarItems[category].length"
        nav
        :key="`${category}+items`"
      >
        <v-list-item
          v-for="item in allowedSidebarItems[category]"
          :key="item.title"
          :to="item.routerLink"
        >
          <v-badge
            color="blue accent-2"
            :content="item.badge"
            :value="!!item.badge"
            offset-x="15"
            offset-y="15"
          >
            <v-tooltip open-delay="400" right>
              <template v-slot:activator="{ on }">
                <v-list-item-avatar v-on="on" size="24" tile>
                  <img :alt="item.title" :src="item.icon" />
                </v-list-item-avatar>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-badge>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <!-- Footer -->
    <template v-slot:append>
      <v-menu offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-ripple="false" v-bind="attrs" v-on="on">
            <v-badge
              bordered
              color="blue accent-2"
              icon="mdi-arrow-up-bold"
              overlap
              offset-x="30"
              offset-y="20"
              :value="!hasUserSeenLatestReleaseNotes"
            >
              <v-list-item-avatar v-if="user !== null">
                <profile-image-avatar
                  :image-src="user.profile_picture"
                  :name="user.name"
                  size="40"
                />
              </v-list-item-avatar>
            </v-badge>
          </v-list-item>
        </template>
        <user-context-menu />
      </v-menu>
    </template>

    <settings-dialog
      v-if="showSettingsDialog"
      @close-dialog="showSettingsDialog = false"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  SVG_ICONS as SVGIcons,
  LATEST_DMO_RELEASE,
  DEV_TOOLS
} from '@/constants'
import UserContextMenu from '@/components/common/UserContextMenu'
import routesName from '@/router/routesName'
import { apiUrl, apiMethod } from '@/services/apiEnums'
import OrganizationSwitcherMenu from './OrganizationSwitcherMenu.vue'
import OrganizationMixin from '../organization/OrganizationMixin'
import ProfileImageAvatar from './ProfileImageAvatar.vue'

const SettingsDialog = () => import('@/components/settings/SettingsDialog')

export default {
  name: 'sidebar',

  components: {
    'user-context-menu': UserContextMenu,
    'settings-dialog': SettingsDialog,
    'org-switcher-menu': OrganizationSwitcherMenu,
    'profile-image-avatar': ProfileImageAvatar
  },

  mixins: [OrganizationMixin],

  data() {
    return {
      settingsIcon: SVGIcons.SETTINGS_ICON,
      showSettingsDialog: false
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'currentActiveOrganization',
      'livestreamCount',
      'isNoLimitPlan'
    ]),
    sideBarItems() {
      return [
        {
          title: 'Home',
          items: [
            {
              title: 'Home',
              icon: SVGIcons.HOME_ICON,
              routerLink: {
                name: 'LoggedInHomePage',
                params: { companyId: this.currentActiveOrganization.id }
              }
            }
          ]
        },
        {
          title: 'Operations',
          items: [
            ...(!this.currentActiveOrganization.id
              ? []
              : [
                  {
                    title: 'Sites',
                    icon: SVGIcons.STATS_ICON,
                    routerLink: {
                      name: 'SitesOverviewPage',
                      params: { companyId: this.currentActiveOrganization.id }
                    }
                  },
                  {
                    title: 'Streams',
                    icon: SVGIcons.STREAM_ICON,
                    badge: this.livestreamCount,
                    routerLink: {
                      name: 'StreamOverviewPage',
                      params: { companyId: this.currentActiveOrganization.id }
                    }
                  }
                ])
          ]
        },
        {
          title: 'Fleet',
          items: [
            {
              title: 'Drones',
              icon: SVGIcons.DRONE_ICON,
              routerLink: {
                name: 'DronesDisplay',
                params: { companyId: this.currentActiveOrganizationId }
              }
            },
            {
              title: 'Batteries',
              icon: SVGIcons.BATTERY_ICON,
              routerLink: {
                name: 'BatteriesDisplay',
                params: { companyId: this.currentActiveOrganizationId }
              }
            }
          ]
        },
        {
          title: 'Settings',
          items: [
            ...(!this.currentActiveOrganization
              ? []
              : [
                  {
                    title: 'Settings',
                    icon: SVGIcons.SETTINGS_ICON,
                    routerLink: {
                      name: routesName.SettingsPage,
                      params: { companyId: this.currentActiveOrganization.id }
                    }
                  }
                ])
          ]
        },
        {
          title: 'Usage',
          items: [
            {
              title: 'ProductUsage',
              icon: SVGIcons.METRICS_ICON,
              routerLink: { name: 'ProductUsageOverview' }
            },
            {
              title: 'NewsCMS',
              icon: SVGIcons.NEWS_ICON,
              routerLink: { name: 'NewsCMS' }
            },
            {
              title: 'TipsCMS',
              icon: SVGIcons.TIPS_ICON,
              routerLink: { name: 'TipsCMS' }
            },
            {
              title: 'GovernanceCMS',
              icon: SVGIcons.MONEY_ICON,
              routerLink: { name: 'GovernanceOverview' }
            }
          ]
        }
      ]
    },
    allowedSidebarItems() {
      const filteredItems = {}
      this.sideBarItems.forEach(itemGroup => {
        const category = itemGroup.title
        filteredItems[category] = []
        itemGroup.items.forEach(item => {
          if (this.checkRBAC(item)) {
            filteredItems[category].push(item)
          }
        })
      })
      return filteredItems
    },
    hasUserSeenLatestReleaseNotes() {
      return this.user.last_release_read === LATEST_DMO_RELEASE
    }
  },

  methods: {
    checkRBAC(item) {
      switch (item.title) {
        case 'Sites':
          return this.$rbac(apiMethod.GET, apiUrl.Sites)
        case 'Drones':
          return this.$rbac(apiMethod.GET, apiUrl.Drones)
        case 'Batteries':
          return this.$rbac(apiMethod.GET, apiUrl.Batteries)
        case 'ProductUsage':
          return this.$metaRbac(apiMethod.GET, apiUrl.CompaniesUsage)
        case 'GovernanceCMS':
          return this.$metaRbac(apiMethod.GET, apiUrl.CompaniesUsage)
        case 'News':
          return true
        case 'NewsCMS':
          return this.$metaRbac(apiMethod.PATCH, apiUrl.News)
        case 'TipsCMS':
          return this.$metaRbac(apiMethod.GET, apiUrl.Tips)
        case 'Settings':
          return (
            !(
              this.isNoLimitPlan &&
              this.currentActiveOrganization.personal_space
            ) || DEV_TOOLS
          )
        case 'Streams':
          return this.$rbac(apiMethod.GET, apiUrl.Streams)
        case 'Home':
          return true
        default:
          return false
      }
    }
  }
}
</script>
