// Copyright (C) 2024 Skylark Drones

export function setAppTitle(title) {
  document.title = title
}

export function setFavicon(favicon) {
  let faviconLink = document.querySelector("link[rel*='icon']")
  if (!faviconLink) {
    faviconLink = document.createElement('link')
    document.head.appendChild(faviconLink)
    faviconLink.type = 'image/x-icon'
    faviconLink.rel = 'shortcut icon'
  }
  faviconLink.href = favicon
}
