<!-- Copyright (C) 2024 Skylark Drones -->
<template>
  <v-card width="350" class="rounded-lg">
    <div class="pt-4 d-flex flex-column align-center">
      <profile-image-avatar
        :image-src="user.profile_picture"
        :name="user.name"
        size="120"
        :text-props="{
          style: { fontSize: '40px' }
        }"
      />
      <div
        class="mt-2 text-h5 text-truncate"
        style="color: var(--v-primary-font-color-base); max-width: 275px"
      >
        {{ user.name }}
      </div>
      <div
        class="text-body-2 text-truncate"
        style="color: var(--v-secondary-font-color-base); max-width: 255px"
      >
        {{ user.email }}
      </div>
      <incomplete-profile-alert
        v-if="!isUserProfileComplete"
        :compact="true"
        class="mt-2"
        dense
      />
      <v-btn
        @click.prevent="showUserProfile"
        class="mt-2"
        rounded
        depressed
        color="primary"
        small
      >
        <v-icon left small>mdi-account</v-icon>
        View Profile
      </v-btn>
    </div>
    <v-list class="pa-0 mx-4 mt-4 mb-2 rounded-lg" outlined>
      <template v-for="(item, index) in items">
        <v-list-item :key="item.title" @click.prevent="item.method">
          <v-list-item-avatar>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title style="color: var(--v-primary-font-color-base)">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
        <v-divider v-if="index !== items.length - 1" :key="index" />
      </template>
    </v-list>
    <div class="mt-2 pb-4 d-flex flex-row justify-center">
      <v-btn
        text
        target="_blank"
        small
        class="text-none"
        style="color: var(--v-primary-font-color-base)"
        :href="privacyPolicyLink"
      >
        Privacy Policy
      </v-btn>
      <v-icon class="mx-2" size="4px">mdi-circle</v-icon>
      <v-btn
        text
        target="_blank"
        small
        class="text-none"
        style="color: var(--v-primary-font-color-base)"
        :href="termsOfServiceLink"
      >
        Terms of Service
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import { source } from '@/utils/analytics/abstraction/eventEnums'
import { releases } from '@/components/changelog/ReleaseHistory'
import { editUser } from '@/services/UserService'
import { LATEST_DMO_RELEASE } from '@/constants'
import ProfileImageAvatar from './ProfileImageAvatar.vue'

const IncompleteProfileAlert = () =>
  import('@/components/common/IncompleteProfileAlert.vue')

export default {
  name: 'UserContextMenu',

  components: {
    'profile-image-avatar': ProfileImageAvatar,
    IncompleteProfileAlert
  },

  computed: {
    ...mapGetters('brand', [
      'appNameAbbr',
      'privacyPolicyLink',
      'termsOfServiceLink'
    ]),
    ...mapGetters(['user', 'isUserProfileComplete']),
    hasUserSeenLatestReleaseNotes() {
      return this.user.last_release_read === LATEST_DMO_RELEASE
    },
    items() {
      return [
        {
          title: this.hasUserSeenLatestReleaseNotes
            ? `What's New in ${this.appNameAbbr}`
            : `Read about the ${LATEST_DMO_RELEASE} release!`,
          icon: 'mdi-arrow-up-bold-circle-outline',
          method: this.navigateToWhatsNewPage,
          color: 'blue accent-2'
        },
        {
          title: 'Sign Out',
          icon: 'mdi-logout',
          method: this.signOutUser,
          color: 'error'
        }
      ]
    }
  },

  methods: {
    ...mapActions(['signOut']),
    ...mapMutations(['updateUserAttribute']),
    showUserProfile() {
      this.$router.push({
        name: 'UsersDisplay',
        params: { userId: this.user.id }
      })
      this.$analytics.trackUserProfileView(source.USER_MENU)
    },
    signOutUser() {
      this.signOut()
      this.$router.push({ name: 'HomePage' })
      this.$analytics.trackLogout()
    },
    async navigateToWhatsNewPage() {
      let redirectTo = 'Overview'
      try {
        if (this.user.last_release_read !== LATEST_DMO_RELEASE) {
          await editUser(this.user.id, {
            last_release_read: LATEST_DMO_RELEASE
          })
          this.updateUserAttribute({ last_release_read: LATEST_DMO_RELEASE })

          // Check if the release can be found in the releases list. Typically,
          // hotfix releases are not added to the changelog despite a version bump.
          // For these cases, fallback to the overview page.
          const release = releases.find(
            release => release.version === LATEST_DMO_RELEASE
          )
          if (release) redirectTo = release.version
        }
      } finally {
        if (redirectTo === 'Overview') {
          await this.$router.push({ name: 'Changelog' })
        } else {
          await this.$router.push({
            name: 'ReleasePage',
            params: { version: redirectTo }
          })
        }
        this.$analytics.trackWhatsNewView(source.USER_MENU)
      }
    }
  }
}
</script>
