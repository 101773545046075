// Copyright (C) 2021 Skylark Drones

import toGeoJson from '@mapbox/togeojson'
import JSZip from 'jszip'

export function readKML(kmlFile, processKMLFeatures) {
  const fr = new FileReader()
  fr.onload = file => {
    const kml = new DOMParser().parseFromString(file.target.result, 'text/xml')
    const features = toGeoJson.kml(kml).features

    processKMLFeatures(features)
  }
  fr.readAsText(kmlFile)
}

export function readKMZ(kmzFile, processKMLFeatures) {
  JSZip.loadAsync(kmzFile).then(zip => {
    let kmlFile = null
    zip.forEach((relPath, file) => {
      // Safety check to ensure that the processing is done only for kml files
      if (
        relPath.split('.').pop().toLowerCase() === 'kml' &&
        kmlFile === null
      ) {
        kmlFile = file
      }
    })

    const features = []
    if (kmlFile === null) {
      processKMLFeatures(features)
      return
    }

    kmlFile.async('string').then(xml => {
      const kml = new DOMParser().parseFromString(xml, 'text/xml')
      processKMLFeatures(toGeoJson.kml(kml).features)
    })
  })
}
