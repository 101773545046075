// Copyright (C) 2023-2024 Skylark Drones

import { mapMutations, mapGetters } from 'vuex'
import { getAllDrones } from '@/services/AssetService'

export default {
  data() {
    return {
      isFetchingDronesList: false
    }
  },

  computed: {
    ...mapGetters(['supportedDronesList'])
  },

  methods: {
    ...mapMutations(['updateNotificationPayload', 'setSupportedDronesList']),
    async fetchDrones() {
      try {
        this.isFetchingDronesList = true
        const res = await getAllDrones()

        this.setSupportedDronesList(
          res.data.items.reduce((acc, value) => {
            acc[value.id] = value
            return acc
          }, {})
        )
      } catch (err) {
        this.updateNotificationPayload({
          message: 'Unable to fetch supported drones!',
          code: 400,
          timeout: 5000
        })
      } finally {
        this.isFetchingDronesList = false
      }
    },
    getDroneModel(droneId) {
      return this.supportedDronesList[droneId]
    }
  }
}
