// Copyright (C) 2022-2024 Skylark Drones

import bboxPolygon from '@turf/bbox-polygon'
import bbox from '@turf/bbox'
import circle from '@turf/circle'
import { lineString } from '@turf/helpers'
import transformScale from '@turf/transform-scale'
import length from '@turf/length'
import area from '@turf/area'

export const m2ToAcres = 4046.86

const squareSizeInAcres = 10
const lineLengthInKm = 2

export function getInitialSiteGeometry(siteType, centerPoint) {
  if (siteType === 'asset') {
    return {
      type: 'Point',
      coordinates: centerPoint
    }
  } else if (siteType === 'area') {
    return getInitialPolygonGeometry(centerPoint)
  } else if (siteType === 'corridor') {
    return getInitialLineGeometry(centerPoint)
  }
}

function getInitialPolygonGeometry(centerPoint) {
  const squareSizeInMeterSquare = squareSizeInAcres * m2ToAcres
  const squareEdgeLength = Math.sqrt(squareSizeInMeterSquare)
  const radius = squareEdgeLength / 2000

  return bboxPolygon(bbox(circle(centerPoint, radius, { steps: 64 }))).geometry
}

function getInitialLineGeometry(centerPoint) {
  const squareCoordinates =
    getInitialPolygonGeometry(centerPoint).coordinates[0]
  const lineFromSquare = lineString([
    squareCoordinates[0],
    squareCoordinates[2]
  ])
  return transformScale(lineFromSquare, lineLengthInKm / length(lineFromSquare))
    .geometry
}

export function getSiteSize(geoJSON) {
  if (geoJSON.type === 'LineString') {
    return length(geoJSON)
  } else if (geoJSON.type === 'Polygon') {
    return area(geoJSON) / m2ToAcres
  }
}
