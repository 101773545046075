<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card ref="streamRow" class="rounded-lg fill-height" color="#ff6961">
    <v-row no-gutters style="min-height: 228px" class="fill-height">
      <v-col>
        <stream-list-homepage-loader v-if="isLoading" />
        <empty-stream-card
          v-else-if="filteredStreams.length === 0"
          elevation="0"
          class="d-flex fill-height"
        />
        <stream-list-homepage v-else :streams="filteredStreams" />
      </v-col>
      <v-col v-if="showFeatureSlides" cols="auto" class="my-10">
        <v-divider vertical />
      </v-col>
      <v-col v-if="showFeatureSlides">
        <stream-feature-carousel />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import dayjs from 'dayjs'

import { getStreams } from '@/services/StreamService'
import EmptyStreamCard from '@/components/homepage/stream-row/EmptyStreamCard.vue'
import StreamListHomepage from './StreamListHomepage.vue'
import StreamFeatureCarousel from './StreamFeatureCarousel.vue'
import StreamListHomepageLoader from './StreamListHomepageLoader.vue'

export default {
  name: 'StreamRow',

  components: {
    EmptyStreamCard,
    StreamListHomepage,
    StreamFeatureCarousel,
    StreamListHomepageLoader
  },

  data() {
    return {
      streams: [],
      isLoading: false,
      resizeObserver: new ResizeObserver(this.onElemResize),
      showFeatureSlides: true
    }
  },

  computed: {
    ...mapGetters(['currentActiveOrganizationId']),
    filteredStreams() {
      return this.streams
        .filter(stream => {
          const date7DaysAgo = dayjs(Date.now()).subtract(7, 'days')

          return dayjs(stream.start_time).isAfter(date7DaysAgo)
        })
        .sort((streamA, streamB) => {
          if (streamA.status === 'live' && streamB.status === 'live') {
            if (dayjs(streamA.start_time).isBefore(streamB.start_time)) {
              return 1
            } else {
              return -1
            }
          }

          if (streamA.status === 'live') {
            return -1
          }

          return 1
        })
        .slice(0, 2)
    }
  },

  watch: {
    currentActiveOrganizationId: function () {
      this.fetchAllLivestreams()
    }
  },

  mounted() {
    this.resizeObserver.observe(this.$refs.streamRow.$el)
    this.fetchAllLivestreams()
  },

  destroyed() {
    this.resizeObserver.disconnect()
  },

  methods: {
    ...mapMutations(['updateNotificationPayload', 'setLivestreamCount']),
    async fetchAllLivestreams() {
      this.isLoading = true

      try {
        const resp = await getStreams(this.currentActiveOrganizationId, {
          view_recording: 'true',
          direction: 'lt',
          value: new Date().toISOString()
        })
        this.streams = resp.data.streams
        this.setLivestreamCount(
          resp.data.streams.filter(stream => stream.status === 'live').length
        )
      } catch (err) {
        this.updateNotificationPayload({
          message: 'Unable to fetch livestreams!',
          code: 400,
          timeout: 5000
        })
      } finally {
        this.isLoading = false
      }
    },
    onElemResize(entries) {
      requestAnimationFrame(() => {
        this.showFeatureSlides = entries[0].target.clientWidth >= 700
      })
    }
  }
}
</script>
