// Copyright (C) 2021-2024 Skylark Drones

import apiInstance from '@/config'

/**
 * Authenticate the user with DMO servers to verify that the
 * user is legally allowed to use Drone Mission Ops.
 * @param {String} idToken Identity Token (Google)
 * @returns {Promise<import('axios').AxiosResponse>} Axios response
 */
export function authenticateUser(idToken) {
  const config = { headers: { Authorization: idToken } }
  return apiInstance.publicHttp.get('/authenticate', config)
}

/**
 * FUTURE USE: To implement callback(popup) mode
 * Used to fetch access/refresh token using auth code returned by google in callback
 * @param {String} code auth code returned by google
 * @returns {Promise<import('axios').AxiosResponse} Axios Response
 */
export function getIdToken(code) {
  return apiInstance.publicHttp.get('/authenticate/code', {
    params: { code },
    headers: { 'X-Requested-With': 'XmlHttpRequest' }
  })
}

/**
 * Refreshes current id_token and return new refreshed id_token
 * @param {String} idToken Current JWT auth token
 * @returns {Promise<import('axios').AxiosResponse>} Axios response
 */
export function getRefreshedToken(idToken) {
  return apiInstance.publicHttp.get('/authenticate/refresh', {
    headers: { Authorization: idToken }
  })
}

/**
 * Communicate to backend that user logged out
 * @param {String} idToken Current JWT auth token
 * @returns {Promise<import('axios').AxiosResponse>} Axios response
 */
export function logoutUser(idToken) {
  return apiInstance.publicHttp.patch('/authenticate/logout', undefined, {
    headers: { Authorization: idToken }
  })
}
