<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <div>
    <v-row :dense="$vuetify.breakpoint.xsOnly">
      <v-col
        v-for="site in $props.sites"
        :key="site.id"
        cols="6"
        md="3"
        lg="3"
        xl="2"
      >
        <site-card
          class="mb-2 mb-sm-4"
          :site="site"
          :to="{ name: 'SiteDetailsPage', params: { siteId: site.id } }"
          @site-deleted="$props.deleteSiteFunction"
          @collection-created="$props.collectionCreatedFunction"
          @site-added-to-collection="$props.siteAddedToCollectionFunction"
        />
      </v-col>
    </v-row>
    <v-row v-if="$props.areMoreSites" class="mb-5" no-gutters justify="center">
      <v-btn
        class="rounded-lg text-body-1 font-weight-medium"
        width="250"
        height="50"
        text
        @click="$props.loadMoreSites"
        :loading="$props.isPaginating"
      >
        Load More
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import SiteCard from './SiteCard'

export default {
  name: 'SiteUngroupedGridView',

  components: {
    'site-card': SiteCard
  },

  props: {
    sites: { type: Array, required: true },
    deleteSiteFunction: { type: Function, required: true },
    siteAddedToCollectionFunction: { type: Function, required: true },
    collectionCreatedFunction: { type: Function, required: true },
    loadMoreSites: { type: Function, required: true },
    isPaginating: { type: Boolean, required: true },
    areMoreSites: { type: Boolean, required: true }
  }
}
</script>
