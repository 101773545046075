<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-avatar
    v-bind="$attrs"
    v-on="$listeners"
    :color="$attrs.color || 'grey lighten-2'"
  >
    <v-img
      v-if="$props.imageSrc"
      :src="$props.imageSrc"
      :contain="isImageContain"
      :width="$props.imgProps.width || imageWidth"
      :height="$props.imgProps.height || imageHeight"
      :max-width="$props.imgProps.maxWidth || imageWidth"
      :max-height="$props.imgProps.maxHeight || imageHeight"
      v-bind="$props.imgProps"
    />
    <v-icon
      v-else-if="$props.fallbackIcon || $props.company"
      v-bind="$props.iconProps"
      :color="$props.iconProps.color || 'grey darken-2'"
    >
      {{ $props.fallbackIcon || 'mdi-office-building' }}
    </v-icon>
    <div
      v-else
      v-bind="$props.textProps"
      :class="$props.textProps.class || 'grey--text text--darken-2'"
      data-hj-suppress
    >
      {{ nameInitials }}
    </div>
  </v-avatar>
</template>

<script>
import { computeNameInitials } from '@/utils/common/display'

export default {
  name: 'ProfileImageAvatar',

  props: {
    name: { type: String },
    fallbackIcon: { type: String },
    imageSrc: { type: String },
    company: { type: Boolean },
    imgProps: {
      type: Object,
      default: () => ({})
    },
    iconProps: {
      type: Object,
      default: () => ({})
    },
    textProps: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    nameInitials() {
      return computeNameInitials(this.$props.name)
    },
    isImageContain() {
      if ('contain' in this.$props.imgProps) {
        return this.$props.imgProps.contain
      }

      return this.$props.company
    },
    defaultImageSize() {
      return this.isImageContain ? 24 : undefined
    },
    imageWidth() {
      if ('width' in this.$props.imgProps) {
        return this.$props.imgProps.width
      }
      return this.defaultImageSize
    },
    imageHeight() {
      if ('height' in this.$props.imgProps) {
        return this.$props.imgProps.height
      }

      return this.defaultImageSize
    }
  }
}
</script>
