<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-btn-toggle
    v-model="viewType"
    borderless
    class="elevation-2"
    color="primary"
    dense
    mandatory
    rounded
  >
    <v-btn class="white">
      <v-icon
        :color="viewType === 0 ? 'primary' : 'primary-font-color'"
        size="24"
      >
        mdi-view-grid-outline
      </v-icon>
    </v-btn>
    <v-btn class="white">
      <v-icon
        :color="viewType === 1 ? 'primary' : 'primary-font-color'"
        size="24"
      >
        mdi-view-column-outline
      </v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ViewToggleOption',

  data() {
    return { viewType: 0 }
  },

  computed: {
    ...mapGetters(['viewMode'])
  },

  watch: {
    viewType: function (typeIndex) {
      const selectedViewMode = typeIndex === 0 ? 'grid' : 'column'
      if (selectedViewMode === this.viewMode) return

      this.setViewMode(selectedViewMode)
    }
  },

  mounted() {
    this.viewType = this.viewMode === 'grid' ? 0 : 1
  },

  methods: {
    ...mapMutations(['setViewMode'])
  }
}
</script>
