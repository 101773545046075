<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card class="rounded-lg overflow-hidden" height="50vh" max-height="60vh">
    <v-img
      position="center top"
      :src="background"
      :height="homeCardImageHeight"
      style="position: absolute; bottom: 0; left: 0; width: 100%"
    />
    <div
      class="px-4 px-md-15 pt-4 pt-md-6 text-center text-md-left"
      style="position: relative"
    >
      <v-col cols="12" md="">
        <div class="text-h4 text-md-h2 font-weight-bold">
          {{ appName }}
        </div>
        <div class="text--secondary mt-2 mt-md-4">
          {{ subtitleOfTheDay }}
        </div>
      </v-col>
      <v-col cols="12" md="auto" class="pt-6">
        <continue-with-google-button
          @new-user-sign-up="showSignUpDialog = true"
        />
        <sign-up-dialog
          v-if="showSignUpDialog"
          @close-dialog="showSignUpDialog = false"
        />
      </v-col>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import background from '@/assets/images/homepage-hero-bg.png'
import ContinueWithGoogleButton from '@/components/homepage/ContinueWithGoogleButton.vue'
import { randomNumberInRange } from '@/utils/common'
const SignUpDialog = () => import('@/components/homepage/SignUpDialog')

export default {
  name: 'HomePageHeroCard',

  components: {
    SignUpDialog,
    ContinueWithGoogleButton
  },

  data() {
    return {
      background,
      showSignUpDialog: false
    }
  },

  computed: {
    ...mapGetters('brand', ['appNameAbbr', 'appName']),
    subtitleOfTheDay() {
      const homeCardSubtitle = [
        'The future of drone operations, Today',
        `Dream it, Drone it, ${this.appNameAbbr} it`,
        'Capturing reality in a snap',
        'Improving your map, 1 cm/px at a time',
        `Plan the unplanned with ${this.appNameAbbr}, Go Live`,
        'Your on-site wingman',
        'Your co-pilot on the ground'
      ]
      const num = randomNumberInRange(0, homeCardSubtitle.length - 1)
      return homeCardSubtitle[num]
    },
    homeCardImageHeight() {
      let height = 60

      if (this.$vuetify.breakpoint.lgAndUp) {
        height = 90
      }

      if (this.$vuetify.breakpoint.md) {
        height = 55
      }

      if (this.$vuetify.breakpoint.smAndDown) {
        height = 50
      }

      return `${height}%`
    }
  }
}
</script>
