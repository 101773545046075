// Copyright (C) 2024 Skylark Drones

export class UnitConverter {
  constructor() {
    if (new.target === UnitConverter) {
      throw new TypeError('Cannot construct BaseConverter instances directly')
    }
  }

  static PRECISION = 5

  convert(value, inputUnit, outputUnit) {
    throw new Error('convert method must be implemented by subclass')
  }
}
