<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <div>
    <group-header class="mb-5">{{ $props.heading }}</group-header>

    <!-- Sites Grid -->
    <v-row :dense="$vuetify.breakpoint.xsOnly" v-if="$props.sites.length">
      <v-col
        v-for="site in $props.sites"
        :key="site.id"
        cols="6"
        md="3"
        lg="3"
        xl="2"
      >
        <site-card
          :site="site"
          :to="{ name: 'SiteDetailsPage', params: { siteId: site.id } }"
          @site-deleted="removeSite"
          @collection-created="$props.collectionCreatedFunction"
          @site-added-to-collection="$props.siteAddedToCollectionFunction"
        />
      </v-col>
      <v-col v-if="isMoreSites" cols="6" md="3" lg="3" xl="2">
        <v-btn
          elevation="0"
          width="100%"
          height="100%"
          style="background: #dcdcdc"
          @click="loadMore"
          :loading="isFetchingMoreSites"
        >
          Load More
        </v-btn>
      </v-col>
    </v-row>
    <div v-else class="text-body-2 py-5" style="color: #6e6e6d">
      <slot v-if="$slots['no-items']" name="no-items"></slot>
      <template v-else> No Sites </template>
    </div>
  </div>
</template>

<script>
import { last } from 'lodash'

import GroupHeader from './GroupHeader'
import SiteCard from '@/components/sites/SiteCard'

export default {
  name: 'SiteGridViewSection',

  components: {
    'group-header': GroupHeader,
    'site-card': SiteCard
  },

  data() {
    return {
      isFetchingMoreSites: false,
      isMoreSites: !(this.sites.length < this.$props.limit)
    }
  },

  props: {
    heading: { type: String, required: true },
    groupKey: { type: String, required: true },
    sites: { type: Array, required: true },
    deleteSiteFunction: { type: Function, required: true },
    siteAddedToCollectionFunction: { type: Function, required: true },
    collectionCreatedFunction: { type: Function, required: true },
    loadMoreSites: { type: Function, required: true },
    limit: { type: Number, required: true }
  },

  computed: {
    latestCursor() {
      return last(this.$props.sites).creation_time
    }
  },

  methods: {
    async loadMore() {
      this.isFetchingMoreSites = true
      this.isMoreSites = await this.$props.loadMoreSites(
        this.$props.groupKey,
        this.latestCursor
      )
      this.isFetchingMoreSites = false
    },
    removeSite(siteId) {
      this.$props.deleteSiteFunction(siteId, this.$props.groupKey)
    }
  }
}
</script>
