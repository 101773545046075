// Copyright (C) 2020-2024 Skylark Drones

import moment from 'moment'
import bytes from 'bytes'

// Function to convert seconds to hours, minutes and seconds
// and return a formatted time string. E.g 2h 33m 15s
export function formatTime(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const seconds = durationInSeconds % 60

  let formattedTime = ''

  if (hours > 0) formattedTime += ` ${hours}h`
  if (minutes > 0) formattedTime += ` ${minutes}m`
  if (seconds > 0) formattedTime += ` ${seconds}s`

  return formattedTime
}

// Function to compute the initials of a name string which
// consists of the first letter of the first 2 words capitalised
// E.g Nekhelesh Ramananthan => NR
export function computeNameInitials(name) {
  let initials
  if (name) {
    name = name.trim()
    const nameSlices = name.split(' ')

    // Check first is a word is empty string
    if (nameSlices.length > 0 && nameSlices[0]) {
      initials = nameSlices[0][0].toUpperCase()
    }

    // Check if second word is empty string
    if (nameSlices.length > 1 && nameSlices[1]) {
      initials += nameSlices[1][0].toUpperCase()
    }
  }

  // If no initials are found
  if (!initials) return '-'

  return initials
}

// Function to round off the size of a geometry
// E.g Polygon (3.4455) => 3.45, Polygon (105.232) => 105
export function roundOffSize(geomType, size) {
  if (geomType === 'Point') return null
  if (geomType === 'Polygon') {
    return size >= 100 ? parseInt(size) : parseFloat(size.toFixed(2))
  } else {
    return size >= 50 ? parseInt(size) : parseFloat(size.toFixed(2))
  }
}

// Function to round the value to a fixed amount of maximum decimal place
// E.g 3.23, 3 => 3.23; 3.234, 3 => 3.234 ; 3.2345, 3 => 3.234
export function roundToDecimal(value, decimalPlaces = 2) {
  const roundedValue = Number(value.toFixed(decimalPlaces))
  return roundedValue === value ? value : roundedValue
}

// Function to format value to a minimum of 0.1 percent precision
// E.g 3.2355 => 3.24; 300.2234 => 300.2 ; 0.573292, 3 => 0.573
export function formatPrecise(value) {
  if (value <= 1) {
    return roundToDecimal(value, 3)
  } else if (value < 100) {
    return roundToDecimal(value, 2)
  }
  return roundToDecimal(value, 1)
}

// Function to format value greater than 1000 using K OR M
// E.g 3.23K, 2.5M, 3.23
export function preciseFormatCompact(value) {
  const formattedValue = formatPrecise(value)
  if (formattedValue < 1000) return formattedValue
  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })
  return formatter.format(formattedValue)
}

// Function to convert seconds into days, hours, minutes and seconds
// and return a max 'n' units (short form) which is customisable.
// E.g 1d 4h, 30m 15s
export function formatFlyingTime(durationInSeconds, maxUnits = 2) {
  const flyingTime = {
    d: Math.floor(durationInSeconds / (3600 * 8)),
    h: Math.floor((durationInSeconds / 3600) % 8),
    m: Math.floor((durationInSeconds % 3600) / 60),
    s: durationInSeconds % 60
  }

  let unitsAdded = 0
  let remainingTimeStr = ''

  for (const [unit, value] of Object.entries(flyingTime)) {
    if (unitsAdded >= maxUnits) break
    if (!value) continue

    remainingTimeStr += `${value}${unit} `
    unitsAdded++
  }

  return remainingTimeStr
}

// Function to convert datetime into a relative quantity E.g 4 hours ago, 2 days ago
export function formatDateTime(lastSeen) {
  if (!lastSeen) return 'Not Available'

  const now = moment.now()
  const datetime = moment(moment.utc(lastSeen).toDate()).local()
  const diff = moment(now).diff(datetime, 'days')

  switch (diff) {
    case 0: {
      const hours = moment(now).diff(datetime, 'hours')
      if (hours) return `${hours} hours ago`
      const minutes = moment(now).diff(datetime, 'minutes')
      if (minutes) return `${minutes} minutes ago`
      else return 'Few seconds ago'
    }
    case 1: {
      return 'Yesterday'
    }
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return `${diff} days ago`
    case 7:
      return `1 week ago`
    default:
      return datetime.format('Do MMM YYYY')
  }
}

export function formatBytes(rawBytes) {
  return bytes(rawBytes, { unitSeparator: ' ' })
}
