<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-row class="pa-4 fill-height" no-gutters>
    <v-col>
      <v-row
        v-for="i in 2"
        :key="i"
        no-gutters
        align="center"
        justify="space-between"
        class="rounded-lg flex-nowrap pa-3"
        :class="{ 'mt-2': i !== 1 }"
        style="background-color: #ff9994"
      >
        <v-col>
          <v-skeleton-loader type="sentences" width="80%" />
        </v-col>
        <v-col cols="auto">
          <v-skeleton-loader type="avatar" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'StreamListHomepageLoader'
}
</script>
