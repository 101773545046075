// Copyright (C) 2020-2024 Skylark Drones

import apiInstance from '@/config'

export function registerCompany(data, params = null) {
  if (params) return apiInstance.http.post('/register', data, { params })
  else return apiInstance.http.post('/register', data)
}

/**
 * Creates sample sites and collections for the given company
 */
export function createSampleSites(companyID) {
  return apiInstance.http.post(`/register/sample_sites/${companyID}`)
}
