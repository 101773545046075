// Copyright (C) 2024 Skylark Drones

import mutations from './mutations'
import getters from './getters'

const state = {
  appName: null,
  appNameAbbr: null,
  appLogo: null,
  billingDetails: {},
  supportEmail: null,
  favicon: null,
  appLogoWhite: null,
  locationCoordinate: [0, 0],
  defaultZoom: null,
  androidPilotAppLink: null,
  androidM3EAppLink: null,
  disabledFeatures: {},
  termsOfServiceLink: null,
  privacyPolicyLink: null
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
