<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <div>
    <site-grid-view-section
      v-for="group in groupedSites"
      class="mb-8"
      v-bind:key="group.heading"
      :heading="group.heading"
      :group-key="group.groupKey"
      :sites="group.sites"
      :collection-created-function="$props.collectionCreatedFunction"
      :delete-site-function="$props.deleteSiteFunction"
      :site-added-to-collection-function="$props.siteAddedToCollectionFunction"
      :is-more-sites="group.moreItems"
      :load-more-sites="$props.loadMoreSites"
      :limit="$props.limit"
    >
      <template v-slot:no-items>{{ group.emptyMessage }}</template>
    </site-grid-view-section>
  </div>
</template>

<script>
import GroupByMixin from './GroupByMixin'
import SiteGridViewSection from './SiteGridViewSection.vue'

export default {
  name: 'SiteGroupByGridView',

  components: {
    'site-grid-view-section': SiteGridViewSection
  },

  mixins: [GroupByMixin]
}
</script>
