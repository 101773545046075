<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-btn block color="primary" depressed tile v-bind="$attrs" v-on="$listeners">
    <img
      style="position: absolute; opacity: 0.3"
      height="56"
      :alt="appLogoWhite"
      :src="appLogoWhite"
    />
    <v-badge
      v-if="currentActiveOrganization"
      :content="user.invites.length"
      :value="user.invites.length"
      offset-x="8"
      offset-y="8"
      color="blue accent-2"
    >
      <div
        class="rounded-lg white elevation-2 d-flex justify-center align-center"
        style="width: 32px; height: 32px"
      >
        <img
          v-if="currentActiveOrganization.logo"
          :src="currentActiveOrganization.logo"
          style="width: 24px; height: 24px; object-fit: contain"
        />
        <v-icon v-else size="24" color="grey darken-2">
          {{
            currentActiveOrganization.personal_space
              ? 'mdi-account'
              : 'mdi-office-building'
          }}
        </v-icon>
        <v-icon
          size="24"
          style="position: absolute; bottom: -12px; right: -22px"
        >
          mdi-unfold-more-horizontal
        </v-icon>
      </div>
    </v-badge>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrganizationSwitchButton',

  computed: {
    ...mapGetters('brand', ['appLogoWhite']),
    ...mapGetters(['currentActiveOrganization', 'user'])
  }
}
</script>
